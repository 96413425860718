import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

export const Dropzone = ({ label, onDrop, type, sendFile }) => {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop });
    const [files, setFiles] = useState([]);

    useEffect(() => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    }, [acceptedFiles]);


    return (
        <div className="mb-4">
            <div className='flex justify-between items-center mb-2'>
                <label className="block text-gray-200 text-sm font-bold">{label}</label>
            </div>
            <div {...getRootProps({ className: 'dropzone border-dashed border-2 border-gray-200 p-4 rounded-md' })}>
                <input {...getInputProps()} />
                <div className="text-center">
                    {isDragActive ? (
                        <p className="text-gray-500">Отпустите файлы здесь...</p>
                    ) : (
                        <p className="text-gray-500">Перетащите файлы сюда, или кликните для выбора файлов</p>
                    )}
                </div>
                {files.map(file => (
                    <div key={file.name}>
                        <div className="mt-2">
                            <img
                                style={{ maxHeight: '200px' }}
                                src={file.preview}
                                onLoad={() => { URL.revokeObjectURL(file.preview) }}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2" onClick={() => sendFile(
                files, type
            )}>Отправить</button>

        </div >
    );
};
