


export default {
    traid: 'Торговля',
    deposit: 'Пополнение',
    withdrawal: 'Вывод',
    transactions: 'Транзакции',
    deals: 'Сделки',
    profile: 'Профиль',
    analytics: 'Аналитика',
    logout: 'Выйти',
    real: 'Реальный Счет',
    demo: 'Демо Счет',
}
