import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom'; // Или обычный <a> тег, если вы не используете React Router
import { useLanguage } from '@/context/LanguageContext';
import useAuth from '@/hooks/useAuth.hook';
import { menuItems } from '@/layout/pages';

const Menu = () => {
    const { language, getTranslation } = useLanguage();
    const t = getTranslation('Menu')
    const { user } = useAuth();

    useEffect(() => {
        if (user?.permissions?.allowWithdrawal && !menuItems.find(item => item.name === 'withdrawal'))
            menuItems.push({
                name: 'withdrawal', href: '/withdrawal', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            });
    }, [user]);

    return (
        <nav className="bg-gray-800 border-gray-200 border-t">
            <div className="mx-auto px-2 sm:px-6 lg:px-8">
                <div className="relative flex items-center justify-between h-16 overflow-y-auto">
                    <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                        <div className="flex space-x-4">
                            {menuItems.map((item, index) => (
                                <NavLink
                                    key={index}
                                    to={'/' + language.toLowerCase() + item.href}
                                    className={({ isActive }) =>
                                        isActive ? "text-white bg-gray-600 px-3 py-2 rounded-md text-sm font-medium" :
                                            "text-gray-300 bg-gray-700 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                                    }
                                >
                                    {t[item.name]}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Menu;
