// ru

export default {
    // Pending Trade
    pending_trade: 'Назначить время',
    // Choose date and time
    date_and_time: 'Выберите дату и время',
    //Time
    time: 'Время',
    // Investment
    investment: 'Инвестиции',
    // up
    up: 'вверх',
    // down
    down: 'вниз',
    // Your payout
    your_payout: 'Ваша выплата',
    // Deals
    deals: 'Сделки',
    // Pending
    pending: 'В ожидании',
    // В процессе
    in_progress: 'В процессе',
}