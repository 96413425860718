// store.js
import { configureStore } from '@reduxjs/toolkit';
import investmentReducer from './investmentSlice';
import timeReducer from './timeSlice';
import websocketReducer from './socket/websocketSlice';
import dealsReducer from './dealsSlice';
import activesReducer from './activesSlice';
import transactionReducer from './transactionSlice';
import globalAlertReducer from './globalAlertSlice';
import userBalanceReducer from './userBalanceSlice';
import analyticsReducer from './analyticsSlice';
import paymentMethodReducer from './paymentMethodSlice';
import newsReducer from './newsSlice';

export const store = configureStore({
  reducer: {
    paymentMethod: paymentMethodReducer,
    globalAlert: globalAlertReducer,
    transactions: transactionReducer,
    actives: activesReducer,
    deals: dealsReducer,
    websocket: websocketReducer,
    time: timeReducer,
    investment: investmentReducer,
    balance: userBalanceReducer,
    analytics: analyticsReducer,
    news: newsReducer,
  },
});
