
// en

export default {
    //Аккаунт
    account: 'Account',
    //На счету
    on_account: 'On account',
    // Доступно для вывода
    available_for_withdrawal: 'Available for withdrawal',
    // Комиссия
    commission: 'Commission',
    // Ваши прошлые заявки
    last_applications: 'Your past applications',
    // Вывод
    withdrawal: 'Withdrawal',
    // Нет для вывода
    not_for_withdrawal: 'You can withdraw money from your balance to your bank card or electronic wallet that you used to deposit funds. You can request a withdrawal at any time. Your withdrawal requests are processed within 3 business days.',
    // Make a deposit
    make_a_deposit: 'Make a deposit',
    // История выплат
    payment_history: 'Payment history',
    faq: [
            {
                question: 'How to withdraw funds from the account?',
                answer: "The capital withdrawal procedure is extremely simple and is done through your personal account.\n\nThe method you chose to deposit funds is also the method for withdrawing funds.\n\nFor example, if you deposited funds to your account using the Visa payment system, you will also withdraw money through the Visa payment system.\n\nWhen it comes to withdrawing a significant amount, the Company may request verification (verification is requested at the Company's discretion), which is why it is very important to register the account in your own name in order to confirm your rights to it at any time."
            },
            {
                question: 'How long does it take to withdraw funds?',
                answer: 'On average, the fund withdrawal process takes from one to five days from the date of receiving the corresponding request from the Client and depends solely on the volume of requests processed simultaneously. The Company always strives to make payments on the day the request is received from the Client.'
            },
            {
                question: 'Is there a commission for depositing or withdrawing funds from the account?',
                answer: 'No. The Company does not charge a commission for depositing funds or withdrawing funds.\n\nHowever, it is worth considering that payment systems may charge their own commissions and use an internal currency conversion rate.'
            },
            {
                question: 'Are any documents required to withdraw funds?',
                answer: 'Usually, no additional documents are required to withdraw funds. However, at its discretion, the Company may ask you to confirm your personal information by requesting certain documents. This is usually done to prevent actions related to illegal trading, financial manipulations, and the use of funds obtained illegally.\n\nThe list of such documents is minimal, and the process of providing them will not take much time and effort.'
            },
            {
                question: 'What is the minimum withdrawal amount?',
                answer: 'The minimum withdrawal amount starts from 10 USD for most payment systems.\n\nFor cryptocurrencies, this amount starts from 50 USD (and may be higher for some currencies, such as Bitcoin).'
            }
        ]
}
