import React, { useState, useCallback, useEffect } from 'react';
import { Dropzone } from './components/Dropzone';
import Menu from '@/layout/Menu';
import useAuth from '@/hooks/useAuth.hook';
import { useHttp } from '@/hooks/http.hook';
import { useLanguage } from '@/context/LanguageContext';
import { addAlert } from '@/redux/globalAlertSlice';
import { useDispatch } from 'react-redux';

const UserProfile = () => {
    const dispatch = useDispatch();
    const { user, token } = useAuth();
    console.log(user.permissions.allowDataChange)
    const { request, error, loading } = useHttp();
    // Состояния для каждого поля профиля
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthday, setBirthday] = useState('');
    const [country, setCountry] = useState('');
    // State for old password and new password
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordRepeat, setNewPasswordRepeat] = useState('');
    // Photos
    const [photos, setPhotos] = useState(null);
    const { getTranslation } = useLanguage();
    const t = getTranslation('profile');

    // Функция для сохранения профиля (нужно реализовать запрос к API)
    const saveProfile = async () => {
        console.log('saveProfile: ');
        if (!name || !surname || !birthday || !country) {
            dispatch(addAlert({ alert: 'All fields are required', type: 'error' }));
            return;
        }

        try {
            const response = await request('/api/leads/update_lead_details/', 'PUT', {
                first_name: name,
                last_name: surname,
                birthday: birthday,
                geo: country
            });
            if (response.message)
                dispatch(addAlert({ alert: response.message, type: 'success' }))
        } catch (e) {
            dispatch(addAlert({ alert: 'Error updating profile', type: 'error' }));
        }
        // здесь будет запрос к API для сохранения данных
    };

    // /api/leads/update_password/
    const updatePassword = async () => {
        if (newPassword !== newPasswordRepeat) {
            dispatch(addAlert({ alert: 'Passwords do not match', type: 'error' }));
            return;
        }

        try {
            const response = await request('/api/leads/update_password/', 'PUT', {
                old_password: oldPassword,
                new_password: newPassword
            });

            if (response.message)
                dispatch(addAlert({ alert: response.message, type: 'success' }));

        } catch (error) {
            dispatch(addAlert({ alert: 'Error updating password', type: 'error' }));
        }
    };

    // Handle change for old password input
    const handleOldPasswordChange = (e) => {
        setOldPassword(e.target.value);
    };

    // Handle change for new password input
    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    // Handle change for new password repeat input
    const handleNewPasswordRepeatChange = (e) => {
        setNewPasswordRepeat(e.target.value);
    };


    const handleDropDocs = useCallback((acceptedFiles, type) => {
        // console.log('Passport:', acceptedFiles);
        // console.log('Passport:', type);

        return { acceptedFiles, type }
        // /api/lead_photos/add/
    }, []);

    const getUserPhoto = async () => {
        try {
            const data = await request(`/api/lead_photos/get_photo_by_lead/`, 'GET');
            setPhotos(data);
        } catch (e) {
            console.log('e', e);
        }
    }

    useEffect(() => {
        setName(user.first_name);
        setSurname(user.last_name);
        setBirthday(user.birthday);
        setCountry(user.geo);
    }, [user])

    useEffect(() => {
        getUserPhoto()
    }, [])

    const sendFile = async (acceptedFiles, type) => {
        if (!acceptedFiles.length) return;
        if (!user) return;
        if (!token) return;
        if (!type) return;

        const formData = new FormData();
        formData.append('file', acceptedFiles[0]);
        formData.append('photo_type', type);
        formData.append('lead_id', user.id);

        try {
            // default fetch
            const response = await fetch(`/api/lead_photos/add/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });
            const data = await response.json();
            console.log('data', data);
            if (data.created_at)
                dispatch(addAlert({ alert: t.docs_upload_success, type: 'success' }));
        } catch (e) {
            dispatch(addAlert({ alert: t.docs_upload_fail, type: 'error' }));
        }
    }


    return (
        <div className=''>
            <div className="sm:container mx-auto p-1 min-h-screen h-auto">
                <Menu />
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                    {/* Левая колонка */}
                    <div className="bg-gray-900 p-4 rounded-lg">
                        {/* Личная информация */}
                        <h3 className="text-lg leading-6 font-medium text-gray-200  mb-4">{t.personal_info}</h3>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-sm font-medium text-gray-200">{t.email}</label>
                            <input

                                type="email"
                                id="email"
                                defaultValue={user.email}
                                readOnly
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        {/* first_name */}
                        <div className="mb-4">
                            <label htmlFor="first_name" className="block text-sm font-medium text-gray-200">{t.name}</label>
                            <input
                                disabled={!user.permissions.allowDataChange}
                                type="text"
                                id="first_name"
                                name='first_name'
                                defaultValue={name}
                                onChange={(e) => setName(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        {/* last_name */}
                        <div className="mb-4">
                            <label htmlFor="last_name" className="block text-sm font-medium text-gray-200">{t.surname}</label>
                            <input
                                disabled={!user.permissions.allowDataChange}
                                type="text"
                                id="last_name"
                                name='last_name'
                                defaultValue={surname}
                                onChange={(e) => setSurname(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        {/* birthday */}
                        <div className="mb-4">
                            <label htmlFor="birthday" className="block text-sm font-medium text-gray-200">{t.birthday}</label>
                            <input
                                disabled={!user.permissions.allowDataChange}
                                type="date"
                                id="birthday"
                                name='birthday'
                                value={birthday}
                                onChange={(e) => setBirthday(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>
                        {/* country */}
                        <div className="mb-4">
                            <label htmlFor="country" className="block text-sm font-medium text-gray-200">{t.country}</label>
                            <select
                                disabled={!user.permissions.allowDataChange}
                                id="country"
                                name='country'
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            >
                                {/* Empty option */}
                                <option value=""></option>
                                {t.countryList.map(({ value, label }, index) => (
                                    <option key={index} value={value}>{label}</option>
                                ))}
                            </select>
                        </div>
                        {user.permissions.allowDataChange && <button
                            onClick={saveProfile}
                            className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md text-white"
                        >
                            {t.save}
                        </button>}
                    </div>

                    {/* Правая колонка */}
                    <div className="bg-gray-900 p-4 rounded-lg">
                        {/* Верификация документов */}
                        {/* Форма изменения пароля и других настроек безопасности */}
                        <h3 className="text-lg leading-6 font-medium text-gray-200  mb-4">{t.verification}</h3>

                        <section className="sm:container flex flex-col gap-4">
                            <div>
                                {/* find photo_status true */}
                                {photos && photos.some(photo => photo.photo_status === true && photo.type === 'passport') ? <div>
                                    <h3 className="flex text-lg leading-6 font-medium text-gray-200">{t.identity}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 ml-2 w-6 h-6">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                        </svg>
                                    </h3>
                                    <p className="text-green-500">{t.docs_verified}</p></div>
                                    : <>
                                        <Dropzone sendFile={sendFile} label={t.identity} type={'passport'} />
                                        {photos && photos.filter(photo => photo.type === 'passport').length > 0 && <div className='text-orange-500'>Фото загружено и ожидает проверки</div>}
                                    </>}

                                {/* if docs isset set message */}
                                {/* display photo with type "passport" */}
                                <div className="mt-4 flex gap-2 flex-wrap">
                                    {photos && photos.filter(photo => photo.type === 'passport').map((photo, index) => (
                                        <img key={index} className='max-h-[100px]' src={`${process.env.REACT_APP_SIDE_MEDIA_PATH}/${user.id}/${photo.photo_path}`} alt="passport" />
                                    ))}
                                </div>

                            </div>

                            <div>
                                {/* find photo_status true */}
                                {photos && photos.some(photo => photo.photo_status === true && photo.type === 'address') ? <div>
                                    <h3 className="flex text-lg leading-6 font-medium text-gray-200">{t.address}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 ml-2 w-6 h-6">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                        </svg>
                                    </h3>
                                    <p className="text-green-500">{t.docs_verified}</p> </div> : <>
                                    <Dropzone sendFile={sendFile} label={t.address} type={'address'} />
                                    {photos && photos.filter(photo => photo.type === 'address').length > 0 && <div className='text-orange-500'>Фото загружено и ожидает проверки</div>}</>}
                                {/* display photo with type "address" */}
                                <div className="mt-4 flex gap-2 flex-wrap">
                                    {photos && photos.filter(photo => photo.type === 'address').map((photo, index) => (
                                        <img key={index} className='max-h-[100px]' src={`${process.env.REACT_APP_SIDE_MEDIA_PATH}/${user.id}/${photo.photo_path}`} alt="address" />
                                    ))}
                                </div>
                            </div>

                            <div>
                                {/* find photo_status true */}
                                {photos && photos.some(photo => photo.photo_status === true && photo.type === 'employment') ? <div>
                                    <h3 className="flex text-lg leading-6 font-medium text-gray-200">{t.employment}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 ml-2 w-6 h-6">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                        </svg>
                                    </h3>
                                    <p className="text-green-500">{t.docs_verified}</p> </div> : <>
                                    <Dropzone sendFile={sendFile} label={t.employment} type={'employment'} />
                                    {photos && photos.filter(photo => photo.type === 'employment').length > 0 && <div className='text-orange-500'>Фото загружено и ожидает проверки</div>}
                                </>}
                                {/* display photo with type "employment" */}
                                <div className="mt-4 flex gap-2 flex-wrap">
                                    {photos && photos.filter(photo => photo.type === 'employment').map((photo, index) => (
                                        <img key={index} className='max-h-[100px]' src={`${process.env.REACT_APP_SIDE_MEDIA_PATH}/${user.id}/${photo.photo_path}`} alt="employment" />
                                    ))}
                                </div>
                            </div>

                            <div>
                                {/* find photo_status true */}
                                {photos && photos.some(photo => photo.photo_status === true && photo.type === 'card_front') ? <div>
                                    <h3 className="flex text-lg leading-6 font-medium text-gray-200">{t.card_front}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 ml-2 w-6 h-6">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                        </svg>
                                    </h3>
                                    <p className="text-green-500">{t.docs_verified}</p></div> : <>
                                    <Dropzone sendFile={sendFile} label={t.card_front} type={'card_front'} />
                                    {photos && photos.filter(photo => photo.type === 'card_front').length > 0 && <div className='text-orange-500'>Фото загружено и ожидает проверки</div>}
                                </>}
                                {/* display photo with type "card_front" */}
                                <div className="mt-4 flex gap-2 flex-wrap">
                                    {photos && photos.filter(photo => photo.type === 'card_front').map((photo, index) => (
                                        <img key={index} className='max-h-[100px]' src={`${process.env.REACT_APP_SIDE_MEDIA_PATH}/${user.id}/${photo.photo_path}`} alt="card_front" />
                                    ))}
                                </div>
                            </div>

                            <div>
                                {/* find photo_status true */}
                                {photos && photos.some(photo => photo.photo_status === true && photo.type === 'card_back') ? <div>
                                    <h3 className="flex text-lg leading-6 font-medium text-gray-200">{t.card_back}
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 ml-2 w-6 h-6">
                                            <path fillRule="evenodd" d="M8.603 3.799A4.49 4.49 0 0 1 12 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 0 1 3.498 1.307 4.491 4.491 0 0 1 1.307 3.497A4.49 4.49 0 0 1 21.75 12a4.49 4.49 0 0 1-1.549 3.397 4.491 4.491 0 0 1-1.307 3.497 4.491 4.491 0 0 1-3.497 1.307A4.49 4.49 0 0 1 12 21.75a4.49 4.49 0 0 1-3.397-1.549 4.49 4.49 0 0 1-3.498-1.306 4.491 4.491 0 0 1-1.307-3.498A4.49 4.49 0 0 1 2.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 0 1 1.307-3.497 4.49 4.49 0 0 1 3.497-1.307Zm7.007 6.387a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                        </svg>
                                    </h3>
                                    <p className="text-green-500">{t.docs_verified}</p> </div> : <>
                                    <Dropzone sendFile={sendFile} label={t.card_back} type={'card_back'} />
                                    {photos && photos.filter(photo => photo.type === 'card_back').length > 0 && <div className='text-orange-500'>Фото загружено и ожидает проверки</div>}
                                </>}
                                {/* display photo with type "card_back" */}
                                <div className="mt-4 flex gap-2 flex-wrap">
                                    {photos && photos.filter(photo => photo.type === 'card_back').map((photo, index) => (
                                        <img key={index} className='max-h-[100px]' src={`${process.env.REACT_APP_SIDE_MEDIA_PATH}/${user.id}/${photo.photo_path}`} alt="card_back" />
                                    ))}
                                </div>
                            </div>

                        </section>

                    </div>

                    <div className="bg-gray-900 p-4 rounded-lg">
                        {/* Безопасность */}
                        {/* Форма изменения пароля и других настроек безопасности */}
                        <h3 className="text-lg leading-6 font-medium text-gray-200 mb-4">{t.security}</h3>

                        {/* Старый пароль */}
                        <div className="mb-4">
                            <label htmlFor="old_password" className="block text-sm font-medium text-gray-200">{t.old_password}</label>
                            <input
                                type="password"
                                id="old_password"
                                name='old_password'
                                onChange={handleOldPasswordChange}
                                value={oldPassword}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        {/* Новый пароль */}
                        <div className="mb-4">
                            <label htmlFor="new_password" className="block text-sm font-medium text-gray-200">{t.new_password}</label>
                            <input
                                type="password"
                                id="new_password"
                                name='new_password'
                                onChange={handleNewPasswordChange}
                                value={newPassword}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        {/* Повтор нового пароля */}
                        <div className="mb-4">
                            <label htmlFor="new_password_repeat" className="block text-sm font-medium text-gray-200">{t.repit_new_password}</label>
                            <input
                                type="password"
                                id="new_password_repeat"
                                name='new_password_repeat'
                                onChange={handleNewPasswordRepeatChange}
                                value={newPasswordRepeat}
                                className="mt-1 block w-full px-3 py-2 bg-gray-700 border border-gray-600 rounded-md text-white shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                        </div>

                        <button
                            onClick={updatePassword}
                            className="mt-4 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md text-white"
                        >
                            {t.save}
                        </button>
                    </div>
                </div>

                {/* Кнопка сохранения */}

            </div>
        </div>
    );
};

export default UserProfile;
