// Deposit.jsx
import React, { useEffect, useState } from 'react';
import Menu from '@/layout/Menu';
import { Link } from 'react-router-dom';
import FAQAccordion from './components/Faq';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormater } from '@/utils/dateFormater';
import { fetchTransactions } from '@/redux/transactionSlice';
import useAuth from '@/hooks/useAuth.hook';
import { operationStatus } from '@/utils/operationStatus';
import { fetchUserBalance, fetchUserWithdrawal } from '@/redux/userBalanceSlice';
import { addAlert } from '@/redux/globalAlertSlice';
import { useLanguage } from '@/context/LanguageContext';
import { fetchPaymentMethods } from '@/redux/paymentMethodSlice';

const PaymentDataForm = ({ predefinedAmounts, amount, setAmount }) => {
    const { wallet } = useSelector(state => state.balance);

    const handleAmountChange = (e) => {
        const newAmount = Math.max(0, parseFloat(e.target.value).toFixed(2));
        setAmount(newAmount > wallet.balance ? wallet.balance.toFixed(2) : newAmount);
    };

    return (
        <div className="text-white">
            <div className="mb-4">
                <input
                    id="amount"
                    type="number"
                    value={amount}
                    onChange={handleAmountChange}
                    min={0}
                    max={wallet.balance}
                    className="mt-1 p-2 block w-full bg-gray-700 border-transparent rounded-md"
                />
            </div>
            <div className="flex space-x-2">
                {predefinedAmounts.map((amt) => (
                    <button
                        key={amt}
                        onClick={() => setAmount(amt)}
                        className="px-4 py-2 bg-blue-500 rounded-md"
                    >
                        {amt} $
                    </button>
                ))}
            </div>
        </div>
    );
};

const Txns = () => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { txns, status, error } = useSelector(state => state.transactions);
    const { language, getTranslation } = useLanguage();
    const t = getTranslation('withdrawal');
    const transactionsLang = getTranslation('transactions');

    useEffect(() => {
        if (status === 'idle' && token) {
            dispatch(fetchTransactions({ token }))
        }
    }, [dispatch, token]);

    return <div className="bg-gray-800 p-4 rounded-lg shadow-md">
        <div className="text-white mb-4">
            <h3 className="text-lg font-semibold">{t.last_applications}:</h3>
        </div>
        <div className="overflow-x-auto">
            <table className="w-full text-white">
                <tbody>
                    {/* Массив данных для строк таблицы */}
                    {txns
                        .slice(-5)
                        .map((item) => (
                            <tr key={item.id} className="border-b border-gray-700">
                                <td className="p-2">{item.id}</td>
                                <td className="p-2">{dateFormater(item.created_at)}</td>
                                <td className="p-2">
                                    {operationStatus(item.status, transactionsLang)}
                                </td>
                                <td className="p-2">{item.currency}</td>
                                <td className={`p-2 ${Number(item.sum) > 0 ? 'text-green-500' : 'text-red-500'}`}>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                    item.sum
                                )}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
        <div className="text-right mt-4">
            <Link to={'/' + language.toLowerCase() + '/transactions'} className="text-blue-400 hover:text-blue-300">{t.payment_history} &gt;</Link>
        </div>
    </div>

}
const Withdrawal = () => {
    const { wallet, wzdStatus, wzdError } = useSelector(state => state.balance);
    const { token, user } = useAuth();
    const [amount, setAmount] = useState('');
    const { language, getTranslation } = useLanguage();
    const t = getTranslation('withdrawal');

    const dispatch = useDispatch();

    const withdrawal = (amount) => {
        if (amount <= 10) {
            dispatch(addAlert({ type: 'error', alert: 'Сумма вывода должна быть больше 0' }));
        } else {
            dispatch(fetchUserWithdrawal({ token, amount }));
        }
    }

    React.useEffect(() => {
        if (wzdStatus === 'succeeded') {
            dispatch(fetchTransactions({ token }));
            dispatch(fetchUserBalance(token));
            dispatch(addAlert({ type: 'success', alert: 'Запрос на вывод средств успешно отправлен' }));
        }

        if (wzdStatus === 'failed') {
            dispatch(addAlert({ type: 'error', alert: 'Ошибка при выводе средств, попробуйте позже' }));
        }

        return () => {
            setAmount(0);
        }
    }, [wzdStatus]);

    useEffect(() => {
        dispatch(fetchTransactions({ token }));
    }, [token]);

    const handleAmountChange = (val) => {
        const newAmount = Math.max(0, parseFloat(val).toFixed(2));
        setAmount(newAmount > wallet.balance ? wallet.balance.toFixed(2) : newAmount);
    }

    return (
        <div className='sm:container mx-auto'>
            <Menu />

            <div className="grid grid-cols-1 md:grid-cols-2 justify-between bg-gray-900 rounded-md m-2 md:m-4 md:py-4 gap-4 md:px-8 mx-auto md:my-8 divide-y lg:divide-y-0 lg:divide-x divide-dashed divide-gray-200 min-h-screen md:min-h-[calc(100vh-180px)]">
                <div className='w-full'>
                    <div className="bg-gray-800 p-4 rounded-lg shadow-md flex flex-col md:flex-row flex-wrap xl:flex-nowrap w-full justify-between">
                        <div className="text-white ">
                            <h3 className="text-lg font-semibold mb-2 basis-full">{t.account}</h3>
                            <div className='flex md:flex-col md:items-start items-end mb-4'>
                                <div className="mb-0 md:mb-2 w-1/3 md:w-auto">
                                    <span className="text-sm text-gray-400">{t.on_account}:</span>
                                    <div className="text-xl font-bold">{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                        parseFloat(wallet.available_funds)
                                    )}</div>
                                </div>

                                <div className="mb-0 md:mb-2 w-1/3 md:w-auto">
                                    <span className="text-sm text-gray-400">{t.available_for_withdrawal}:</span>
                                    <div className="text-xl font-bold">{
                                        new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                            wallet.balance
                                        )
                                    }</div>
                                </div>

                            </div>
                        </div>
                        <div className="text-white max-w-sm">
                            <h3 className="text-lg font-semibold mb-2">{t.withdrawal}:</h3>
                            {+wallet.balance <= 0 ? <div className="p-4 bg-red-600 bg-opacity-50 rounded-lg">
                                <p className="mb-2">{t.not_for_withdrawal}</p>
                                <Link to={'/' + language.toLowerCase() + '/deposit'} className="flex gap-2 w-fit bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">{t.make_a_deposit} <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clipRule="evenodd" />
                                </svg></Link>
                            </div> : <div className='w-full'>
                                <PaymentDataForm predefinedAmounts={[150, 200, 300, 500]} amount={amount} setAmount={handleAmountChange} />
                                <button onClick={() => withdrawal(amount)} className="flex gap-2 text-white mt-4 px-4 py-2 bg-green-500 rounded-md">{t.withdrawal}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clipRule="evenodd" />
                                </svg>
                                </button>
                            </div>}
                        </div>
                    </div>
                    <Txns />
                </div>
                <div className='max-w-2xl w-full'>
                    <FAQAccordion />
                </div>
            </div>

        </div>
    );
};

export default Withdrawal;
