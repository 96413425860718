//pl
//Fields: DATA	SUMA	TYP	STATUS	KARTA	WALUTA	METODA

export default {
    date: 'Data',
    amount: 'Suma',
    type: 'Typ',
    status: 'Status',
    card: 'Karta',
    currency: 'Waluta',
    method: 'Metoda',
    // operation status
    operationStatus: (status) => {
        switch (status) {
            case 'success':
                return 'Sukces';
            case 'pending':
                return 'Oczekujący';
            case 'failed':
                return 'Niepowodzenie';
            case 'error':
                return 'Błąd';
            default:
                return '-';
        }
    },
    operationType: (type) => {
        switch (type) {
            case 'deposit':
                return 'Depozyt';
            case 'withdrawal':
                return 'Wypłata';
            case 'bonus':
                return 'Bonus';
            default:
                return '-';
        }
    }
}