import { formatDuration } from '@/utils/formatDuration';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export function DealTimer({ deal }) {
    const [timeLeft, setTimeLeft] = useState(null);
    useEffect(() => {
        if (deal.status === 'open') {
            const dateObject = new Date(deal.open_time + "Z");
            const isoDateTime = dateObject.toISOString();

            const openTime = new Date(isoDateTime); // Время открытия сделки
            const durationSeconds = deal.duration; // Продолжительность сделки в секундах
            const endTime = new Date(openTime.getTime() + durationSeconds * 1000); // Время закрытия сделки

            // Периодическое обновление таймера
            const intervalId = setInterval(() => {
                const currentTime = new Date(); // Текущее время
                const timeDifference = endTime - currentTime; // Разница во времени
                if (timeDifference <= 0) {
                    clearInterval(intervalId); // Очистка интервала, если время вышло
                    setTimeLeft(null);
                } else {
                    const secondsLeft = Math.floor(timeDifference / 1000); // Оставшееся время в секундах
                    setTimeLeft(secondsLeft);
                }
            }, 1000);

            // Очистка интервала при размонтировании компонента
            return () => clearInterval(intervalId);
        }
    }, [deal]);


    if (!timeLeft)
        return formatDuration(deal.duration);

    return (
        <div>
            {deal.status === 'open' && timeLeft !== null && (
                <div className=''>
                    {formatDuration(timeLeft, 'mm:ss')}
                    {/* 
                    <CircularProgressbar
                        value={(timeLeft / deal.duration) * 100}
                        text={formatDuration(timeLeft)}
                        styles={buildStyles({
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: 'round',
                            // Text size
                            textSize: '16px',
                            // Colors
                            pathColor: `rgba(195, 0, 0, ${(timeLeft / deal.duration) * 100 / 100})`,
                            textColor: '#f88',
                            trailColor: '#d6d6d6',
                            backgroundColor: '#3e98c7',
                        })}
                    /> */}

                </div>
            )}
        </div>
    );
}
