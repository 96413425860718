// pl

export default {
    // Całkowity zysk
    total_profit: 'Całkowity zysk',
    // Średni zysk na transakcję
    average_profit_per_trade: 'Średni zysk na transakcję',
    // Najbardziej dochodowa transakcja
    most_profitable_trade: 'Najbardziej dochodowa transakcja',
    // Transakcja na kwotę
    deal_for_amount: 'Transakcja na kwotę',
    // Zysk
    profit: 'Zysk',
    // Data
    date: 'Data',
    // Czas trwania
    duration: 'Czas trwania',
    // Analiza instrumentów
    instruments_analytics: 'Analiza instrumentów',
    // Udane transakcje
    successful_deals: 'Udane transakcje',
    //dzisiaj
    today: 'Dzisiaj',
    //wczoraj 
    yesterday: 'Wczoraj',
    // trzydzieści dni
    thirtyDays: '30 dni',
}
