//es
//Fields: FECHA	MONTO	TIPO	ESTADO	TARJETA	MONEDA	MÉTODO

export default {
    date: 'Fecha',
    amount: 'Monto',
    type: 'Tipo',
    status: 'Estado',
    card: 'Tarjeta',
    currency: 'Moneda',
    method: 'Método',
    // operation status
    operationStatus: (status) => {
        switch (status) {
            case 'success':
                return 'Éxito';
            case 'pending':
                return 'Pendiente';
            case 'failed':
                return 'Fallido';
            case 'error':
                return 'Error';
            default:
                return '-';
        }
    },
    operationType: (type) => {
        switch (type) {
            case 'deposit':
                return 'Depósito';
            case 'withdrawal':
                return 'Retiro';
            case 'bonus':
                return 'Bono';
            default:
                return '-';
        }
    }
}