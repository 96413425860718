import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

const ActivesSelector = ({ selectOption }) => {
    const actives = useSelector(state => state.actives.items);
    const selectedOption = useSelector(state => state.actives.selected);
    // Группировка активов по имени
    const groupedAssets = useMemo(() => {
        return actives.reduce((acc, asset) => {
            if (!acc[asset.name]) {
                acc[asset.name] = [];
            }
            acc[asset.name].push(asset);
            return acc;
        }, {});
    }, [actives]);

    const [activeTab, setActiveTab] = useState(Object.keys(groupedAssets)[0] || '');

    return (
        <div className="content ">
            <h2 className="text-lg mb-4 md:text-2xl text-white">Market</h2>
            <div className="tabs flex gap-2 w-full overflow-auto">
                {Object.keys(groupedAssets).map((name) => (
                    <button key={name} onClick={() => setActiveTab(name)} className={`relative inline-block text-left text-xs flex items-center gap-2 bg-gray-900 px-4 py-2 rounded-lg cursor-pointer hover:bg-opacity-80 ${activeTab === name ? 'active' : ''}`}>
                        {name}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`duration-300 w-6 h-6 ${activeTab === name ? 'rotate-90' : ''}`}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                    </button>
                ))}
            </div>
            <div className="content my-8">
                {groupedAssets[activeTab]?.map((asset) => (
                    <div key={asset.id} className="asset">
                        <a href="#" onClick={() => selectOption(asset)} className={`flex space-x-4 px-4 py-2 text-sm md:text-xl text-white hover:bg-gray-700 ${selectedOption === asset ? 'bg-gray-700' : ''}`} role="menuitem">
                            {asset.type.split("/").length === 2 ? (
                                <div className="flex items-center min-w-12">
                                    {/* Валютная пара: отображаем две иконки */}
                                    <img className="tab__flag tab__flag__selector flag-aud" src={`/media/actives/${asset.type.split("/")[0].toLowerCase().trim()}.svg`} alt="Flag"/>
                                    <img className="tab__flag tab__flag__selector -ml-2 mt-1" style={{ marginLeft: -8, marginTop: 8 }} src={`/media/actives/${asset.type.split("/")[1].toLowerCase().trim()}.svg`} alt="Flag" />
                                </div>
                            ) : (
                                <div className="flex items-center min-w-12">
                                    {/* Одиночный актив: отображаем одну иконку */}
                                    <img className="tab__flag tab__flag__selector" src={`/media/actives/${asset.type.toLowerCase().trim()}.svg`} alt="Flag" />
                                </div>
                            )}
                            <span className="text-sm md:text-xl">{asset.type}</span>
                            <span className="text-sm md:text-xl">${asset.buy}</span>
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ActivesSelector;