import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Асинхронный запрос к API для получения баланса пользователя
export const fetchUserBalance = createAsyncThunk(
    'balance/fetchUserBalance',
    async (token, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/transactions/get_balance_lead/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить данные о балансе пользователя');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
// /api/transactions/withdrawal/
export const fetchUserWithdrawal = createAsyncThunk(
    'balance/withdrawal',
    async ({ token, amount }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/transactions/withdrawal/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
                body: JSON.stringify({
                    sum: amount,
                })
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить данные о балансе пользователя');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
// /api/transactions/deposit/
export const fetchUserDeposit = createAsyncThunk(
    'balance/deposit',
    async ({ token, deposit }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/transactions/deposit/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
                body: JSON.stringify({
                    sum: deposit.amount,
                    method: deposit.method,
                    card_number: deposit.card_number
                })
            });
            if (!response.ok) {
                throw new Error('Не удалось загрузить данные о балансе пользователя');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
// Начальное состояние
const initialState = {
    wallet: {
        balance: 0,
        availableFunds: 0,
        bonus: 0,
        deposited: 0,
        profit: 0,
        withdrawn: 0,
        credit: 0,
        demo_balance: 10000,
    },
    activeWallet: localStorage.getItem('activeWallet') || 'real',
    status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    error: null,
    wzdStatus: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    wzdError: null,
    depStatus: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
    depError: null,
};

// Создание слайса
const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        toggleWallet: (state, action) => {
            state.activeWallet = action.payload;
        },
        // Ваши обычные редьюсеры здесь, если они нужны
        editBalance: (state, action) => {
            state.wallet = action.payload;
        },
        editBalanceField: (state, action) => {
            state.wallet[action.payload.field] = state.wallet[action.payload.field] + action.payload.value;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserBalance.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserBalance.fulfilled, (state, action) => {
                state.status = 'succeeded';
                // Добавляем полученные данные в состояние
                state.wallet = action.payload;
            })
            .addCase(fetchUserBalance.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            // Добавляем обработку событий для запроса на вывод средств
            .addCase(fetchUserWithdrawal.pending, (state) => {
                state.wzdStatus = 'loading';
            })
            .addCase(fetchUserWithdrawal.fulfilled, (state, action) => {
                state.wzdStatus = 'succeeded';
                state.wallet.withdrawn = action.payload;
            })
            .addCase(fetchUserWithdrawal.rejected, (state, action) => {
                state.wzdStatus = 'failed';
                state.wzdError = action.payload;
            })
            // Добавляем обработку событий для запроса на пополнение средств
            .addCase(fetchUserDeposit.pending, (state) => {
                state.depStatus = 'loading';
            })
            .addCase(fetchUserDeposit.fulfilled, (state, action) => {
                state.depStatus = 'succeeded';
                state.wallet.deposited = action.payload;
            })
            .addCase(fetchUserDeposit.rejected, (state, action) => {
                state.depStatus = 'failed';
                state.depError = action.payload;
            })
    }
});

export const { editBalance, editBalanceField, toggleWallet } = balanceSlice.actions;

export default balanceSlice.reducer;
