import React, { useEffect } from 'react';
import { dateFormater } from '@/utils/dateFormater';
import { operationStatus, operationType } from '@/utils/operationStatus';
import { useLanguage } from '@/context/LanguageContext';
import useAuth from '@/hooks/useAuth.hook';
import { fetchTransactions, setCurrentPage } from '@/redux/transactionSlice';
import { useDispatch } from 'react-redux';
import ReactPaginate from 'react-paginate';


const Table = ({ txns, txnsPerPage, currentPage, total }) => {
    const { token } = useAuth();
    const { getTranslation } = useLanguage();
    const t = getTranslation('transactions')
    const transactionsLang = useLanguage().getTranslation('transactions');
    const dispatch = useDispatch();

    useEffect(() => {
        // Получаем данные при монтировании компонента и при изменении текущей страницы
        dispatch(fetchTransactions({ token, limit: txnsPerPage, offset: (currentPage - 1) * txnsPerPage }));
    }, [dispatch, currentPage, txnsPerPage]);


    const handlePageChange = (newPage) => {
        dispatch(setCurrentPage(newPage.selected + 1));
    };

    const pageNumbers = Math.ceil(total / txnsPerPage);

    return (
        <div className="min-h-screen h-auto">
            {/* pagination */}
            <div className="mb-2 text-white">
                {/* pagination */}
                <ReactPaginate
                    previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                    nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageNumbers}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'rounded-full border'}
                    className="flex justify-center mt-4 items-center gap-1"
                    pageClassName="flex items-center justify-center w-8 h-8 bg-gray-800"
                />
            </div>
            <div className='overflow-x-auto'>
                <table className="min-w-full bg-gray-700 text-white">
                    <thead>
                        <tr>
                            {/* Заголовки таблицы */}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.date}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.amount}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.type}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.status}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.card}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.currency}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.method}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Строки таблицы */}
                        {txns.map((item, index) => (
                            <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}`}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="ml-4">
                                            <div className="text-sm text-gray-400">{dateFormater(item.created_at)}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-white">
                                        {item.sum ? new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                            parseFloat(item.sum)
                                        ) : '-'}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-white">{operationType(item.type, transactionsLang) ?? '-'}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-white">{operationStatus(item.status, transactionsLang) ?? '-'}</div>
                                </td>

                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-green-500">{item.card_number}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-white">{item.currency}</div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-white">{item.method}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;
