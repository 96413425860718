import React from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from '@/context/LanguageContext';
import { DealTimer } from '../DealCard';

const DealCard = React.memo(({ deal }) => {
    const [openDetails, setOpenDetails] = React.useState(false);
    const { getTranslation } = useLanguage();
    const t = getTranslation('sidebar');
    const actives = useSelector(state => state.actives.items);

    const handleOpenDetails = () => {
        setOpenDetails(!openDetails);
    }

    return (
        <div className="flex flex-col border-b border-gray-400 border-dashed p-2 shadow-lg bg-opacity-50">
            {/* Display the deal information */}
            <div className={`flex items-center ${deal.status === 'close' && 'cursor-pointer'}`} onClick={handleOpenDetails}>

                {deal.status === 'close' && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-4 h-4 mr-1 duration-300 ${openDetails && 'rotate-180'} `}>
                    <path fillRule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clipRule="evenodd" />
                </svg>}
                <div className='flex-1'>

                    <div className="flex justify-between">
                        <div className='font-bold'>{actives.find(active => active.id === deal.active_id)?.type}</div>
                        <div><DealTimer deal={deal} /></div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="flex gap-1 items-center">
                            <div className={`flex rounded-md  gap-2 ${deal.type === 'up' ? 'text-green-600' : 'text-red-600'}`}>{deal.type === 'up' ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" w-4 h-4">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clipRule="evenodd" />
                            </svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clipRule="evenodd" />
                            </svg>}</div>
                            <div className="text-green-500">${deal.value}</div>
                        </div>
                        {deal.status != 'close' ? <div className="text-yellow-500">{t.in_progress}</div> : <div className={`${deal.profit && Number(deal.profit) > 0 ? 'text-green-500' : 'text-red-500'}`}>${deal.profit && deal.profit != 0 ? deal.profit.toFixed(2) : 0.00}</div>
                        }
                    </div>
                </div>
            </div>
            {/* Detail */}
            {deal.status === 'close' && <div className={`detail-container grid grid-cols-2 gap-2 ${openDetails ? 'open' : ''}`}>
                {/* if win */}
                <div className='col-span-full'>
                    {Number(deal.profit) > 0 ? <img src="/media/deals/win.png" className='w-full max-h-[100px]'/> : <img className='w-full max-h-[100px]' src="/media/deals/loos.png" />}
                </div>
                <div>
                    <span className='text-zinc-500'>Торговая пара:</span><br /> <span>{actives.find(active => active.id === deal.active_id)?.type}</span>
                </div>
                <div>
                    <span className='text-zinc-500'>Сумма сделки:</span><br /> <span>{deal.value}</span>
                </div>
                <div>
                    <span className='text-zinc-500'>Цена открытия:</span><br /> <span>{deal.open_price}</span>
                </div>
                <div>
                    <span className='text-zinc-500'>Цена закрытия:</span><br /> <span>{deal.close_price}</span>
                </div>
                <div>
                    <span className='text-zinc-500'>Время открытия:</span> <br /> <span>{new Date(deal.open_time).toLocaleString()}</span>
                </div>
                <div>
                    <span className='text-zinc-500'>Время закрытия:</span> <br /> <span>{deal.close_time ? new Date(deal.close_time).toLocaleString() : 'В процессе'}</span>
                </div>

                <div>
                    <span className='text-zinc-500'>Разница:</span><br /> <span>{(deal.open_price - deal.close_price).toFixed(5)}</span>
                </div>
            </div>}
        </div>
    );
})

export default DealCard;