// en

export default {
    // Total Profit
    total_profit: 'Total Profit',
    // Average Profit per Trade
    average_profit_per_trade: 'Average Profit per Trade',
    // Most Profitable Trade
    most_profitable_trade: 'Most Profitable Trade',
    // Deal for Amount
    deal_for_amount: 'Deal for Amount',
    // Profit
    profit: 'Profit',
    // Date
    date: 'Date',
    // Duration
    duration: 'Duration',
    // Instruments Analytics
    instruments_analytics: 'Instruments Analytics',
    // Successful Deals
    successful_deals: 'Successful Deals',
    //today
    today: 'Today',
    //yesterday 
    yesterday: 'Yesterday',
    // thirty days
    thirtyDays: '30 days',
}
