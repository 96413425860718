export default {
    traid: 'Comercio',
    deposit: 'Depósito',
    withdrawal: 'Retiro',
    transactions: 'Transacciones',
    deals: 'Operaciones',
    profile: 'Perfil',
    analytics: 'Análisis',
    logout: 'Cerrar sesión',
    real: 'Cuenta Real',
    demo: 'Cuenta de demostración'
}
