import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';


export const fetchPaymentMethods = createAsyncThunk(
    'paymentMethod/fetchPaymentMethods',
    async ({ token, desk_id = 0 }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/wallets/get_wallet_by_desk/${desk_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить даные');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    paymentMethods: [],
    total: 0,
    status: 'idle',
    error: null
};


const paymentMethodSlice = createSlice({
    name: 'paymentMethod',
    initialState,
    reducers: {
        setPaymentMethod: (state, action) => {
            return action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchPaymentMethods.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchPaymentMethods.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(fetchPaymentMethods.fulfilled, (state, action) => {
                state.status = 'succeeded';
                const groupedWallets = _.groupBy(action.payload, (item) => item.type);
                state.paymentMethods = groupedWallets;
            });
    }
});

export const { setPaymentMethod } = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;