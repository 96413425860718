import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


export const fetchNews = createAsyncThunk(
    'news/fetchNews',
    async ({ token, limit = 100, offset = 0 }, { rejectWithValue }) => {
        const response = await fetch(`/api/news/get_news_by_lead`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
            },
        });
        if (!response.ok) {
            return rejectWithValue('Failed to fetch news');
        }
        const data = await response.json();
        return data;
    }
);

const initialState = {
    news: [],
    status: 'idle',
    error: null,
};

const newsSlice = createSlice({
    name: 'news',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchNews.pending, (state, action) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchNews.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.news = action.payload.news;
            })
            .addCase(fetchNews.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    }

});

export const { fetchNewsStart, fetchNewsSuccess, fetchNewsFailure } = newsSlice.actions;

export default newsSlice.reducer;
