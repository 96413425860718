//pl

export default {
  //Deposit
  deposit: 'Wpłata',
  // Deposit success alert
  depositSuccess: 'Twoje konto zostało pomyślnie doładowane',
  // Deposit error alert
  depositError: 'Błąd podczas wpłaty na konto',
  // The amount
  the_amount: 'Kwota',
  //Payment method
  payment_method: 'Metoda płatności',
  // Min amount
  min_amount: 'Minimalna kwota',
  // Max amount
  max_amount: 'Maksymalna kwota',
  depositSuccessMsg: 'Twoja wpłata została wysłana do przetwarzania.\nTwój stan konta zostanie zaktualizowany w ciągu 5 minut.',
  // Минимальная сумма пополнения 10$
  min_amount_10: 'Minimalna kwota wpłaty 10$',
  // Максимальная сумма пополнения 50000$
  max_amount_50000: 'Maksymalna kwota wpłaty 50000$',
  payment: {
    other: ['Skopiuj numer karty', 'Otwórz aplikację bankową', 'Przelej dokładną kwotę jednym płatnością'],
    crypto: ['Skopiuj numer portfela', 'Przelej dokładną kwotę jednym płatnością'],
    // Kwota do przelania
    amount_to_transfer: 'Kwota do przelania',
    // Ta kwota nie zawiera opłaty bankowej
    amount_to_transfer_hint: 'Ta kwota nie zawiera opłaty bankowej',
    // Numer karty do przelania
    card_number: 'Numer karty do przelania',
    // Anuluj żądanie
    cancel_request: 'Anuluj żądanie',
    // Zapłaciłem
    i_paid: 'Zapłaciłem',
  },

  faq: [
    {
      question: 'Czy istnieje minimalna kwota, którą mogę wpłacić na swoje konto przy rejestracji?',
      answer: 'Zaletą platformy handlowej firmy jest to, że nie musisz wpłacać dużych kwot na swoje konto. Możesz rozpocząć handel, inwestując niewielką ilość pieniędzy. Minimalna wpłata wynosi 10 dolarów amerykańskich.'
    },
    {
      question: 'Czy muszę wpłacać na konto platformy handlowej i jak często muszę to robić?',
      answer: `Aby pracować z opcjami binarnymi, musisz otworzyć indywidualne konto. Aby zawierać rzeczywiste transakcje, koniecznie będziesz musiał wpłacić kwotę zakupionych opcji.
          \n\n
          Możesz rozpocząć handel bez gotówki, korzystając tylko z konta szkoleniowego firmy (konto demo). Takie konto jest bezpłatne i zostało stworzone w celu pokazania funkcjonowania platformy handlowej. Za pomocą takiego konta możesz praktykować nabywanie opcji binarnych, zrozumieć podstawowe zasady handlu, przetestować różne metody i strategie lub ocenić poziom swojej intuicji.
          `
    },
    {
      question: 'Jak mogę dokonać wpłaty?',
      answer: `To bardzo proste. Procedura zajmie kilka minut.
          \n\n
          1) Otwórz okno realizacji transakcji i kliknij zielony przycisk "Wpłata" w prawym górnym rogu karty.
          \n\n
          Możesz również wpłacić na konto za pośrednictwem swojego Konta Osobistego, klikając przycisk "Wpłata" w profilu konta.
          \n\n
          2) Następnie należy wybrać metodę wpłaty na konto (Firma oferuje wiele wygodnych metod dostępnych dla Klienta i wyświetlanych na jego indywidualnym koncie).
          \n\n
          3) Następnie należy wskazać walutę, na którą zostanie wpłacone konto, a odpowiednio walutę samego konta.
          \n\n
          4) Wprowadź kwotę wpłaty.
          \n\n
          5) Wypełnij formularz, podając żądane dane płatności.
          \n\n
          6) Dokonaj płatności.`
    },
    {
      question: 'Jaka jest minimalna kwota wpłaty?',
      answer: `Zaletą platformy handlowej firmy jest to, że nie musisz wpłacać dużych kwot na swoje konto. Możesz rozpocząć handel, inwestując niewielką ilość pieniędzy. Minimalna wpłata wynosi 10 dolarów amerykańskich.`
    },
    {
      question: 'Czy istnieje jakakolwiek opłata za wpłatę lub wypłatę środków z konta?',
      answer: `Nie. Firma nie pobiera żadnych opłat za wpłatę ani za operacje wypłaty.
          \n\n
          Należy jednak wziąć pod uwagę, że systemy płatności mogą pobierać swoje opłaty i korzystać z wewnętrznego kursu wymiany walut.`
    }
  ]
}