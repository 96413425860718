// investmentSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const investmentSlice = createSlice({
  name: 'investment',
  initialState: {
    value: 10,
  },
  reducers: {
    increaseInvestment: (state, action) => {
      state.value = Math.min(Math.min(3000, action.payload), state.value + 1);
    },
    decreaseInvestment: (state) => {
      state.value = Math.max(1, state.value - 1);
    },
    setInvestment: (state, action) => {
      state.value = Math.min(3000, Math.max(1, action.payload));
    },
  },
});

// Экспортируем сгенерированные действия
export const { increaseInvestment, decreaseInvestment, setInvestment } = investmentSlice.actions;

export default investmentSlice.reducer;
