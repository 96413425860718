import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import useAuth from '@/hooks/useAuth.hook';
import { useSelector, useDispatch } from 'react-redux';
import { useLanguage } from '@/context/LanguageContext';
import { toggleWallet } from '@/redux/userBalanceSlice';
import { menuItems } from '@/layout/pages';

const DropdownMenu = () => {
    const dispatch = useDispatch();
    const { language, getTranslation } = useLanguage();
    const t = getTranslation('Menu');
    const auth = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { wallet, activeWallet } = useSelector(state => state.balance);

    useEffect(() => {
        if (auth?.user?.permissions?.allowWithdrawal && !menuItems.find(item => item.name === 'withdrawal')) {
            menuItems.push({ name: 'withdrawal', link: '/withdrawal' });
        }
    }, [auth]);

    const handleToggleWallet = () => {
        dispatch(toggleWallet(activeWallet === 'real' ? 'demo' : 'real'));
        // add to localStorage
        localStorage.setItem('activeWallet', activeWallet === 'real' ? 'demo' : 'real');
    };

    const handleItemClick = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        // Функция для проверки клика вне компонента
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Добавление обработчика события при монтировании компонента
        document.addEventListener('mousedown', handleClickOutside);

        // Удаление обработчика события при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-xs text-left" ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)} className="" id="options-menu" aria-haspopup="true" aria-expanded={isOpen}>
                <div className='flex items-center gap-2 bg-gray-900 px-4 py-1 md:py-2 rounded-lg cursor-pointer hover:bg-opacity-80'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-green-400">
                        <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                    </svg>
                    <span className='text-white'>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                        activeWallet === 'real' ? wallet.available_funds : wallet.demo_balance
                    )}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                    </svg>
                </div>
            </button>

            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="absolute right-0 z-50 mt-2 origin-top-right rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 focus:outline-none z-[999]"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                <div className="py-1">
                    <div className="flex  items-center cursor-pointer px-4 py-2 text-sm  hover:bg-gray-700">
                        <button onClick={handleToggleWallet} className="text-gray-200 text-left hover:text-white">
                            {t[activeWallet === 'real' ? 'demo' : 'real']}<br />
                            <span className='text-green-500'>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                parseFloat(activeWallet === 'real' ? wallet.demo_balance : wallet.available_funds)
                            )}</span>
                        </button>
                    </div>
                    <div className="border-t border-gray-600"></div>

                    {menuItems.map((item, index) => (
                        <Link
                            onClick={handleItemClick}
                            key={index}
                            to={language.toLowerCase() + item.href}
                            className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-700 hover:text-white"
                            role="menuitem"
                        >
                            {t[item.name]}
                        </Link>
                    ))}
                    <div className="border-t border-gray-600"></div>
                    <div
                        className="flex items-center cursor-pointer px-4 py-2 text-sm text-red-500 hover:bg-gray-700"
                        role="menuitem"
                        onClick={auth.logOut}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                        </svg>
                        {t.logout}
                    </div>
                </div>
            </Transition>
        </div>
    );
};

export default DropdownMenu;
