// es

export default {
    // Beneficio Total
    total_profit: 'Beneficio Total',
    // Beneficio Promedio por Operación
    average_profit_per_trade: 'Beneficio Promedio por Operación',
    // Operación más Rentable
    most_profitable_trade: 'Operación más Rentable',
    // Operación por Monto
    deal_for_amount: 'Operación por Monto',
    // Beneficio
    profit: 'Beneficio',
    // Fecha
    date: 'Fecha',
    // Duración
    duration: 'Duración',
    // Análisis de Instrumentos
    instruments_analytics: 'Análisis de Instrumentos',
    // Operaciones Exitosas
    successful_deals: 'Operaciones Exitosas',
    // Hoy
    today: 'Hoy',
    // Ayer
    yesterday: 'Ayer',
    // 30 días
    thirtyDays: '30 días',
}