//es

export default {
  //Depósito
  deposit: 'Depósito',
  // Alerta de depósito exitoso
  depositSuccess: 'Tu cuenta ha sido financiada exitosamente',
  // Alerta de error de depósito
  depositError: 'Error al depositar en la cuenta',
  // La cantidad
  the_amount: 'Cantidad',
  //Método de pago
  payment_method: 'Método de pago',
  // Cantidad mínima
  min_amount: 'Cantidad mínima',
  // Cantidad máxima
  max_amount: 'Cantidad máxima',
  depositSuccessMsg: 'Tu pago ha sido enviado para su procesamiento.\nTu saldo se acreditará en un plazo de 5 minutos.',
  // Минимальная сумма пополнения 10$
  min_amount_10: 'Cantidad mínima de depósito 10$',
  // Максимальная сумма пополнения 50000$
  max_amount_50000: 'Cantidad máxima de depósito 50000$',

  payment: {
    other: ['Copie el número de tarjeta', 'Abra la aplicación bancaria', 'Realice una transferencia única por la cantidad exacta'],
    crypto: ['Copie la dirección de la billetera', 'Realice una transferencia única por la cantidad exacta'],
    // Cantidad a transferir
    amount_to_transfer: 'Cantidad a transferir',
    // Esta cantidad no incluye la comisión del banco
    amount_to_transfer_hint: 'Esta cantidad no incluye la comisión del banco',
    // Número de tarjeta para transferir
    card_number: 'Número de tarjeta para transferir',
    // Cancelar solicitud
    cancel_request: 'Cancelar solicitud',
    // He pagado
    i_paid: 'He pagado',
  },

  faq: [
    {
      question: '¿Existe un monto mínimo que puedo depositar en mi cuenta al registrarme?',
      answer: 'La ventaja de la plataforma de trading de la compañía es que no tienes que depositar grandes cantidades en tu cuenta. Puedes comenzar a operar invirtiendo una pequeña cantidad de dinero. El depósito mínimo es de 10 dólares estadounidenses.'
    },
    {
      question: '¿Necesito depositar en la cuenta de la plataforma de trading y con qué frecuencia debo hacerlo?',
      answer: `Para operar con opciones binarias, necesitas abrir una cuenta individual. Para realizar operaciones reales, definitivamente necesitarás hacer un depósito por el monto de las opciones compradas.
          \n\n
          Puedes comenzar a operar sin dinero en efectivo, utilizando solo la cuenta de entrenamiento de la compañía (cuenta demo). Esta cuenta es gratuita y se crea para demostrar el funcionamiento de la plataforma de trading. Con la ayuda de esta cuenta, puedes practicar la adquisición de opciones binarias, comprender los principios básicos del trading, probar varios métodos y estrategias, o evaluar tu nivel de intuición.
          `
    },
    {
      question: '¿Cómo puedo hacer un depósito?',
      answer: `Es muy fácil de hacer. El procedimiento tomará un par de minutos.
          \n\n
          1) Abre la ventana de ejecución de operaciones y haz clic en el botón verde "Depósito" en la esquina superior derecha de la pestaña.
          \n\n
          También puedes hacer un depósito en la cuenta a través de tu Cuenta Personal haciendo clic en el botón "Depósito" en el perfil de la cuenta.
          \n\n
          2) Luego, elige un método para hacer el depósito en la cuenta (la compañía ofrece muchos métodos convenientes que están disponibles para el cliente y se muestran en su cuenta individual).
          \n\n
          3) A continuación, indica la moneda en la que se depositará la cuenta y, en consecuencia, la moneda de la cuenta en sí.
          \n\n
          4) Ingresa el monto del depósito.
          \n\n
          5) Completa el formulario ingresando los detalles de pago solicitados.
          \n\n
          6) Realiza el pago.`
    },
    {
      question: '¿Cuál es el monto mínimo de depósito?',
      answer: `La ventaja de la plataforma de trading de la compañía es que no tienes que depositar grandes cantidades en tu cuenta. Puedes comenzar a operar invirtiendo una pequeña cantidad de dinero. El depósito mínimo es de 10 dólares estadounidenses.`
    },
    {
      question: '¿Hay alguna tarifa por depositar o retirar fondos de la cuenta?',
      answer: `No. La compañía no cobra ninguna tarifa ni por el depósito ni por las operaciones de retiro.
          \n\n
          Sin embargo, debes tener en cuenta que los sistemas de pago pueden cobrar sus tarifas y utilizar la tasa de conversión de moneda interna.`
    }
  ]
}