// PL
// Fields: INSTRUMENT	DEAL	OPEN QUOTE	CLOSE QUOTE	AMOUNT	RESULT

export default {
    instrument: 'Instrument',
    deal: 'Transakcja',
    open: 'Cena otwarcia',
    close: 'Cena zamknięcia',
    sum: 'Kwota',
    result: 'Wynik',
    next: 'Następny',
    prev: 'Poprzedni',
    total_result: 'Wynik całkowity',
}