import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAllDeals, setDefaultPage } from '@/redux/dealsSlice';
import { dateFormater } from '@/utils/dateFormater';
import { selectActives } from '@/redux/activesSlice';
import { fetchDealsForLead, setCurrentPage } from '@/redux/dealsSlice';
import { useDispatch } from 'react-redux';
import useAuth from '@/hooks/useAuth.hook';
import { useLanguage } from '@/context/LanguageContext';
import ReactPaginate from 'react-paginate';


const Table = () => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const deals = useSelector(selectAllDeals);
    const actives = useSelector(selectActives);
    const { total, currentPage, dealsPerPage } = useSelector(state => state.deals);
    const { activeWallet } = useSelector(state => state.balance);
    const { getTranslation } = useLanguage();
    const t = getTranslation('deals');

    useEffect(() => {
        // Получаем данные при монтировании компонента и при изменении текущей страницы
        dispatch(fetchDealsForLead({ token, limit: dealsPerPage, offset: (currentPage - 1) * dealsPerPage, demo: activeWallet === 'demo' }));
    }, [dispatch, currentPage, dealsPerPage, activeWallet]);

    const handlePageChange = (newPage) => {
        dispatch(setCurrentPage(newPage.selected + 1));
    };


    const pageNumbers = Math.ceil(total / dealsPerPage);
    console.log(currentPage)
    return (
        <div className=" min-h-screen h-auto">
            {/* pagination */}

            <div className="mb-2 text-white">
                {/* pagination */}
                <ReactPaginate
                    initialPage={currentPage - 1}
                    previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                    nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={pageNumbers}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageChange}
                    containerClassName={'pagination'}
                    activeClassName={'rounded-full border'}
                    className="flex justify-center mt-4 items-center gap-1"
                    pageClassName="flex items-center justify-center w-8 h-8 bg-gray-800"
                />
            </div>
            <div className='overflow-x-auto'>
                <table className="min-w-full bg-gray-700 text-white">
                    <thead>
                        <tr>
                            {/* Заголовки таблицы */}
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.instrument}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.deal}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.open}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.close}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.sum}</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">{t.result}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Строки таблицы */}
                        {
                            deals
                                .map((item, index) => (
                                    <tr key={index} className={`${index % 2 === 0 ? 'bg-gray-800' : 'bg-gray-700'}`}>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="flex items-center">
                                                <div className="flex-shrink-0 h-10 w-10">
                                                    {/* Предположим, здесь может быть изображение */}
                                                    <div className="flex">
                                                        <img className="tab__flag flag-aud" alt="Flag" src={`/media/actives/${actives.find(active => active.id === item.active_id)?.type.split("/")[0].toLowerCase().trim()}.svg`} />
                                                        <svg className="tab__flag -ml-1 mt-1">
                                                            <use xlinkHref="/flags.svg#flag-usd" />
                                                        </svg>

                                                    </div>
                                                </div>
                                                <div className="ml-4">
                                                    <div className="text-sm font-medium text-white">{
                                                        actives.find(active => active.id === item.active_id)?.type
                                                    }</div>
                                                    <div className="text-sm text-gray-400">{dateFormater(item.open_time)} - {dateFormater(item.close_time)}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-white">{item?.type === 'up' ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 w-6 h-6">
                                                <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                                            </svg>
                                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-red-500 w-6 h-6">
                                                    <path fillRule="evenodd" d="M1.72 5.47a.75.75 0 0 1 1.06 0L9 11.69l3.756-3.756a.75.75 0 0 1 .985-.066 12.698 12.698 0 0 1 4.575 6.832l.308 1.149 2.277-3.943a.75.75 0 1 1 1.299.75l-3.182 5.51a.75.75 0 0 1-1.025.275l-5.511-3.181a.75.75 0 0 1 .75-1.3l3.943 2.277-.308-1.149a11.194 11.194 0 0 0-3.528-5.617l-3.809 3.81a.75.75 0 0 1-1.06 0L1.72 6.53a.75.75 0 0 1 0-1.061Z" clipRule="evenodd" />
                                                </svg>

                                            }</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-white">${item.open_price ?? '-'}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-white">${item.close_price ?? '-'}</div>
                                        </td>

                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className="text-sm text-green-500">{item.value ? new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                                parseFloat(item.value)
                                            ) : '-'}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap">
                                            <div className={`text-sm ${parseFloat(item.profit) > 0 ? 'text-green-500' : 'text-red-500'}`}>{item.profit ? new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                                                parseFloat(item.profit)
                                            ) : '-'}</div>
                                        </td>
                                    </tr>
                                ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Table;
