import React, { useEffect } from 'react';
import { useHttp } from '@/hooks/http.hook';
import Table from './components/Table';
import { useSelector } from 'react-redux';
import { fetchTransactions, getAllTxns } from '@/redux/transactionSlice';
import { useDispatch } from 'react-redux';
import useAuth from '@/hooks/useAuth.hook';
import Menu from '@/layout/Menu';

const Transactions = () => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { txns, total, status, currentPage, txnsPerPage, error } = useSelector(state => state.transactions);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchTransactions({ token }));
        }
    }, [status, token]);

    return (
        <div>
            <Menu />
            <Table
                total={total}
                currentPage={currentPage}
                txnsPerPage={txnsPerPage}
                txns={txns} />
        </div>
    );

}

export default Transactions;