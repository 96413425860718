//en

export default {
  //Deposit
  deposit: 'Deposit',
  // Deposit success alert
  depositSuccess: 'Your account has been successfully funded',
  // Deposit error alert
  depositError: 'Error depositing account',
  // The amount
  the_amount: 'Amount',
  //Payment method
  payment_method: 'Payment method',
  // Min amount
  min_amount: 'Min amount',
  // Max amount
  max_amount: 'Max amount',
  depositSuccessMsg: 'Your payment has been sent for processing.\nYour balance will be credited within 5 minutes.',
  // Минимальная сумма пополнения 10$
  min_amount_10: 'Min amount 10$',
  // Максимальная сумма пополнения 50000$
  max_amount_50000: 'Max amount 50000$',
  payment: {
    other: ['Copy the card number', 'Open the bank application', 'Transfer the exact amount in one payment'],
    crypto: ['Copy the wallet address', 'Transfer the exact amount in one payment'],
    // Amount to transfer
    amount_to_transfer: 'Amount to transfer',
    // This amount does not include bank commission
    amount_to_transfer_hint: 'This amount does not include bank commission',
    // Card number for transfer
    card_number: 'Card number for transfer',
    // Cancel request
    cancel_request: 'Cancel request',
    // I have paid
    i_paid: 'I have paid',
  },
  faq: [
    {
      question: 'Is there a minimum amount that I can deposit to my account at registration?',
      answer: 'The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is 10 US dollars.'
    },
    {
      question: 'Do I need to deposit the account of the trading platform and how often do I need to do this?',
      answer: `To work with binary options you need to open an individual account. To conclude real trades, you will certainly need to make a deposit in the amount of options purchased.
          \n\n
          You can start trading without cash, only using the company's training account (demo account). Such an account is free of charge and created to demonstrate the functioning of the trading platform. With the help of such an account, you can practice acquiring binary options, understand the basic principles of trading, test various methods and strategies, or evaluate the level of your intuition.
          `
    },
    {
      question: 'How can I deposit?',
      answer: `It is very easy to do. The procedure will take a couple of minutes.
          \n\n
          1) Open the trade execution window and click on the green "Deposit" button in the upper right corner of the tab.
          \n\n
          You can also deposit the account through your Personal Account by clicking the "Deposit" button in the account profile.
          \n\n
          2) After it is necessary to choose a method of depositing the account (the Company offers a lot of convenient methods that are available to the Client and are displayed in his individual account).
          \n\n
          3) Next, indicate the currency in which the account will be deposited, and accordingly the currency of the account itself.
          \n\n
          4) Enter the amount of the deposit.
          \n\n
          5) Fill out the form by entering the requested payment details.
          \n\n
          6) Make a payment.`
    },
    {
      question: 'What is the minimum deposit amount?',
      answer: `The advantage of the Company’s trading platform is that you don’t have to deposit large amounts to your account. You can start trading by investing a small amount of money. The minimum deposit is 10 US dollars.`
    },
    {
      question: 'Is there any fee for depositing or withdrawing funds from the account?',
      answer: `No. The company does not charge any fee for either the deposit or for the withdrawal operations.
          \n\n
          However, it is worth considering that payment systems can charge their fee and use the internal currency conversion rate.`
    }
  ]
}