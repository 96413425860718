import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAnalytics = createAsyncThunk(
    'analytics/fetchAnalytics',
    async ({ token, demo = false, start = new Date(), end = new Date() }, { rejectWithValue }) => {
        try {

            const response = await fetch(`/api/deals/lead_analytics/analytics?start=${start}&end=${end}&demo=${demo}`, {
                headers: {
                    'Authorization': `Bearer ${token}` // Используйте ваш токен аутентификации
                }
            });

            if (!response.ok) throw new Error('Network response was not ok.');

            const data = await response.json();

            return data; // Ожидаем, что ответ сервера будет иметь структуру, которую вы описали
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    data: [],
    status: 'idle',
    error: null,
};

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnalytics.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAnalytics.fulfilled, (state, action) => {
                state.data = action.payload;
                state.status = 'succeeded';
                state.error = null;
            })
            .addCase(fetchAnalytics.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

// export const { fetchDataStart, fetchDataSuccess, fetchDataFailure } = analyticsSlice.actions;

export default analyticsSlice.reducer;
