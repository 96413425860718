// es

export default {
    //Cuenta
    account: 'Cuenta',
    //En la cuenta
    on_account: 'En la cuenta',
    // Disponible para retiro
    available_for_withdrawal: 'Disponible para retiro',
    // Comisión
    commission: 'Comisión',
    // Tus solicitudes anteriores
    last_applications: 'Tus solicitudes anteriores',
    // Retiro
    withdrawal: 'Retiro',
    // No disponible para retiro
    not_for_withdrawal: 'Puedes retirar dinero de tu saldo a tu tarjeta bancaria o billetera electrónica que utilizaste para depositar. Puedes solicitar un retiro en cualquier momento. Las solicitudes de retiro se procesan en un plazo de 3 días hábiles.',
    // Hacer un depósito
    make_a_deposit: 'Hacer un depósito',
    // Historial de pagos
    payment_history: 'Historial de pagos',
    faq: [
            {
                question: '¿Cómo retirar dinero de la cuenta?',
                answer: 'El procedimiento para retirar capital es extremadamente sencillo y se realiza a través de su cuenta personal.\n\nEl método que eligió para depositar en su cuenta es el mismo método que utilizará para retirar fondos.\n\nPor ejemplo, si depositó en su cuenta a través del sistema de pago Visa, también retirará dinero a través del sistema de pago Visa.\n\nCuando se trata de retirar una suma considerable, la Compañía puede solicitar verificación (la verificación se solicita a discreción de la Compañía), por lo que es muy importante registrar la cuenta a su nombre para poder confirmar sus derechos sobre ella en cualquier momento.'
            },
            {
                question: '¿Cuánto tiempo lleva retirar fondos?',
                answer: 'Por lo general, el proceso de retiro de fondos lleva de uno a cinco días a partir de la fecha de recepción de la solicitud correspondiente del cliente y depende únicamente del volumen de solicitudes que se estén procesando simultáneamente. La Compañía siempre se esfuerza por realizar los pagos el mismo día en que se recibe la solicitud del cliente.'
            },
            {
                question: '¿Hay alguna comisión por depositar o retirar fondos de la cuenta?',
                answer: 'No. La Compañía no cobra comisiones por las operaciones de depósito o retiro de fondos.\n\nSin embargo, tenga en cuenta que los sistemas de pago pueden cobrar sus propias comisiones y utilizar una tasa de conversión de divisas interna.'
            },
            {
                question: '¿Se requiere algún documento para realizar un retiro de fondos?',
                answer: 'Por lo general, no se requieren documentos adicionales para retirar fondos. Sin embargo, la Compañía puede solicitar a su discreción que confirme sus datos personales mediante la presentación de ciertos documentos. Esto se hace generalmente para prevenir acciones relacionadas con el comercio ilegal, manipulaciones financieras y el uso de fondos obtenidos de manera ilegal.\n\nLa lista de dichos documentos es mínima y el proceso de proporcionarlos no le llevará mucho tiempo ni esfuerzo.'
            },
            {
                question: '¿Cuál es el monto mínimo para retirar fondos?',
                answer: 'El monto mínimo para retirar fondos es de 10 USD para la mayoría de los sistemas de pago.\n\nPara las criptomonedas, este monto comienza en 50 USD (y puede ser mayor para algunas monedas, como Bitcoin).'
            }
        ]
}
