export default {
    traid: 'Handel',
    deposit: 'Einzahlung',
    withdrawal: 'Auszahlung',
    transactions: 'Transaktionen',
    deals: 'Deals',
    profile: 'Profil',
    analytics: 'Analytik',
    logout: 'Abmelden',
    real: 'Echtes Konto',
    demo: 'Demokonto',
}
