import React, { useEffect, useRef, useState } from 'react';
import { createChart, LineStyle, CrosshairMode } from 'lightweight-charts';
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '@/hooks/useAuth.hook';
import { fetchDealsForLead, getOpenDeals, setDefaultPage } from '@/redux/dealsSlice';
import { formatDuration } from '@/utils/formatDuration';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'; // Импорт плагина
dayjs.extend(utc); // Использование плагина

const timeframes = {
    '1min': '1m',
    '5min': '5m',
    '15min': '15m',
    '30min': '30m',
    '45min': '45m',
    '1h': '1h'
};
const timeframesToSeconds = {
    '1min': 60,
    '5min': 300,
    '15min': 900,
    '30min': 1800,
    '45min': 2700,
    '1h': 3600
};

const ChartComponent = () => {
    const { token } = useAuth();
    const chartContainerRef = useRef(null);
    const candleSeriesRef = useRef(null); // Правильно определяем ссылку для использования с серией свечей
    const [lastTime, setLastTime] = useState(null);
    const lastTimeRef = useRef(null);
    const { deals } = useSelector(state => state.deals);
    const { activeWallet } = useSelector(state => state.balance);
    const dispatch = useDispatch();
    const resizeObserver = useRef();
    const chart = useRef();
    const lastCandleRef = useRef({ time: null, open: null, high: null, low: null, close: null });
    // Состояние для хранения ссылок на линии цен, связанных с открытыми сделками
    const priceLinesRef = useRef({}); // Хранение ссылок на линии сделок
    const [isChartReady, setChartReady] = useState(false);
    const timerIdsRef = useRef({}); // Используйте это для хранения идентификаторов таймеров
    const socket = useSelector(state => state.websocket.socket);
    const selectedOption = useSelector(state => state.actives.selected);
    const messageHandlerRef = useRef();
    const animationFrameRef = useRef();
    const [timeframe, setTimeframe] = useState('1min');

    const handleWebSocketMessage = (data, timeframe) => {
        const { price, timestamp } = data; // Допустим, данные приходят в таком формате

        const now = new Date(); // Получаем текущее время
        now.setSeconds(0, 0); // Округляем до минуты, отбрасывая секунды и миллисекунды
        const currentTime = Math.floor(now.getTime() / 1000); // Конвертируем в Unix timestamp в секундах

        const currentFrameStart = currentTime - (currentTime % timeframe); // Начало текущего 5-минутного интервала

        if (!lastTimeRef.current || currentFrameStart > lastTimeRef.current) {
            // Создаем новую свечу
            const newCandle = {
                time: currentTime,
                open: lastCandleRef?.current?.close || price,
                high: data.bid || price,
                low: data.ask || price,
                close: price,
            };
            candleSeriesRef.current.update(newCandle);
            lastCandleRef.current = newCandle;
            lastTimeRef.current = currentTime; // Обновляем время последней свечи
        } else {
            // Обновляем последнюю свечу
            const updatedCandle = { ...lastCandleRef.current, close: price };
            smoothUpdateCandle(updatedCandle);
            lastCandleRef.current = updatedCandle;
        }

        // if (currentTime - currentFrameStart < timeframe) {
        //     // Текущее время все еще в пределах текущего таймфрейма
        //     // Обновляем последнюю свечу
        //     const updatedCandle = {
        //         ...lastCandleRef.current,
        //         close: price
        //     };
        //     smoothUpdateCandle(updatedCandle);
        //     lastCandleRef.current = updatedCandle;
        // } else {
        //     // Текущее время выходит за пределы текущего таймфрейма
        //     // Создаем новую свечу
        //     const newCandle = {
        //         time: timestamp,
        //         open: lastCandleRef?.current?.close || price,
        //         high: data.bid || price,
        //         low: data.ask || price,
        //         close: price,
        //     };
        //     candleSeriesRef.current.update(newCandle);
        //     lastCandleRef.current = newCandle;
        // }


    };

    useEffect(() => {
        // Функция для создания нового обработчика сообщений
        const createMessageHandler = () => {
            return (event) => {
                const data = JSON.parse(event.data);
                if (data.event && data.event === "price" && data.symbol === selectedOption.type.replace(/\s/g, "")) {
                    handleWebSocketMessage(data, timeframesToSeconds[timeframe]); // Обрабатываем текущее сообщение
                }
            };
        };

        // Пересоздаем обработчик сообщений при каждом изменении selectedOption или socket
        messageHandlerRef.current = createMessageHandler();

        if (socket) {
            socket.addEventListener('message', messageHandlerRef.current);
            return () => socket.removeEventListener('message', messageHandlerRef.current);
        }
    }, [socket, selectedOption, timeframe]); // Добавляем selectedOption в массив зависимостей

    const getPairValue = async () => {
        try {
            const getPairValue = await fetch(`/api/actives/${selectedOption.type.replace(/\//g, "-").replace(/[^A-Za-z0-9-]/g, "")}`,
                {
                    headers: {
                        'Authorization': `Bearer ${token}` // Используйте ваш токен аутентификации
                    }
                })
            const pairValue = await getPairValue.json();

            return pairValue
        } catch (e) {
            console.log(e);
            return null;
        }
    };

    const fetchDataForTimeframe = async () => {
        const url = `https://api.twelvedata.com/time_series?apikey=${process.env.REACT_APP_TWELWEDATA_TOKEN}&timezone=utc&interval=${timeframe}&symbol=${selectedOption.type.replace(/\s/g, "")}&outputsize=1000&format=JSON&end_date=${dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss')}`

        const response = await fetch(url);
        const data = await response.json();
        const seriesData = [];

        const pairValue = await getPairValue();

        const secondPropertyName = Object.keys(data)[1]; // Получаем имя второго свойства
        const secondProperty = data[secondPropertyName]; // Получаем значение второго свойства

        Object.entries(secondProperty).forEach(([key, value]) => {
            const date = new Date(value['datetime']);
            const time = dayjs(value['datetime'] + 'Z').utc().unix() //dayjs(value['datetime']).unix()

            seriesData.push({
                time: time,
                open: parseFloat(value['open']),
                high: parseFloat(value['high']),
                low: parseFloat(value['low']),
                close: parseFloat(value['close']),
            });
        });

        seriesData.sort((a, b) => a.time - b.time);
        setLastTime(seriesData[seriesData.length - 1]?.time);
        // change close price to pairValue.price in last candle
        if (pairValue && pairValue.price)
            seriesData[seriesData.length - 1].close = pairValue.price;

        lastCandleRef.current = seriesData[seriesData.length - 1];

        if (chartContainerRef.current) {
            // clear the container
            if (chart.current) {
                chart.current.remove();
                chart.current = null;
                candleSeriesRef.current = null;

                Object.keys(timerIdsRef.current).forEach((dealId) => {
                    clearInterval(timerIdsRef.current[dealId]);
                });
            }
            chartContainerRef.current.innerHTML = '';

            chart.current = createChart(chartContainerRef.current, {
                width: chartContainerRef.current.clientWidth,
                // if widht < md height = 300 else 600
                height: window.innerWidth < 768 ? 300 : 600,
                layout: {
                    background: { color: '#253248' },
                    textColor: 'rgba(255, 255, 255, 0.9)',
                },
                grid: {
                    vertLines: { color: '#334158' },
                    horzLines: { color: '#334158' },
                },
                crosshair: {
                    mode: CrosshairMode.Normal
                },
                priceScale: {
                    border: { color: "#485c7b" }
                },
                timeScale: {
                    border: { color: "#485c7b" }
                },
                localization: {
                    dateFormat: 'yyyy-MM-dd',
                    priceFormatter: price => {
                        if (selectedOption.type.split("/")[0].trim() === 'EUR')
                            return price.toFixed(5);

                        return price.toFixed(3);
                    }
                },
                timeScale: {
                    timeVisible: true, // Показывать время на временной шкале
                    secondsVisible: false, // Не показывать секунды, если не нужно    
                }
            });

            candleSeriesRef.current = chart.current.addCandlestickSeries({
                upColor: "#4bffb5",
                downColor: "#ff4976",
                borderDownColor: "#ff4976",
                borderUpColor: "#4bffb5",
                wickDownColor: "#838ca1",
                wickUpColor: "#838ca1"
            });
            candleSeriesRef.current.setData(seriesData);
            // setVisibleRange in last hour
            // if last time not older then 3 hours
            if (seriesData[seriesData.length - 1]?.time && timeframe === '1min' && seriesData[seriesData.length - 1]?.time > dayjs().subtract(3, 'hour').unix())
                chart.current.timeScale().setVisibleRange({ from: dayjs().subtract(3, 'hour').unix(), to: dayjs().unix() });
            setChartReady(true); // Устанавливаем флаг готовности графика в true
            deals.forEach(deal => {
                if (deal.status === 'open') {
                    addPriceLine(deal);
                }
            });
        }
    };

    useEffect(() => {
        fetchDataForTimeframe();
        return () => {
        };
    }, [selectedOption, timeframe]);

    useEffect(() => {
        lastCandleRef.current = { time: new Date().getTime() / 1000, open: selectedOption.buy, high: selectedOption.buy, low: selectedOption.buy, close: selectedOption.buy };
    }, [selectedOption]);

    const smoothUpdateCandle = (updatedCandle) => {
        cancelAnimationFrame(animationFrameRef.current); // Отменяем предыдущую анимацию
    
        const steps = 10;
        const startValue = lastCandleRef.current.close;
        const endValue = updatedCandle.close;
        const difference = endValue - startValue;
        const stepValue = difference / steps; // Расчет шага изменения
    
        let values = Array.from({ length: steps }, (_, i) => startValue + stepValue * (i + 1));
    
        const animate = (index) => {
            if (index < values.length) {
                const partialClose = values[index];
                const candleToUpdate = { ...lastCandleRef.current, close: partialClose };
                candleSeriesRef.current.update(candleToUpdate);
    
                animationFrameRef.current = requestAnimationFrame(() => animate(index + 1));
            } else {
                lastCandleRef.current = updatedCandle; // Обновляем ссылку на последнюю свечу
                candleSeriesRef.current.update(updatedCandle); // Финальное обновление свечи на графике
            }
        };
    
        animate(0); // Начинаем анимацию с первого шага
    };



    const addPriceLine = (deal) => {
        if (!candleSeriesRef.current) return;
        // Время начала сделки, предполагаем, что deal.open_time - это timestamp начала
        const dateObject = new Date(deal.open_time + "Z");
        const isoDateTime = dateObject.toISOString();

        const openTime = new Date(isoDateTime); // Время открытия сделки
        const endTime = new Date(openTime.getTime() + deal.duration * 1000); // Время закрытия сделки


        const priceLine = candleSeriesRef.current.createPriceLine({
            price: deal.open_price,
            color: '#4bffb5',
            lineWidth: 2,
            lineStyle: LineStyle.Dotted,
            axisLabelVisible: true,
            title: `#${deal.id}`,
        });

        priceLinesRef.current[deal.id] = priceLine;

        const updatePriceLineTitle = () => {

            // const now = new Date();
            const currentTime = new Date(); // Текущее время
            const timeDifference = endTime - currentTime; // Разница во времени
            // const secondsRemaining = Math.round(remaining / 1000);
            if (timeDifference > 0) {
                const secondsLeft = Math.floor(timeDifference / 1000);
                priceLine.applyOptions({
                    title: `$${deal.value} - ${formatDuration(secondsLeft, 'mm:ss')}`,
                });
            } else {

                clearInterval(timerId); // Останавливаем таймер после окончания сделки
            }
        };

        const timerId = setInterval(updatePriceLineTitle, 1000);
        timerIdsRef.current[deal.id] = timerId; // Сохраняем ID таймера
        updatePriceLineTitle(); // Начальное обновление

    };



    useEffect(() => {
        // Удаление всех существующих линий сделок
        Object.keys(priceLinesRef.current).forEach(dealId => {
            if (candleSeriesRef.current && priceLinesRef.current[dealId]) {
                // Очистка интервала таймера
                const timerId = timerIdsRef.current[dealId];
                if (timerId) {
                    clearInterval(timerId);
                    delete timerIdsRef.current[dealId]; // Удаляем ID таймера из хранилища
                }
                candleSeriesRef.current.removePriceLine(priceLinesRef.current[dealId]);
            }
        });

        // Очистка ссылок на линии сделок
        priceLinesRef.current = {};

        // Добавление линий для оставшихся открытых сделок
        deals.forEach(deal => {
            if (deal.status === 'open') {
                addPriceLine(deal);
            }
        });

    }, [deals, isChartReady, selectedOption]); // Зависимость от deals гарантирует перерисовку линий при их изменении

    useEffect(() => {
        dispatch(setDefaultPage())
        dispatch(fetchDealsForLead({ token, offset: 0, limit: 10, demo: activeWallet === 'demo' }));
    }, []);


    // Resize chart on container resizes.
    useEffect(() => {
        resizeObserver.current = new ResizeObserver(entries => {
            const { width } = entries[0].contentRect;
            let h = window.innerWidth < 768 ? 300 : 600;
            chart?.current?.applyOptions({ width, height: h });
            setTimeout(() => {
                chart?.current?.timeScale().fitContent();
            }, 0);
        });

        resizeObserver.current.observe(chartContainerRef.current);

        return () => resizeObserver.current.disconnect();
    }, []);

    const handleTimeframeChange = (time) => {
        // Загрузите данные для выбранного таймфрейма
        setTimeframe(time);
        // Обновите данные на графике
    };

    console.log("selectedOption: ", selectedOption)

    return <div className='relative'>
        <div className='absolute z-[99] flex top-2 left-2 gap-2'>
            {Object.entries(timeframes).map(([value, label]) => (
                <div className={`flex flex-1 font-medium gap-2 hover:bg-opacity-70 items-center justify-center rounded-md border-gray-400 shadow-lg  text-xs min-w-6 h-6 p-1 cursor-pointer ${timeframe === value ? 'bg-gray-200 text-gray-700' : 'text-white bg-gray-700'} `} onClick={() => handleTimeframeChange(value)} key={value}>{label}</div>
            ))}
        </div>
        <div ref={chartContainerRef} className='h-[300px] md:h-[600px]' style={{ width: '100%' }} ></div>
    </div>
};

export default React.memo(ChartComponent);
