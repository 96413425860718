import React, { useEffect, useState } from "react";
import InvestmentInput from "./InvestmentInput";
import TimeSelector from "./TimeSelector";
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedActives } from '@/redux/activesSlice';
import { formatDuration } from "@/utils/formatDuration";
import _ from 'lodash';
import { addDeal, fetchDealsForLead, setDefaultPage, updateDeal } from "@/redux/dealsSlice";
import { DealTimer } from "../DealCard";
import { addAlert } from "@/redux/globalAlertSlice";
import { editBalanceField, fetchUserBalance } from "@/redux/userBalanceSlice";
import useAuth from "@/hooks/useAuth.hook";
import { useLanguage } from '@/context/LanguageContext';
import DealCard from "./DealCard";
import ActivesSelector from "./ActivesSelector";
import Modal from 'react-modal';


function TradingWidget() {
  const dispatch = useDispatch();
  const [pendingTime, setPendingTime] = useState('');
  const [isPending, setIsPending] = useState(false);
  const investment = useSelector((state) => state.investment.value);
  const time = useSelector((state) => state.time.value);
  const socket = useSelector(state => state.websocket.socket);
  const selectedOption = useSelector(state => state.actives.selected);
  const actives = useSelector(state => state.actives.items);
  const { deals, status, error } = useSelector(state => state.deals);
  const [tradeDiley, setTradeDiley] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('deals'); // ['deals', 'pending'
  const { token } = useAuth();
  const { activeWallet } = useSelector(state => state.balance);
  const { getTranslation } = useLanguage();
  const t = getTranslation('sidebar');

  const handleDateTimeChange = (e) => {
    setPendingTime(e.target.value);
  };

  const sendTrade = (type) => {
    if (tradeDiley > 0) return;

    setTradeDiley(3500);

    const date = isPending ?
      new Date(pendingTime).toISOString().replace(/\.\d{3}Z$/, '')
      : new Date().toISOString().replace(/\.\d{3}Z$/, '')

    //check if isPending date is in the future
    if (isPending && new Date(pendingTime) < new Date()) {
      dispatch(addAlert({ type: 'error', alert: 'Date and time must be in the future' }))
      return;
    }

    const status = isPending ? "pending" : "open";
    const dataToSend = {
      type: type,
      status: status,
      open_time: date,
      duration: time,
      value: investment,
      active_id: selectedOption.id,
      demo: activeWallet === 'demo'
    };

    if (socket && socket.readyState === WebSocket.OPEN) {
      socket.send(JSON.stringify(dataToSend));
    }
  }

  const toggleOpen = () => setIsOpen(!isOpen);

  const selectOption = (value) => {
    dispatch(setSelectedActives(value));
    // Add Selected Options to local storage
    localStorage.setItem('selectedOption', JSON.stringify(value));
    setIsOpen(false);
  };

  const handleActiveTabChange = (activeTab) => {
    setActiveTab(activeTab);
    dispatch(setDefaultPage())
    if (activeTab === 'pending') {
      dispatch(fetchDealsForLead({ token, offset: 0, limit: 10, status: 'pending', demo: activeWallet === 'demo' }));
    } else {
      dispatch(fetchDealsForLead({ token, offset: 0, limit: 10, demo: activeWallet === 'demo' }));
    }
  }

  useEffect(() => {
    if (socket) {
      socket.onmessage = (event) => {
        const data = JSON.parse(event.data);
        // Обрабатываем полученные данные, например, добавляем сделку
        if (data.action === 'action_deal_create') {
          // dispatch(editBalanceField({ field: 'available_funds', value: Number(-data.message.value) }))
          // dispatch(editBalanceField({ field: 'balance', value: Number(-data.message.value) }))
          dispatch(addDeal(data.message)); // предположим, что у вас есть такой action creator\
          dispatch(addAlert({ type: 'success', alert: `Trade opened with price: ${data.message.open_price}` }))
          dispatch(fetchUserBalance(token))

        }

        if (data.action === 'action_deal_update') {
          dispatch(updateDeal(data.message));
          if (data.message.status === 'close' && data.message.profit > 0) {
            // dispatch(editBalanceField({ field: 'available_funds', value: Number(+data.message.profit) }))
            // dispatch(editBalanceField({ field: 'balance', value: Number(+data.message.profit) }))

          }
          dispatch(addAlert({ type: 'success', alert: `Trade closed` }))
          dispatch(fetchUserBalance(token))
          dispatch(fetchDealsForLead({ token, offset: 0, limit: 10, demo: activeWallet === 'demo' }));

        }
      };
    }
  }, [socket]);

  useEffect(() => {
    dispatch(fetchDealsForLead({ token, demo: activeWallet === 'demo' }));
  }, [activeWallet]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTradeDiley(tradeDiley => tradeDiley - 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [tradeDiley]);

  // Здесь могут быть состояния и методы для управления данными компонента
  return (
    <div className="bg-gray-800 text-white p-4 rounded-lg text-xs shadow-md w-full md:max-w-sm h-auto min-h-screen">
      <div className="relative inline-block text-left w-full">
        {selectedOption && <button onClick={toggleOpen} className="inline-flex gap-2 justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-black text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue" id="options-menu" aria-haspopup="true" aria-expanded={isOpen}>
          {/* Изображение флага (предполагается, что у вас есть соответствующие изображения) */}
          <div className="flex">
            {/* <svg className="tab__flag flag-aud">
              <use xlinkHref={`/flags.svg#flag-${selectedOption.type
                .slice(0, 3)
                .toLowerCase()
                }`} />
            </svg>
            <svg className="tab__flag -ml-1 mt-1">
              <use xlinkHref="/flags.svg#flag-usd" />
            </svg> */}

            {selectedOption.type.split("/").length === 2 ? (
              <>
                <img className="tab__flag flag-aud" src={`/media/actives/${selectedOption.type.split("/")[0].toLowerCase().trim()}.svg`} alt="Flag" />
                <img className="tab__flag -ml-2 mt-1" style={{ marginLeft: -8, marginTop: 8 }} src={`/media/actives/${selectedOption.type.split("/")[1].toLowerCase().trim()}.svg`} alt="Flag"/>
              </>
            ) : (
              <>
                <img className="tab__flag" src={`/media/actives/${selectedOption.type.toLowerCase().trim()}.svg`} alt="Flag" />
              </>
            )}
          </div>

          <span>{selectedOption.type}</span>
          <span>{selectedOption.buy}$</span>
          {/* Иконка стрелки */}
          <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="white" aria-hidden="true">
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleOpen}
          contentLabel="Actives Selector"
          className="modal"
          overlayClassName="overlay"
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 999,
              backgroundColor: 'rgb(19 19 19 / 75%)'
            },
            content: {
              position: 'absolute',
              top: '40px',
              left: '40px',
              right: '40px',
              bottom: '40px',
              border: '1px solid rgb(31, 41, 55)',
              background: '#fff',
              color: '#fff',
              overflow: 'auto',
              background: '#1f2937',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '12px'
            }
          }}
        >
          {/* close btn */}
          <button onClick={toggleOpen} className="absolute top-2 right-2 text-white hover:text-gray-300 focus:outline-none">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
              <path fillRule="evenodd" d="M6.293 6.707a1 1 0 0 1 1.414 0L12 10.586l4.293-4.293a1 1 0 1 1 1.414 1.414L13.414 12l4.293 4.293a1 1 0 0 1-1.414 1.414L12 13.414l-4.293 4.293a1 1 0 0 1-1.414-1.414L10.586 12 6.293 7.707a1 1 0 0 1 0-1.414Z" clipRule="evenodd" />
            </svg>
          </button>
          <ActivesSelector selectOption={selectOption} />
        </Modal>
        {/* Выпадающий список */}
        {/* <div className={`${isOpen ? 'block' : 'hidden'} origin-top-right absolute right-0 mt-2 w-full z-[999] md:w-56 rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5`}>
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {actives.map((option, index) => (
              <a key={index} href="#" onClick={() => selectOption(option)} className={`flex space-x-4 px-4 py-2 text-sm text-white hover:bg-gray-700 ${selectedOption === option ? 'bg-gray-700' : ''}`} role="menuitem">
                <div className="flex">
                  <img className="tab__flag flag-aud" src={`/media/actives/${option.type.split("/")[0].toUpperCase().trim()}.svg`}   />
                  <img className="tab__flag -ml-1 mt-1" src={`/media/actives/${option.type.split("/")[1].toUpperCase().trim()}.svg`}   />
                </div>
                <span className="text-xs">{option.type}</span>
                <span className="text-xs">${option.buy}</span>
              </a>
            ))}
          </div>
        </div> */}
      </div>

      <div className="bg-gray-800 pt-4 rounded-lg mb-4">
        <div className="  mb-2">
          <label className="flex justify-between items-center switch">
            <input value={isPending} type="checkbox" onClick={() => setIsPending(!isPending)} />
            <span className="slider round"></span>
            <span>{t.pending_trade}</span>
          </label>

        </div>
        {isPending && (
          <label htmlFor="datetime-local">
            <p className="text-gray-200 font-semibold text-xs text-center">{t.date_and_time}</p>
            <input
              className="flex items-center justify-between bg-gray-700 p-2 rounded-md border border-gray-600 text-white bg-gray-700 text-xs w-full text-center focus:outline-none"
              type="datetime-local"
              id="datetime-local"
              name="datetime-local"
              value={pendingTime}
              onChange={handleDateTimeChange}
              min={new Date().toISOString().slice(0, 16)} // Установка минимальной даты и времени на текущие
            />
          </label>
        )}
      </div>

      <div className="flex gap-4 md:gap-0 md:flex-col">
        <div className=" mb-2 w-1/2 md:w-full">
          <p className="text-gray-200 font-semibold text-xs text-center">{t.time}</p>
          <TimeSelector />
        </div>

        <div className=" mb-2 w-1/2 md:w-full">
          <p className="text-gray-200 font-semibold text-xs text-center">{t.investment}</p>
          <InvestmentInput />
        </div>
      </div>
      <div className="flex items-center mb-4 gap-4">
        <button
          onClick={() => sendTrade("up")}
          className="w-1/2 md:w-full flex justify-center items-center gap-1 flex-1 bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-opacity-70 font-bold uppercase">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm.53 5.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 1 0 1.06 1.06l1.72-1.72v5.69a.75.75 0 0 0 1.5 0v-5.69l1.72 1.72a.75.75 0 1 0 1.06-1.06l-3-3Z" clipRule="evenodd" />
          </svg>
          {t.up}
          {tradeDiley > 0 && <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
          </div>}
        </button>
        <button
          onClick={() => sendTrade("down")}
          className="w-1/2 md:w-full flex justify-center items-center gap-1 flex-1 bg-red-500 text-white px-4 py-2 rounded-lg shadow-lg hover:bg-opacity-70 font-bold uppercase">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clipRule="evenodd" />
          </svg>
          {t.down}
          {tradeDiley > 0 && <div
            className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span
              className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
            >Loading...</span>
          </div>}
        </button>
      </div>

      {/* <div className="text-center">
        <span>{t.your_payout}: <b>{(investment * 1.88).toFixed(2)}</b> $</span>
      </div> */}

      {/* Deals/Pending tabs btn */}
      <div className="flex justify-between items-center my-4">
        <button className={`flex justify-center items-center gap-2 flex-1  px-6 py-2 rounded-lg shadow-lg hover:bg-opacity-70 font-bold 
        ${activeTab === 'deals' ? 'bg-gray-200 text-gray-700' : 'bg-gray-700 text-white'}`}
          onClick={() => handleActiveTabChange('deals')}
        >
          {t.deals}
        </button>
        <button className={`flex justify-center items-center gap-2 flex-1 px-6 py-2 rounded-lg shadow-lg hover:bg-opacity-70 font-bold 
        ${activeTab === 'pending' ? 'bg-gray-200 text-gray-700' : 'bg-gray-700 text-white'}`}
          onClick={() => handleActiveTabChange('pending')}
        >
          {t.pending}
        </button>
      </div>

      {/* last deals */}
      <div className="space-y-2 md:overflow-auto md:max-h-[40vh]">
        {
          _.sortBy(deals, (a, b) => a.id).reverse().slice(0, 5)
            .map((deal, index) => (
              <DealCard key={index} deal={deal} />
            ))}
      </div>
    </div>
  );
}

export default TradingWidget;