// timeSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const timeSlice = createSlice({
  name: 'time',
  initialState: {
    value: 60, // начальное значение в секундах (1 минута)
  },
  reducers: {
    increment: (state) => {
      state.value = Math.min(14400, state.value + 1); // ограничим максимальное значение 4 часами
    },
    decrement: (state) => {
      state.value = Math.max(60, state.value - 1); // ограничим минимальное значение 5 секундами
    },
    setTime: (state, action) => {
      state.value = Math.min(14400, Math.max(60, action.payload)); // установим время с учетом ограничений
    },
  },
});

// Экспортируем сгенерированные действия
export const { increment, decrement, setTime } = timeSlice.actions;

export default timeSlice.reducer;
