//en
//Fields: ДАТА	СУММА	ТИП	СТАТУС	КАРТА	ВАЛЮТА	МЕТОД

export default {
    date: 'Date',
    amount: 'Amount',
    type: 'Type',
    status: 'Status',
    card: 'Card',
    currency: 'Currency',
    method: 'Method',
    // operation status
    operationStatus: (status) => {
        switch (status) {
            case 'success':
                return 'Success';
            case 'pending':
                return 'Pending';
            case 'failed':
                return 'Failed';
            case 'error':
                return 'Error';
            default:
                return '-';
        }
    },
    operationType: (type) => {
        switch (type) {
            case 'deposit':
                return 'Deposit';
            case 'withdrawal':
                return 'Withdrawal';
                //Bonus
            case 'bonus':
                return 'Bonus';
            default:
                return '-';
        }
    }
}