import React, { useEffect } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses, MenuItemStyles } from 'react-pro-sidebar';
import { Link, useNavigate } from 'react-router-dom';
import { useLanguage } from '@/context/LanguageContext';
import useAuth from '@/hooks/useAuth.hook';
import { menuItems } from '@/layout/pages';

const themes = {
    dark: {
        sidebar: {
            backgroundColor: 'rgb(31 41 55 / 1)',
            color: '#8ba1b7',
        },
        menu: {
            menuContent: '#082440',
            icon: '#fff',
            hover: {
                backgroundColor: '#026fd3',
                color: '#b6c8d9',
            },
            disabled: {
                color: '#3e5e7e',
            },
        },
    },
};


// hex to rgba converter
const hexToRgba = (hex, alpha) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const SlickSidebar = () => {
    const [collapsed, setCollapsed] = React.useState(false);
    const [toggled, setToggled] = React.useState(false);
    const [broken, setBroken] = React.useState(false);
    const [hasImage, setHasImage] = React.useState(false);
    const { language, getTranslation } = useLanguage();
    const t = getTranslation('Menu')
    const auth = useAuth();
    const { user } = useAuth();


    const menuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 400,
        },
        icon: {
            color: themes['dark'].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes['dark'].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes['dark'].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            marginBottom: '8px',
            [`&.${menuClasses.disabled}`]: {
                color: themes['dark'].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes['dark'].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes['dark'].menu.hover.color,
            },
            [`&.active`]: {
                backgroundColor: '#026fd3',
                color: '#b6c8d9',
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };

    useEffect(() => {
        if (user?.permissions?.allowWithdrawal && !menuItems.find(item => item.name === 'withdrawal'))
            menuItems.push({
                name: 'withdrawal', href: '/withdrawal', icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            });
    }, [user]);

    return (
        <Sidebar
            className='bg-gray-900'
            collapsed={true}
            toggled={toggled}
            onBackdropClick={() => setToggled(false)}
            onBreakPoint={setBroken}
            breakPoint="md"
            backgroundColor={hexToRgba(themes['dark'].sidebar.backgroundColor, hasImage ? 0.9 : 1)}
            rootStyles={{
                color: themes['dark'].sidebar.color,
                border: 0,
                backgroundColor: 'rgb(31 41 55 / 1) !important',
                width: '60px'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', position: 'fixed', top: '3px', width: '60px', backgroundColor: 'rgb(31 41 55 / 1) !important' }}>
                <div style={{ flex: 1, marginBottom: '32px' }}>
                    <Menu menuItemStyles={menuItemStyles} style={{ backgroundColor: 'rgb(31 41 55 / 1) !important' }}>
                        {/*     <MenuItem component={<Link to="/documentation" />}> Documentation</MenuItem> */}
                        {menuItems.map((item, index) => (
                            <MenuItem
                                className='rounded-lg'
                                key={index}
                                icon={item.icon}
                                component={<Link className={
                                    (window.location.pathname === '/' + language.toLowerCase() + item.href) ? 'active rounded-lg' : 'rounded-lg'
                                } to={'/' + language.toLowerCase() + item.href} />}

                            >
                                {t[item.name]}
                            </MenuItem>
                        ))}


                    </Menu>
                </div>

                <div
                    className="flex flex-col items-center cursor-pointer px-4 py-2 text-[8px] text-red-500 hover:bg-gray-700"
                    role="menuitem"
                    onClick={auth.logOut}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
                    </svg>
                    {t.logout}
                </div>
            </div>
        </Sidebar >

    );
};

export default SlickSidebar;