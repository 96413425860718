

export default {
  //Deposit
  deposit: 'Депозит',
  // Deposit success alert
  depositSuccess: 'Ваш счет успешно пополнен',
  // Deposit error alert
  depositError: 'Ошибка при пополнении счета',
  // The amount
  the_amount: 'Сумма',
  //Payment method
  payment_method: 'Способ оплаты',
  // Min amount
  min_amount: 'Минимальная сумма',
  // Max amount
  max_amount: 'Максимальная сумма',
  //depositSuccess
  depositSuccessMsg: 'Ваш платеж отправлен в обработку.\nБаланс будет пополнен в течение 5 минут.',
  // Минимальная сумма пополнения 10$
  min_amount_10: 'Минимальная сумма пополнения 10$',
  // Максимальная сумма пополнения 50000$
  max_amount_50000: 'Максимальная сумма пополнения 50000$',
  payment: {
    other: ['Скопируйте номер карты', 'Откройте приложение банка', 'Одним платежом переведите точную сумму'],
    crypto: ['Скопируйте номер кошелька', 'Одним платежом переведите точную сумму'],
    // Сумма для перевода
    amount_to_transfer: 'Сумма для перевода',
    //В эту сумму не входит комисия банка
    amount_to_transfer_hint: 'В эту сумму не входит комисия банка',
    //Номер карты для перевода
    card_number: 'Номер карты для перевода',
    // Отменить запрос
    cancel_request: 'Отменить запрос',
    //Я оплатил
    i_paid: 'Я оплатил',
  },
  faq: [
    {
      question: 'Есть ли минимальная сумма, которую я могу внести на свой счет при регистрации?',
      answer: 'Преимущество торговой платформы Компании заключается в том, что вам не нужно вносить большие суммы на свой счет. Вы можете начать торговлю, инвестируя небольшую сумму денег. Минимальный депозит составляет 10 долларов США.'
    },
    {
      question: 'Нужно ли мне вносить депозит на счет торговой платформы и как часто мне это делать?',
      answer: `Для работы с бинарными опционами вам необходимо открыть индивидуальный счет. Для заключения реальных сделок вам, конечно же, потребуется внести депозит в размере приобретенных опционов.
              \n\n
              Вы можете начать торговлю без наличных денег, используя только учебный счет компании (демо-счет). Такой счет является бесплатным и создан для демонстрации работы торговой платформы. С его помощью вы можете практиковаться в приобретении бинарных опционов, понять основные принципы торговли, протестировать различные методы и стратегии или оценить уровень вашей интуиции.
              `
    },
    {
      question: 'Как мне внести депозит?',
      answer: `Это очень просто сделать. Процедура займет несколько минут.
              \n\n
              1) Откройте окно исполнения сделок и нажмите на зеленую кнопку "Депозит" в верхнем правом углу вкладки.
              \n\n
              Вы также можете внести депозит на счет через личный кабинет, нажав кнопку "Депозит" в профиле аккаунта.
              \n\n
              2) После этого необходимо выбрать способ внесения депозита на счет (Компания предлагает множество удобных методов, доступных Клиенту и отображаемых в его индивидуальном аккаунте).
              \n\n
              3) Затем укажите валюту, на которую будет внесен депозит, и соответственно валюту самого счета.
              \n\n
              4) Введите сумму депозита.
              \n\n
              5) Заполните форму, указав запрашиваемые реквизиты платежа.
              \n\n
              6) Совершите платеж.`
    },
    {
      question: 'Какая минимальная сумма депозита?',
      answer: `Преимущество торговой платформы Компании заключается в том, что вам не нужно вносить большие суммы на свой счет. Вы можете начать торговлю, инвестируя небольшую сумму денег. Минимальный депозит составляет 10 долларов США.`
    },
    {
      question: 'Есть ли какая-либо комиссия за внесение или вывод средств со счета?',
      answer: `Нет. Компания не взимает никакой комиссии ни за внесение, ни за операции по выводу средств.
              \n\n
              Однако стоит учесть, что платежные системы могут взимать свою комиссию и использовать внутренний курс конвертации валюты.`
    }
  ]
}