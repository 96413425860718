import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { jwtDecode } from "jwt-decode"; // Убедитесь, что вы установили пакет jwt-decode

// Асинхронный action для загрузки активов
export const fetchActives = createAsyncThunk(
    'actives/fetchActives',
    async (token, { rejectWithValue }) => {
        try {
            // Декодирование токена для получения времени истечения
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Получаем текущее время в секундах

            // Проверка, истек ли срок действия токена
            if (decodedToken.exp < currentTime) {
                throw new Error('Token has expired');
            }

            const response = await fetch('/api/actives/?limit=100', {
                headers: {
                    'Authorization': `Bearer ${token}` // Используйте ваш токен аутентификации
                }
            });
            if (!response.ok) throw new Error('Network response was not ok.');

            const data = await response.json();

            return data; // Ожидаем, что ответ сервера будет иметь структуру, которую вы описали
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const activesSlice = createSlice({
    name: 'actives',
    initialState: {
        items: [],
        selected: null,
        total: 0,
        status: 'idle',
        error: null
    },
    reducers: {
        // Здесь можно добавить другие reducers, если нужно
        setSelectedActives: (state, action) => {
            state.selected = action.payload;
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActives.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchActives.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.items = action.payload.actives;
                state.total = action.payload.total;
            })
            .addCase(fetchActives.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { setSelectedActives } = activesSlice.actions;
export const selectActives = (state) => state.actives.items;
export default activesSlice.reducer;
