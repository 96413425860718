


export default {
    traid: 'Trade',
    deposit: 'Deposit',
    withdrawal: 'Withdrawal',
    transactions: 'Transactions',
    deals: 'Deals',
    profile: 'Profile',
    analytics: 'Analytics',
    logout: 'Logout',
    real: 'Real Account',
    demo: 'Demo Account',
}
