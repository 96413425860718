// Компонент Layout.js
import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar'; // Предполагается, что у вас есть компонент Sidebar
import useAuth from '../hooks/useAuth.hook';
import DropdownMenu from '../components/DropdownMenu';
import { Link, NavLink } from 'react-router-dom';
import AlertComponent from '@/components/GlobalAlert';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserBalance } from '@/redux/userBalanceSlice';
import { fetchDealsForLead, fetchOpenDealsForLead } from '@/redux/dealsSlice';
import LangSwitcher from '@/components/LangSwitcher';
import { useLanguage } from '@/context/LanguageContext';
// import component
import Drawer from 'react-modern-drawer'

//import styles
import 'react-modern-drawer/dist/index.css'
import { fetchNews } from '@/redux/newsSlice';
import { dateFormater } from '@/utils/dateFormater';
import SlickSidebar from './SlickSidebar';

const news = [
    {
        id: 1,
        title: 'News 1',
        content: 'Content 1'
    },
    {
        id: 2,
        title: 'News 2',
        content: 'Content 2'
    },
    {
        id: 3,
        title: 'News 3',
        content: 'Content 3'
    },
    {
        id: 1,
        title: 'News 1',
        content: 'Content 1'
    },
    {
        id: 2,
        title: 'News 2',
        content: 'Content 2'
    },
    {
        id: 3,
        title: 'News 3',
        content: 'Content 3'
    },
    {
        id: 1,
        title: 'News 1',
        content: 'Content 1'
    },
    {
        id: 2,
        title: 'News 2',
        content: 'Content 2'
    },
    {
        id: 3,
        title: 'News 3',
        content: 'Content 3'
    }
]


const newNews = (news) => {
    const currentDate = new Date();

    return news.some(newsItem => {
        const newsDate = new Date(newsItem.created_at);
        const diff = (currentDate - newsDate) / (1000 * 60 * 60); // Разница в часах
        return diff <= 24;
    });
}

const Layout = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const auth = useAuth()
    const { token } = useAuth()

    const dispatch = useDispatch()
    const { wallet, status, error } = useSelector(state => state.balance);
    const { deals, status: dealsStatus, openStatus, error: dealsError } = useSelector(state => state.deals);
    const { news, status: newsStatus, error: newsError } = useSelector(state => state.news)
    const { language, getTranslation } = useLanguage();
    const t = getTranslation('Menu')

    useEffect(() => {
        if (auth.token) {
            if (status === 'idle') {
                dispatch(fetchUserBalance(auth.token));
                console.log('fetching balance')
            }

            if (dealsStatus === 'idle') {
                dispatch(fetchDealsForLead({ token: auth.token }));
            }

            if (newsStatus === 'idle') {
                dispatch(fetchNews({ token: auth.token }));
            }
        }
    }, [status, dealsStatus, auth, openStatus, newsStatus, token]);

    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    return (
        <div className="flex pb-[35px] md:pb-0">
            {/* global alert  */}
            {auth.isLoaded && auth.token && <SlickSidebar />}
            <div className="flex-1 flex flex-col overflow-hidden ">
                {auth.isLoaded && auth.token && <header className='flex flex-col md:flex-row flex-wrap items-center justify-between h-auto md:h-[50px] md:px-4'>
                    <div className='text-white text-xs flex items-center justify-between w-full md:w-auto px-4'>
                        <NavLink to={'/'} className="items-center gap-1 hidden lg:flex">
                            <img src="/auth-logo.png" alt="logo" className="h-[30px] " />
                        </NavLink>

                        <div className='flex md:hidden w-full py-1'>
                            <button onClick={toggleDrawer} className="relative px-4 py-1 md:py-2 text-sm bg-gray-900 hover:bg-gray-700 text-white text-xs mr-1 rounded-lg">
                                {newNews(news) && <div className='w-3 h-3 top-[6px] absolute bg-orange-500 rounded-full'></div>}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                                </svg>
                            </button>
                            <DropdownMenu />

                            <Link to={language.toLowerCase() + '/deposit'} className="inline-flex justify-center items-center gap-1 bg-green-500 text-white text-xs px-2 py-1 rounded-lg shadow-lg w-[115px] hover:bg-opacity-70 ml-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                                </svg>
                                {t['deposit']}
                            </Link>
                        </div>
                    </div>
                    <div className='hidden md:flex flex-wrap items-center gap-2 lg:gap-4 my-4 md:my-0'>
                        <div className='hidden md:block'>
                            <button onClick={toggleDrawer} className="relative px-4 py-1 md:py-2 text-sm bg-gray-900 hover:bg-gray-700 text-white text-xs mr-1 rounded-lg">
                                {newNews(news) && <div className='w-3 h-3 top-[6px] absolute bg-orange-500 rounded-full'></div>}
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                                </svg>
                            </button>

                            <LangSwitcher />
                            <DropdownMenu />
                        </div>
                        <div className="hidden md:flex items-center gap-1 font-bold">
                            <Link to={language.toLowerCase() + '/deposit'} className="flex justify-center items-center gap-1 flex-1 bg-green-500 text-white text-xs px-2 py-1 rounded-lg shadow-lg min-w-[115px] hover:bg-opacity-70">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                    <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25ZM12.75 9a.75.75 0 0 0-1.5 0v2.25H9a.75.75 0 0 0 0 1.5h2.25V15a.75.75 0 0 0 1.5 0v-2.25H15a.75.75 0 0 0 0-1.5h-2.25V9Z" clipRule="evenodd" />
                                </svg>
                                {t['deposit']}
                            </Link>
                            {auth?.user?.permissions?.allowWithdrawal &&
                                <Link to={language.toLowerCase() + '/withdrawal'} className="flex justify-center items-center gap-1 flex-1 bg-red-500 text-white text-xs px-2 py-1 rounded-lg shadow-lg min-w-[115px] hover:bg-opacity-70">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                                        <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm-.53 14.03a.75.75 0 0 0 1.06 0l3-3a.75.75 0 1 0-1.06-1.06l-1.72 1.72V8.25a.75.75 0 0 0-1.5 0v5.69l-1.72-1.72a.75.75 0 0 0-1.06 1.06l3 3Z" clipRule="evenodd" />
                                    </svg>
                                    {t['withdrawal']}
                                </Link>}
                        </div>
                    </div>
                </header>}
                <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-800 bg-opacity-50">
                    <AlertComponent />

                    {children}
                </main>
            </div>
            {auth.isLoaded && auth.token && <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='overflow-auto bg-gray-900 bg-opacity-50 divide-y p-4'
                style={{ background: 'rgb(17 24 39)', width: '320px' }}
            >
                {news.length > 0 ? news.map((item, index) => (
                    <div key={index} className='flex flex-col gap-1 mb-2 pt-4'>
                        <h1 className='text-lg font-bold text-gray-200 my-0'>{item.title}</h1>
                        <p className='text-lg text-gray-200'>{item.description}</p>
                        <i className='text-xs text-gray-300'>{dateFormater(item.created_at)}</i>
                    </div>
                )) : 'No news'}
            </Drawer>}

            {/* Fixed mobile menu */}
            {auth.isLoaded && auth.token && <div className="fixed gap-2 flex bottom-0 left-0 right-0 bg-gray-900 md:hidden w-full h-[45px]">

                <NavLink to={'/' + language.toLowerCase() + '/profile'} className={`${(window.location.pathname === '/' + language.toLowerCase() + '/profile') && 'bg-blue-500'} w-1/6 flex items-center justify-center text-white rounded-lg`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
                        <path fillRule="evenodd" d="M4.5 3.75a3 3 0 0 0-3 3v10.5a3 3 0 0 0 3 3h15a3 3 0 0 0 3-3V6.75a3 3 0 0 0-3-3h-15Zm4.125 3a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Zm-3.873 8.703a4.126 4.126 0 0 1 7.746 0 .75.75 0 0 1-.351.92 7.47 7.47 0 0 1-3.522.877 7.47 7.47 0 0 1-3.522-.877.75.75 0 0 1-.351-.92ZM15 8.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15ZM14.25 12a.75.75 0 0 1 .75-.75h3.75a.75.75 0 0 1 0 1.5H15a.75.75 0 0 1-.75-.75Zm.75 2.25a.75.75 0 0 0 0 1.5h3.75a.75.75 0 0 0 0-1.5H15Z" clipRule="evenodd" />
                    </svg>
                </NavLink>


                <NavLink to={'/' + language.toLowerCase() + '/deals'} className={`${(window.location.pathname === '/' + language.toLowerCase() + '/deals') && 'bg-blue-500'} w-1/6 flex items-center justify-center text-white rounded-lg`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 6.878V6a2.25 2.25 0 0 1 2.25-2.25h7.5A2.25 2.25 0 0 1 18 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 0 0 4.5 9v.878m13.5-3A2.25 2.25 0 0 1 19.5 9v.878m0 0a2.246 2.246 0 0 0-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0 1 21 12v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6c0-.98.626-1.813 1.5-2.122" />
                    </svg>
                </NavLink>


                <NavLink to={'/'} className={`${(window.location.pathname === '/' + language.toLowerCase()) && 'bg-blue-500'} w-2/6 flex items-center justify-center text-white rounded-lg`}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-8 h-8">
                        <path fillRule="evenodd" d="M2.25 2.25a.75.75 0 0 0 0 1.5H3v10.5a3 3 0 0 0 3 3h1.21l-1.172 3.513a.75.75 0 0 0 1.424.474l.329-.987h8.418l.33.987a.75.75 0 0 0 1.422-.474l-1.17-3.513H18a3 3 0 0 0 3-3V3.75h.75a.75.75 0 0 0 0-1.5H2.25Zm6.04 16.5.5-1.5h6.42l.5 1.5H8.29Zm7.46-12a.75.75 0 0 0-1.5 0v6a.75.75 0 0 0 1.5 0v-6Zm-3 2.25a.75.75 0 0 0-1.5 0v3.75a.75.75 0 0 0 1.5 0V9Zm-3 2.25a.75.75 0 0 0-1.5 0v1.5a.75.75 0 0 0 1.5 0v-1.5Z" clipRule="evenodd" />
                    </svg>
                </NavLink>

                <NavLink to={'/' + language.toLowerCase() + '/transactions'} className={`${(window.location.pathname === '/' + language.toLowerCase() + '/transactions') && 'bg-blue-500'} w-1/6 flex items-center justify-center text-white rounded-lg`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a2.25 2.25 0 0 0-2.25-2.25H15a3 3 0 1 1-6 0H5.25A2.25 2.25 0 0 0 3 12m18 0v6a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 9m18 0V6a2.25 2.25 0 0 0-2.25-2.25H5.25A2.25 2.25 0 0 0 3 6v3" />
                    </svg>
                </NavLink>

                <NavLink to={'/' + language.toLowerCase() + '/analytics'} className={`${(window.location.pathname === '/' + language.toLowerCase() + '/analytics') && 'bg-blue-500'} w-1/6 flex items-center justify-center text-white rounded-lg`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-white">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5m.75-9 3-3 2.148 2.148A12.061 12.061 0 0 1 16.5 7.605" />
                    </svg>
                </NavLink>
            </div>}
        </div>
    );
};

export default Layout;
