import React, {useEffect} from "react";
import Table from "./Table";
import Menu from "@/layout/Menu";

const Deals = () => {
    return (
        <div className="flex flex-col flex-1 w-full">
            <Menu />
            <Table />
        </div>
    );
}

export default Deals;