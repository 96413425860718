// features/websocket/websocketSlice.js
import { createSlice } from '@reduxjs/toolkit';


export const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    isConnected: false,
    error: null,
    socket: null,
  },
  reducers: {
    connected: (state) => {
      state.isConnected = true;
      state.error = null;
    },
    disconnected: (state) => {
      state.isConnected = false;
      state.socket = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSocket: (state, action) => {
      state.socket = action.payload;
    },
  },
});

export const { connected, disconnected, setError, setSocket } = websocketSlice.actions;

export default websocketSlice.reducer;
