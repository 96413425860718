import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increment, decrement, setTime } from '@/redux/timeSlice';
import { Input } from "@material-tailwind/react";

function TimeSelector() {
    const [showPresets, setShowPresets] = useState(false); // состояние для отображения предустановленных интервалов
    const dispatch = useDispatch();
    const time = useSelector((state) => state.time.value);
    const timeSelectorRef = React.useRef(null);

    // Преобразование строки времени в секунды
    const timeStringToSeconds = (timeString) => {
        const [hours = '0', minutes = '0', seconds = '0'] = timeString.split(':');
        return Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);
    };
    // Преобразование секунд в строку времени
    const timeSecondsToString = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((totalSeconds % 3600) / 60).toString().padStart(2, '0');
        const seconds = (totalSeconds % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    // Обработчик изменения времени
    const handleTimeChange = (event) => {
        const newTime = timeStringToSeconds(event.target.value);
        if (!isNaN(newTime) && newTime >= 5 && newTime <= 14400) {
            dispatch(setTime(newTime));
        }
    };


    const addSecond = () => dispatch(increment());
    const subtractSecond = () => dispatch(decrement());
    const setTimePreset = (seconds) => {
        dispatch(setTime(seconds)); // предполагая, что ввод в минутах
        setShowPresets(false); // Скрываем предустановленные интервалы после выбора
    };

    // Функция для форматирования времени
    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        // Формируем части времени, добавляя ведущие нули при необходимости
        const parts = [
            hours ? String(hours).padStart(2, '0') : null,
            String(minutes).padStart(2, '0'),
            String(seconds).padStart(2, '0'),
        ].filter(Boolean); // Удаляем часы, если их нет

        return parts.join(':');
    };

    //outside click clost timeSelectorRef
    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (timeSelectorRef.current && !timeSelectorRef.current.contains(event.target)) {
                setShowPresets(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [timeSelectorRef]);

    return (
        <div className="relative bg-gray-800 text-xs text-white rounded-lg w-full">
            <div className="flex items-center justify-between mb-4 bg-gray-700 px-2 rounded-md border border-gray-600">
                <button onClick={subtractSecond} className="flex shrink-0  items-center justify-center bg-gray-800 w-6 h-6 rounded-full text-2xl hover:bg-opacity-70">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path fillRule="evenodd" d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
                    </svg>
                </button>
                <input
                    type="text"
                    value={timeSecondsToString(time)} // Преобразуем секунды обратно в строку для отображения
                    onChange={handleTimeChange} // Обновляем время в состоянии Redux
                    onFocus={() => setShowPresets(true)}
                    // onBlur={() => setShowPresets(false)}
                    className=" text-white  bg-gray-700 text-xs w-full px-4 py-2  text-center focus:outline-none"
                />

                <button onClick={addSecond} className="flex shrink-0  items-center justify-center bg-gray-800 w-6 h-6 rounded-full text-2xl hover:bg-opacity-70">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                        <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
                    </svg>
                </button>
            </div>
            {showPresets && (
                <div ref={timeSelectorRef} className="absolute flex flex-wrap justify-center gap-2 bg-gray-800 rounded-lg border p-4 ">
                    {/* Предустановленные временные интервалы */}
                    {[60, 120, 300, 600, 900, 1800, 3600, 7200, 14400].map((preset) => (
                        <button key={preset} onClick={() => setTimePreset(preset)} className="bg-gray-700 p-2 rounded-md">
                            {formatTime(preset)}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}

export default TimeSelector;
