// en

export default {
    // Persönliche Informationen
    personal_info: 'Persönliche Informationen',
    // E-Mail
    email: 'E-Mail',
    // Vorname
    name: 'Vorname',
    // Nachname
    surname: 'Nachname',
    // Geburtstag
    birthday: 'Geburtstag',
    // Land
    country: 'Land',
    // Speichern
    save: 'Speichern',
    // Dokumentenprüfung
    verification: 'Dokumentenprüfung',
    // Identitätsbestätigung
    identity: 'Identitätsbestätigung',
    // Adresse
    address: 'Adresse',
    // Beschäftigung
    employment: 'Beschäftigung',
    // Karte (Vorderseite)
    card_front: 'Karte (Vorderseite)',
    // Karte (Rückseite)
    card_back: 'Karte (Rückseite)',
    // Sicherheit
    security: 'Sicherheit',
    // Altes Passwort
    old_password: 'Altes Passwort',
    // Neues Passwort
    new_password: 'Neues Passwort',
    // Neues Passwort wiederholen
    repit_new_password: 'Neues Passwort wiederholen',
    // docs_verified
    docs_verified: 'Dokumente überprüft',
    // docs_upload_success
    docs_upload_success: 'Dokument erfolgreich hochgeladen',
    // docs_upload_fail
    docs_upload_fail: 'Fehler beim Hochladen des Dokuments',
    countryList: [
        { value: 'AZ', label: 'Azerbaijan' },
        { value: 'AX', label: 'Åland Islands' },
        { value: 'AL', label: 'Albania' },
        { value: 'DZ', label: 'Algeria' },
        { value: 'AS', label: 'American Samoa' },
        { value: 'AI', label: 'Anguilla' },
        { value: 'AO', label: 'Angola' },
        { value: 'AQ', label: 'Antarctica' },
        { value: 'AG', label: 'Antigua and Barbuda' },
        { value: 'AR', label: 'Argentina' },
        { value: 'AM', label: 'Armenia' },
        { value: 'AW', label: 'Aruba' },
        { value: 'AF', label: 'Afghanistan' },
        { value: 'BS', label: 'Bahamas' },
        { value: 'BD', label: 'Bangladesh' },
        { value: 'BB', label: 'Barbados' },
        { value: 'BH', label: 'Bahrain' },
        { value: 'BY', label: 'Belarus' },
        { value: 'BZ', label: 'Belize' },
        { value: 'BJ', label: 'Benin' },
        { value: 'BM', label: 'Bermuda' },
        { value: 'BO', label: 'Bolivia' },
        { value: 'BA', label: 'Bosnia and Herzegovina' },
        { value: 'BW', label: 'Botswana' },
        { value: 'BR', label: 'Brazil' },
        { value: 'IO', label: 'British Indian Ocean Territory' },
        { value: 'BN', label: 'Brunei Darussalam' },
        { value: 'BF', label: 'Burkina Faso' },
        { value: 'BI', label: 'Burundi' },
        { value: 'BT', label: 'Bhutan' },
        { value: 'VU', label: 'Vanuatu' },
        { value: 'VA', label: 'Vatican City' },
        { value: 'VE', label: 'Venezuela' },
        { value: 'TL', label: 'Timor-Leste' },
        { value: 'VN', label: 'Vietnam' },
        { value: 'GA', label: 'Gabon' },
        { value: 'HT', label: 'Haiti' },
        { value: 'GY', label: 'Guyana' },
        { value: 'GM', label: 'Gambia' },
        { value: 'GH', label: 'Ghana' },
        { value: 'GP', label: 'Guadeloupe' },
        { value: 'GT', label: 'Guatemala' },
        { value: 'GN', label: 'Guinea' },
        { value: 'GW', label: 'Guinea-Bissau' },
        { value: 'GG', label: 'Guernsey' },
        { value: 'GI', label: 'Gibraltar' },
        { value: 'HN', label: 'Honduras' },
        { value: 'GD', label: 'Grenada' },
        { value: 'GL', label: 'Greenland' },
        { value: 'GE', label: 'Georgia' },
        { value: 'JE', label: 'Jersey' },
        { value: 'DJ', label: 'Djibouti' },
        { value: 'DM', label: 'Dominica' },
        { value: 'DO', label: 'Dominican Republic' },
        { value: 'EG', label: 'Egypt' },
        { value: 'ZM', label: 'Zambia' },
        { value: 'EH', label: 'Western Sahara' },
        { value: 'ZW', label: 'Zimbabwe' },
        { value: 'IN', label: 'India' },
        { value: 'ID', label: 'Indonesia' },
        { value: 'JO', label: 'Jordan' },
        { value: 'IQ', label: 'Iraq' },
        { value: 'IR', label: 'Iran' },
        { value: 'IS', label: 'Iceland' },
        { value: 'YE', label: 'Yemen' },
        { value: 'CV', label: 'Cabo Verde' },
        { value: 'KZ', label: 'Kazakhstan' },
        { value: 'KH', label: 'Cambodia' },
        { value: 'CM', label: 'Cameroon' },
        { value: 'QA', label: 'Qatar' },
        { value: 'KE', label: 'Kenya' },
        { value: 'KG', label: 'Kyrgyzstan' },
        { value: 'KI', label: 'Kiribati' },
        { value: 'CN', label: 'China' },
        { value: 'KP', label: 'North Korea' },
        { value: 'CC', label: 'Cocos (Keeling) Islands' },
        { value: 'CO', label: 'Colombia' },
        { value: 'KM', label: 'Comoros' },
        { value: 'CG', label: 'Congo - Brazzaville' },
        { value: 'CD', label: 'Congo - Kinshasa' },
        { value: 'CR', label: 'Costa Rica' },
        { value: 'CI', label: 'Côte d’Ivoire' },
        { value: 'CU', label: 'Cuba' },
        { value: 'KW', label: 'Kuwait' },
        { value: 'CW', label: 'Curaçao' },
        { value: 'LA', label: 'Laos' },
        { value: 'LS', label: 'Lesotho' },
        { value: 'LR', label: 'Liberia' },
        { value: 'LB', label: 'Lebanon' },
        { value: 'LY', label: 'Libya' },
        { value: 'MU', label: 'Mauritius' },
        { value: 'MR', label: 'Mauritania' },
        { value: 'MG', label: 'Madagascar' },
        { value: 'YT', label: 'Mayotte' },
        { value: 'MO', label: 'Macao SAR' },
        { value: 'MW', label: 'Malawi' },
        { value: 'MY', label: 'Malaysia' },
        { value: 'ML', label: 'Mali' },
        { value: 'MV', label: 'Maldives' },
        { value: 'MA', label: 'Morocco' },
        { value: 'MQ', label: 'Martinique' },
        { value: 'MH', label: 'Marshall Islands' },
        { value: 'MX', label: 'Mexico' },
        { value: 'MZ', label: 'Mozambique' },
        { value: 'MD', label: 'Moldova' },
        { value: 'MC', label: 'Monaco' },
        { value: 'MN', label: 'Mongolia' },
        { value: 'MS', label: 'Montserrat' },
        { value: 'MM', label: 'Myanmar (Burma)' },
        { value: 'NA', label: 'Namibia' },
        { value: 'NR', label: 'Nauru' },
        { value: 'NP', label: 'Nepal' },
        { value: 'NE', label: 'Niger' },
        { value: 'NG', label: 'Nigeria' },
        { value: 'NI', label: 'Nicaragua' },
        { value: 'NU', label: 'Niue' },
        { value: 'NC', label: 'New Caledonia' },
        { value: 'BV', label: 'Bouvet Island' },
        { value: 'IM', label: 'Isle of Man' },
        { value: 'NF', label: 'Norfolk Island' },
        { value: 'CX', label: 'Christmas Island' },
        { value: 'SH', label: 'Saint Helena' },
        { value: 'PN', label: 'Pitcairn Islands' },
        { value: 'TC', label: 'Turks and Caicos Islands' },
        { value: 'HM', label: 'Heard & McDonald Islands' },
        { value: 'AE', label: 'United Arab Emirates' },
        { value: 'OM', label: 'Oman' },
        { value: 'KY', label: 'Cayman Islands' },
        { value: 'CK', label: 'Cook Islands' },
        { value: 'PK', label: 'Pakistan' },
        { value: 'PW', label: 'Palau' },
        { value: 'PS', label: 'Palestinian Territories' },
        { value: 'PA', label: 'Panama' },
        { value: 'PG', label: 'Papua New Guinea' },
        { value: 'PY', label: 'Paraguay' },
        { value: 'PE', label: 'Peru' },
        { value: 'KR', label: 'South Korea' },
        { value: 'RE', label: 'Réunion' },
        { value: 'RU', label: 'Russia' },
        { value: 'RW', label: 'Rwanda' },
        { value: 'SV', label: 'El Salvador' },
        { value: 'WS', label: 'Samoa' },
        { value: 'ST', label: 'São Tomé and Príncipe' },
        { value: 'SA', label: 'Saudi Arabia' },
        { value: 'MK', label: 'North Macedonia' },
        { value: 'SC', label: 'Seychelles' },
        { value: 'BL', label: 'Saint Barthélemy' },
        { value: 'MF', label: 'Saint Martin' },
        { value: 'PM', label: 'St. Pierre & Miquelon' },
        { value: 'SN', label: 'Senegal' },
        { value: 'VC', label: 'St. Vincent & Grenadines' },
        { value: 'KN', label: 'St. Kitts & Nevis' },
        { value: 'LC', label: 'St. Lucia' },
        { value: 'RS', label: 'Serbia' },
        { value: 'SG', label: 'Singapore' },
        { value: 'SX', label: 'Sint Maarten' },
        { value: 'SY', label: 'Syria' },
        { value: 'SB', label: 'Solomon Islands' },
        { value: 'SO', label: 'Somalia' },
        { value: 'SD', label: 'Sudan' },
        { value: 'SR', label: 'Suriname' },
        { value: 'TJ', label: 'Tajikistan' },
        { value: 'TH', label: 'Thailand' },
        { value: 'TW', label: 'Taiwan' },
        { value: 'TZ', label: 'Tanzania' },
        { value: 'TG', label: 'Togo' },
        { value: 'TK', label: 'Tokelau' },
        { value: 'TO', label: 'Tonga' },
        { value: 'TT', label: 'Trinidad and Tobago' },
        { value: 'TV', label: 'Tuvalu' },
        { value: 'TN', label: 'Tunisia' },
        { value: 'TM', label: 'Turkmenistan' },
        { value: 'TR', label: 'Turkey' },
        { value: 'UG', label: 'Uganda' },
        { value: 'UZ', label: 'Uzbekistan' },
        { value: 'UA', label: 'Ukraine' },
        { value: 'WF', label: 'Wallis & Futuna' },
        { value: 'UY', label: 'Uruguay' },
        { value: 'FO', label: 'Faroe Islands' },
        { value: 'FM', label: 'Micronesia' },
        { value: 'FJ', label: 'Fiji' },
        { value: 'PH', label: 'Philippines' },
        { value: 'FK', label: 'Falkland Islands' },
        { value: 'GF', label: 'French Guiana' },
        { value: 'PF', label: 'French Polynesia' },
        { value: 'TF', label: 'French Southern Territories' },
        { value: 'CF', label: 'Central African Republic' },
        { value: 'TD', label: 'Chad' },
        { value: 'ME', label: 'Montenegro' },
        { value: 'CL', label: 'Chile' },
        { value: 'SJ', label: 'Svalbard and Jan Mayen' },
        { value: 'LK', label: 'Sri Lanka' },
        { value: 'EC', label: 'Ecuador' },
        { value: 'GQ', label: 'Equatorial Guinea' },
        { value: 'ER', label: 'Eritrea' },
        { value: 'SZ', label: 'Eswatini' },
        { value: 'ET', label: 'Ethiopia' },
        { value: 'GS', label: 'South Georgia & South Sandwich Islands' },
        { value: 'ZA', label: 'South Africa' },
        { value: 'SS', label: 'South Sudan' },
        { value: 'JM', label: 'Jamaica' },
        { value: 'JP', label: 'Japan' }
    ]
}