// de

export default {
    // Ausstehender Handel
    pending_trade: 'Ausstehender Handel',
    // Datum und Uhrzeit wählen
    date_and_time: 'Datum und Uhrzeit wählen',
    // Uhrzeit
    time: 'Uhrzeit',
    // Investition
    investment: 'Investition',
    // hoch
    up: 'hoch',
    // runter
    down: 'runter',
    // Ihre Auszahlung
    your_payout: 'Ihre Auszahlung',
    // Deals
    deals: 'Deals',
    // Ausstehend
    pending: 'Ausstehend',
    // In Bearbeitung
    in_progress: 'In Bearbeitung',
}