// Компонент Sidebar.js
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { tabs } from './pages';
import useAuth from '../hooks/useAuth.hook';
import TradingWidget from '@/components/component/trading/TradingWidget';

function Sidebar() {
  const auth = useAuth();
  const sidebarRef = useRef();

  return (

    <aside
      ref={sidebarRef}
      className={`h-full bg-gray-800 shadow-md overflow-y-auto relative md:z-0 block shadow-none w-full md:w-[280px] md:overflow-hidden md:h-[calc(100vh-50px)]`}
    >
      <div className="flex flex-col h-full justify-between">
        {/* Первая часть: аватар с именем и должностью */}
        <TradingWidget />

      </div>
    </aside>
  );
}

export default Sidebar;