export default {
  //Аккаунт
  account: 'Аккаунт',
  //На счету
  on_account: 'На счету',
  // Доступно для вывода
  available_for_withdrawal: 'Доступно для вывода',
  // Комиссия
  commission: 'Комиссия',
  // Ваши прошлые заявки
  last_applications: 'Ваши прошлые заявки',
  // Вывод
  withdrawal: 'Вывод',
  // Нет для вывода
  not_for_withdrawal: 'Вы можете вывести деньги со своего баланса на свою банковскую карту или электронный кошелек, который вы использовали для пополнения счета. Вы можете запросить вывод средств в любое время. Ваши запросы на вывод средств обрабатываются в течение 3 рабочих дней.',
  // Сделать депозит
  make_a_deposit: 'Сделать депозит',
  // История выплат
  payment_history: 'История выплат',
  faq: [
    {
      question: 'Как вывести деньги со счета?',
      answer: 'Процедура вывода капитала предельно проста и совершается через Ваш личный кабинет.\n\nСпособ, который Вы выбрали для пополнения счета, является и способом снятия средств.\n\nНапример, если для заключения сделки Вы пополнили свой счет через платежную систему Visa, то и снимать деньги Вы будете через платежную систему Visa.\n\nКогда речь идет о выводе достаточно крупной суммы, Компания может запросить верификацию (верификация запрашивается на личное усмотрение Компании), вот почему очень важно регистрировать счет лично на себя, чтобы в любой момент времени подтвердить свои права на него.'
    },
    {
      question: 'Сколько времени занимает вывод средств?',
      answer: 'В среднем процедура вывода средств занимает от одного до пяти дней с даты получения соответствующей заявки Клиента и зависит только от объема одновременно обрабатываемых заявок. Компания всегда старается производить выплаты непосредственно в день поступления запроса от Клиента.'
    },
    {
      question: 'Существует ли комиссия при пополнении или выводе средств со счета?',
      answer: 'Нет. Компания не взымает комиссию ни за операцию пополнения счета, ни за вывод средств.\n\nОднако, стоит учитывать, что платежные системы могут взимать свои комиссии и использовать внутренний курс конвертации валют.'
    },
    {
      question: 'Нужны ли какие-нибудь документы, чтобы осуществить вывод средств?',
      answer: 'Обычно для вывода средств дополнительные документы не нужны. Но Компания по своему усмотрению может попросить Вас подтвердить свои личные данные путем запроса определенных документов. Обычно это делается в целях предотвращения действий, связанных с незаконной торговлей, финансовыми махинациями, а также использованием средств, полученных незаконным путем.\n\nСписок таких документов минимален, а операция по их предоставлению не отнимет у Вас много времени и сил.'
    },
    {
      question: 'Какова минимальная сумма вывода?',
      answer: 'Минимальная сумма вывода начинается с 10 USD для большинства платежных систем.\n\nДля криптовалют эта сумма начинается от 50 USD (и может быть выше для некоторых валют, например Bitcoin).'
    }
  ]
}
