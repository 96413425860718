import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';


export const fetchDealsForLead = createAsyncThunk(
  'deals/fetchDealsForLead',
  async ({ token, status = null, limit = 10, offset = 0, demo = false }, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/deals/get_deals_for_lead/?demo=${demo}&limit=${limit}&offset=${offset}${status ? '&status=' + status : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Не удалось получить сделки');
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchOpenDealsForLead = createAsyncThunk(
  'deals/fetchOpenDealsForLead',
  async ({ token, status = 'open', limit = 10, offset = 0, demo = false }, { rejectWithValue }) => {
    try {
      const response = await fetch(`/api/deals/get_deals_for_lead/?demo=${demo}&limit=${limit}&offset=${offset}${status ? '&status=' + status : ''}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
        },
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || 'Не удалось получить сделки');
      }
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  deals: [],
  openDeals: [],
  total: 0,
  status: 'idle', // 'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  currentPage: 1,
  dealsPerPage: 10,
  openTotal: 0,
  openStatus: 'idle',
  openError: null,

};

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    // Reducer для добавления сделки
    addDeal: (state, action) => {
      state.deals.push(action.payload);
    },

    bulkAddDeals: (state, action) => {
      state.deals = [...state.deals, ...action.payload];
    },
    // Reducer для обновления сделки
    updateDeal: (state, action) => {
      const index = state.deals.findIndex(deal => deal.id === action.payload.deal_id);
      if (index !== -1) {
        state.deals[index] = { ...state.deals[index], ...action.payload };
      }
    },
    // Reducer для удаления сделки
    deleteDeal: (state, action) => {
      state.deals = state.deals.filter(deal => deal.id !== action.payload);
    },
    // Reducer для установки списка сделок
    setDeals: (state, action) => {
      state.deals = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setDealsPerPage: (state, action) => {
      state.dealsPerPage = action.payload;
    },
    getOpenDeals: (state) => {
      return state.deals.filter(deal => deal.status === 'open');
    },
    setDefaultPage: (state) => {
      state.currentPage = 1;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDealsForLead.pending, (state) => {
        state.status = 'loading';
      })
      // .addCase(fetchDealsForLead.fulfilled, (state, action) => {
      //   state.status = 'succeeded';
      //   state.deals = action.payload.deals;
      //   state.total = action.payload.total;

      // })
      .addCase(fetchDealsForLead.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.deals = action.payload.deals;
        state.total = action.payload.total;
        // Добавляем новые сделки в конец массива, если они ещё не были добавлены
        // const newDeals = action.payload.deals;
        // const existingDealIds = new Set(state.deals.map(deal => deal.id));
        // const filteredNewDeals = newDeals.filter(deal => !existingDealIds.has(deal.id));
        // state.deals = _.orderBy([...state.deals, ...filteredNewDeals], ['open_time'], ['desc']); // 'asc' для сортировки по возрастанию, 'desc' - по убыванию
        // state.total = action.payload.total;
      })
      .addCase(fetchDealsForLead.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      })
      .addCase(fetchOpenDealsForLead.pending, (state) => {
        state.openStatus = 'loading';
      })
      .addCase(fetchOpenDealsForLead.fulfilled, (state, action) => {
        state.openStatus = 'succeeded';
        state.openDeals = action.payload.deals;
        state.openTotal = action.payload.total;
      })
      .addCase(fetchOpenDealsForLead.rejected, (state, action) => {
        state.openStatus = 'failed';
        state.openError = action.payload;
      });
  },

});

// Action creators генерируются для каждой функции reducer
export const { addDeal, updateDeal, deleteDeal, setDeals, setCurrentPage, setDealsPerPage, getOpenDeals, setDefaultPage } = dealsSlice.actions;

export const selectAllDeals = (state) => state.deals.deals;
export const getDealsStatus = (state) => state.deals.status;
export const getDealsError = (state) => state.deals.error;
export const selectCurrentPage = (state) => state.deals.currentPage;
export const selectDealsPerPage = (state) => state.deals.dealsPerPage;
export const selectTotalDeals = (state) => state.deals.total;


// Экспорт reducer'а
export default dealsSlice.reducer;
