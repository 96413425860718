// EN
// Fields: INSTRUMENT	DEAL	OPEN QUOTE	CLOSE QUOTE	AMOUNT	RESULT

export default {
    instrument: 'Instrument',
    deal: 'Deal',
    open: 'Open Quote',
    close: 'Close Quote',
    sum: 'Amount',
    result: 'Result',
    next: 'Next',
    prev: 'Prev',
    total_result: 'Total result',
}