// ru

export default {
   // Личная информация
    personal_info: 'Личная информация',
    // Email
    email: 'Email',
    // Имя
    name: 'Имя',
    // Фамилия
    surname: 'Фамилия',
    // Дата рождения
    birthday: 'Дата рождения',
    // Страна
    country: 'Страна',
    // Сохранить
    save: 'Сохранить',
    // Верификация документов
    verification: 'Верификация документов',
    // Подтверждение личности
    identity: 'Подтверждение личности',
    // Место прописки
    address: 'Место прописки',
    // Трудоустройство
    employment: 'Трудоустройство',
    // Карта (передняя сторона)
    card_front: 'Карта (передняя сторона)',
    // Карта (задняя сторона)
    card_back: 'Карта (задняя сторона)',
    // Безопасность
    security: 'Безопасность',
    // Старый пароль
    old_password: 'Старый пароль',
    // Новый пароль
    new_password: 'Новый пароль',
    // Повтор нового пароля
    repit_new_password: 'Повтор нового пароля',
    // docs_verified
    docs_verified: 'Документ верифицирован',
    // docs_upload_success
    docs_upload_success: 'Документ успешно загружен',
    // docs_upload_fail
    docs_upload_fail: 'Ошибка загрузки документа',

    countryList:[
        { value: 'AZ', label: 'Азербайджан' },
        { value: 'AX', label: 'Аландские о-ва' },
        { value: 'AL', label: 'Албания' },
        { value: 'DZ', label: 'Алжир' },
        { value: 'AS', label: 'Американское Самоа' },
        { value: 'AI', label: 'Ангилья' },
        { value: 'AO', label: 'Ангола' },
        { value: 'AQ', label: 'Антарктида' },
        { value: 'AG', label: 'Антигуа и Барбуда' },
        { value: 'AR', label: 'Аргентина' },
        { value: 'AM', label: 'Армения' },
        { value: 'AW', label: 'Аруба' },
        { value: 'AF', label: 'Афганистан' },
        { value: 'BS', label: 'Багамы' },
        { value: 'BD', label: 'Бангладеш' },
        { value: 'BB', label: 'Барбадос' },
        { value: 'BH', label: 'Бахрейн' },
        { value: 'BY', label: 'Беларусь' },
        { value: 'BZ', label: 'Белиз' },
        { value: 'BJ', label: 'Бенин' },
        { value: 'BM', label: 'Бермудские о-ва' },
        { value: 'BO', label: 'Боливия' },
        { value: 'BA', label: 'Босния и Герцеговина' },
        { value: 'BW', label: 'Ботсвана' },
        { value: 'BR', label: 'Бразилия' },
        { value: 'IO', label: 'Британская территория в Индийском океане' },
        { value: 'BN', label: 'Бруней-Даруссалам' },
        { value: 'BF', label: 'Буркина-Фасо' },
        { value: 'BI', label: 'Бурунди' },
        { value: 'BT', label: 'Бутан' },
        { value: 'VU', label: 'Вануату' },
        { value: 'VA', label: 'Ватикан' },
        { value: 'VE', label: 'Венесуэла' },
        { value: 'TL', label: 'Восточный Тимор' },
        { value: 'VN', label: 'Вьетнам' },
        { value: 'GA', label: 'Габон' },
        { value: 'HT', label: 'Гаити' },
        { value: 'GY', label: 'Гайана' },
        { value: 'GM', label: 'Гамбия' },
        { value: 'GH', label: 'Гана' },
        { value: 'GP', label: 'Гваделупа' },
        { value: 'GT', label: 'Гватемала' },
        { value: 'GN', label: 'Гвинея' },
        { value: 'GW', label: 'Гвинея-Бисау' },
        { value: 'GG', label: 'Гернси' },
        { value: 'GI', label: 'Гибралтар' },
        { value: 'HN', label: 'Гондурас' },
        { value: 'GD', label: 'Гренада' },
        { value: 'GL', label: 'Гренландия' },
        { value: 'GE', label: 'Грузия' },
        { value: 'JE', label: 'Джерси' },
        { value: 'DJ', label: 'Джибути' },
        { value: 'DM', label: 'Доминика' },
        { value: 'DO', label: 'Доминиканская Республика' },
        { value: 'EG', label: 'Египет' },
        { value: 'ZM', label: 'Замбия' },
        { value: 'EH', label: 'Западная Сахара' },
        { value: 'ZW', label: 'Зимбабве' },
        { value: 'IN', label: 'Индия' },
        { value: 'ID', label: 'Индонезия' },
        { value: 'JO', label: 'Иордания' },
        { value: 'IQ', label: 'Ирак' },
        { value: 'IR', label: 'Иран' },
        { value: 'IS', label: 'Исландия' },
        { value: 'YE', label: 'Йемен' },
        { value: 'CV', label: 'Кабо-Верде' },
        { value: 'KZ', label: 'Казахстан' },
        { value: 'KH', label: 'Камбоджа' },
        { value: 'CM', label: 'Камерун' },
        { value: 'QA', label: 'Катар' },
        { value: 'KE', label: 'Кения' },
        { value: 'KG', label: 'Киргизия' },
        { value: 'KI', label: 'Кирибати' },
        { value: 'CN', label: 'Китай' },
        { value: 'KP', label: 'КНДР' },
        { value: 'CC', label: 'Кокосовые о-ва' },
        { value: 'CO', label: 'Колумбия' },
        { value: 'KM', label: 'Коморы' },
        { value: 'CG', label: 'Конго - Браззавиль' },
        { value: 'CD', label: 'Конго - Киншаса' },
        { value: 'CR', label: 'Коста-Рика' },
        { value: 'CI', label: 'Кот-д’Ивуар' },
        { value: 'CU', label: 'Куба' },
        { value: 'KW', label: 'Кувейт' },
        { value: 'CW', label: 'Кюрасао' },
        { value: 'LA', label: 'Лаос' },
        { value: 'LS', label: 'Лесото' },
        { value: 'LR', label: 'Либерия' },
        { value: 'LB', label: 'Ливан' },
        { value: 'LY', label: 'Ливия' },
        { value: 'MU', label: 'Маврикий' },
        { value: 'MR', label: 'Мавритания' },
        { value: 'MG', label: 'Мадагаскар' },
        { value: 'YT', label: 'Майотта' },
        { value: 'MO', label: 'Макао (САР)' },
        { value: 'MW', label: 'Малави' },
        { value: 'MY', label: 'Малайзия' },
        { value: 'ML', label: 'Мали' },
        { value: 'MV', label: 'Мальдивы' },
        { value: 'MA', label: 'Марокко' },
        { value: 'MQ', label: 'Мартиника' },
        { value: 'MH', label: 'Маршалловы Острова' },
        { value: 'MX', label: 'Мексика' },
        { value: 'MZ', label: 'Мозамбик' },
        { value: 'MD', label: 'Молдова' },
        { value: 'MC', label: 'Монако' },
        { value: 'MN', label: 'Монголия' },
        { value: 'MS', label: 'Монтсеррат' },
        { value: 'MM', label: 'Мьянма (Бирма)' },
        { value: 'NA', label: 'Намибия' },
        { value: 'NR', label: 'Науру' },
        { value: 'NP', label: 'Непал' },
        { value: 'NE', label: 'Нигер' },
        { value: 'NG', label: 'Нигерия' },
        { value: 'NI', label: 'Никарагуа' },
        { value: 'NU', label: 'Ниуэ' },
        { value: 'NC', label: 'Новая Каледония' },
        { value: 'BV', label: 'о-в Буве' },
        { value: 'IM', label: 'о-в Мэн' },
        { value: 'NF', label: 'о-в Норфолк' },
        { value: 'CX', label: 'о-в Рождества' },
        { value: 'SH', label: 'о-в Св. Елены' },
        { value: 'PN', label: 'о-ва Питкэрн' },
        { value: 'TC', label: 'о-ва Тёркс и Кайкос' },
        { value: 'HM', label: 'о-ва Херд и Макдональд' },
        { value: 'AE', label: 'ОАЭ' },
        { value: 'OM', label: 'Оман' },
        { value: 'KY', label: 'Острова Кайман' },
        { value: 'CK', label: 'Острова Кука' },
        { value: 'PK', label: 'Пакистан' },
        { value: 'PW', label: 'Палау' },
        { value: 'PS', label: 'Палестинские территории' },
        { value: 'PA', label: 'Панама' },
        { value: 'PG', label: 'Папуа — Новая Гвинея' },
        { value: 'PY', label: 'Парагвай' },
        { value: 'PE', label: 'Перу' },
        { value: 'KR', label: 'Республика Корея' },
        { value: 'RE', label: 'Реюньон' },
        { value: 'RU', label: 'Россия' },
        { value: 'RW', label: 'Руанда' },
        { value: 'SV', label: 'Сальвадор' },
        { value: 'WS', label: 'Самоа' },
        { value: 'ST', label: 'Сан-Томе и Принсипи' },
        { value: 'SA', label: 'Саудовская Аравия' },
        { value: 'MK', label: 'Северная Македония' },
        { value: 'SC', label: 'Сейшельские Острова' },
        { value: 'BL', label: 'Сен-Бартелеми' },
        { value: 'MF', label: 'Сен-Мартен' },
        { value: 'PM', label: 'Сен-Пьер и Микелон' },
        { value: 'SN', label: 'Сенегал' },
        { value: 'VC', label: 'Сент-Винсент и Гренадины' },
        { value: 'KN', label: 'Сент-Китс и Невис' },
        { value: 'LC', label: 'Сент-Люсия' },
        { value: 'RS', label: 'Сербия' },
        { value: 'SG', label: 'Сингапур' },
        { value: 'SX', label: 'Синт-Мартен' },
        { value: 'SY', label: 'Сирия' },
        { value: 'SB', label: 'Соломоновы Острова' },
        { value: 'SO', label: 'Сомали' },
        { value: 'SD', label: 'Судан' },
        { value: 'SR', label: 'Суринам' },
        { value: 'TJ', label: 'Таджикистан' },
        { value: 'TH', label: 'Таиланд' },
        { value: 'TW', label: 'Тайвань' },
        { value: 'TZ', label: 'Танзания' },
        { value: 'TG', label: 'Того' },
        { value: 'TK', label: 'Токелау' },
        { value: 'TO', label: 'Тонга' },
        { value: 'TT', label: 'Тринидад и Тобаго' },
        { value: 'TV', label: 'Тувалу' },
        { value: 'TN', label: 'Тунис' },
        { value: 'TM', label: 'Туркменистан' },
        { value: 'TR', label: 'Турция' },
        { value: 'UG', label: 'Уганда' },
        { value: 'UZ', label: 'Узбекистан' },
        { value: 'UA', label: 'Украина' },
        { value: 'WF', label: 'Уоллис и Футуна' },
        { value: 'UY', label: 'Уругвай' },
        { value: 'FO', label: 'Фарерские о-ва' },
        { value: 'FM', label: 'Федеративные Штаты Микронезии' },
        { value: 'FJ', label: 'Фиджи' },
        { value: 'PH', label: 'Филиппины' },
        { value: 'FK', label: 'Фолклендские о-ва' },
        { value: 'GF', label: 'Французская Гвиана' },
        { value: 'PF', label: 'Французская Полинезия' },
        { value: 'TF', label: 'Французские Южные территории' },
        { value: 'CF', label: 'Центрально-Африканская Республика' },
        { value: 'TD', label: 'Чад' },
        { value: 'ME', label: 'Черногория' },
        { value: 'CL', label: 'Чили' },
        { value: 'SJ', label: 'Шпицберген и Ян-Майен' },
        { value: 'LK', label: 'Шри-Ланка' },
        { value: 'EC', label: 'Эквадор' },
        { value: 'GQ', label: 'Экваториальная Гвинея' },
        { value: 'ER', label: 'Эритрея' },
        { value: 'SZ', label: 'Эсватини' },
        { value: 'ET', label: 'Эфиопия' },
        { value: 'GS', label: 'Южная Георгия и Южные Сандвичевы о-ва' },
        { value: 'ZA', label: 'Южно-Африканская Республика' },
        { value: 'SS', label: 'Южный Судан' },
        { value: 'JM', label: 'Ямайка' },
        { value: 'JP', label: 'Япония' }
      ]
}