//de

export default {
    //Einzahlung
    deposit: 'Einzahlung',
    // Einzahlung erfolgreich Meldung
    depositSuccess: 'Ihr Konto wurde erfolgreich aufgeladen',
    // Fehler bei der Einzahlung Meldung
    depositError: 'Fehler beim Einzahlen auf das Konto',
    // Der Betrag
    the_amount: 'Betrag',
    //Zahlungsmethode
    payment_method: 'Zahlungsmethode',
    // Mindestbetrag
    min_amount: 'Mindestbetrag',
    // Höchstbetrag
    max_amount: 'Höchstbetrag',
    depositSuccessMsg: 'Ihre Zahlung wurde zur Bearbeitung gesendet.\nIhr Kontostand wird innerhalb von 5 Minuten gutgeschrieben.',
     // Минимальная сумма пополнения 10$
    min_amount_10: 'Mindestbetrag 10$',
    // Максимальная сумма пополнения 50000$
    max_amount_50000: 'Höchstbetrag 50000$',
  payment: {
    other: ['Kopieren Sie die Kartennummer', 'Öffnen Sie die Bankanwendung', 'Überweisen Sie den genauen Betrag in einer Zahlung'],
    crypto: ['Kopieren Sie die Wallet-Adresse', 'Überweisen Sie den genauen Betrag in einer Zahlung'],
    // Betrag zum Überweisen
    amount_to_transfer: 'Betrag zum Überweisen',
    // In diesem Betrag ist keine Bankgebühr enthalten
    amount_to_transfer_hint: 'In diesem Betrag ist keine Bankgebühr enthalten',
    // Kartennummer für die Überweisung
    card_number: 'Kartennummer für die Überweisung',
    // Anfrage abbrechen
    cancel_request: 'Anfrage abbrechen',
    // Ich habe bezahlt
    i_paid: 'Ich habe bezahlt',
  },
    faq: [
        {
            question: 'Gibt es einen Mindestbetrag, den ich bei der Registrierung auf mein Konto einzahlen kann?',
            answer: 'Der Vorteil der Handelsplattform des Unternehmens besteht darin, dass Sie keine großen Beträge auf Ihr Konto einzahlen müssen. Sie können mit einer kleinen Geldsumme mit dem Handel beginnen. Die Mindesteinzahlung beträgt 10 US-Dollar.'
        },
        {
            question: 'Muss ich das Konto der Handelsplattform aufladen und wie oft muss ich dies tun?',
            answer: `Um mit binären Optionen zu arbeiten, müssen Sie ein individuelles Konto eröffnen. Um echte Trades abzuschließen, müssen Sie sicherlich eine Einzahlung in Höhe der gekauften Optionen vornehmen.
          \n\n
          Sie können ohne Bargeld handeln und nur das Schulungskonto des Unternehmens (Demo-Konto) verwenden. Ein solches Konto ist kostenlos und dient dazu, die Funktionsweise der Handelsplattform zu demonstrieren. Mit Hilfe eines solchen Kontos können Sie den Erwerb von binären Optionen üben, die Grundprinzipien des Handels verstehen, verschiedene Methoden und Strategien testen oder das Niveau Ihrer Intuition bewerten.
          `
        },
        {
            question: 'Wie kann ich einzahlen?',
            answer: `Es ist sehr einfach zu tun. Der Vorgang dauert nur ein paar Minuten.
          \n\n
          1) Öffnen Sie das Handelsausführungsfenster und klicken Sie auf die grüne Schaltfläche "Einzahlung" in der oberen rechten Ecke des Tabs.
          \n\n
          Sie können das Konto auch über Ihr persönliches Konto aufladen, indem Sie auf die Schaltfläche "Einzahlung" im Kontoprofil klicken.
          \n\n
          2) Wählen Sie anschließend eine Methode zur Einzahlung auf das Konto aus (das Unternehmen bietet viele bequeme Methoden an, die dem Kunden zur Verfügung stehen und in seinem individuellen Konto angezeigt werden).
          \n\n
          3) Geben Sie als Nächstes die Währung an, in der das Konto eingezahlt wird, und entsprechend die Währung des Kontos selbst.
          \n\n
          4) Geben Sie den Einzahlungsbetrag ein.
          \n\n
          5) Füllen Sie das Formular aus, indem Sie die angeforderten Zahlungsdetails eingeben.
          \n\n
          6) Tätigen Sie eine Zahlung.`
        },
        {
            question: 'Was ist der Mindesteinzahlungsbetrag?',
            answer: `Der Vorteil der Handelsplattform des Unternehmens besteht darin, dass Sie keine großen Beträge auf Ihr Konto einzahlen müssen. Sie können mit einer kleinen Geldsumme mit dem Handel beginnen. Die Mindesteinzahlung beträgt 10 US-Dollar.`
        },
        {
            question: 'Fällt eine Gebühr für Einzahlungen oder Auszahlungen vom Konto an?',
            answer: `Nein. Das Unternehmen erhebt keine Gebühr für Einzahlungen oder Auszahlungen.
          \n\n
          Es ist jedoch zu beachten, dass Zahlungssysteme ihre Gebühren erheben können und den internen Wechselkurs verwenden.`
        }
    ]
}