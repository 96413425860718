// pl

export default {
    // Informacje osobiste
    personal_info: 'Informacje osobiste',
    // Email
    email: 'Email',
    // Imię
    name: 'Imię',
    // Nazwisko
    surname: 'Nazwisko',
    // Data urodzenia
    birthday: 'Data urodzenia',
    // Kraj
    country: 'Kraj',
    // Zapisz
    save: 'Zapisz',
    // Weryfikacja dokumentu
    verification: 'Weryfikacja dokumentu',
    // Potwierdzenie tożsamości
    identity: 'Potwierdzenie tożsamości',
    // Adres
    address: 'Adres',
    // Zatrudnienie
    employment: 'Zatrudnienie',
    // Karta (przednia strona)
    card_front: 'Karta (przednia strona)',
    // Karta (tylna strona)
    card_back: 'Karta (tylna strona)',
    // Bezpieczeństwo
    security: 'Bezpieczeństwo',
    // Stare hasło
    old_password: 'Stare hasło',
    // Nowe hasło
    new_password: 'Nowe hasło',
    // Powtórz nowe hasło
    repit_new_password: 'Powtórz nowe hasło',
    // docs_verified
    docs_verified: 'Dokumenty zweryfikowane',
    // docs_upload_success
    docs_upload_success: 'Pomyślnie przesłano dokument',
    // docs_upload_fail
    docs_upload_fail: 'Błąd podczas przesyłania dokumentu',
    countryList: [
        { value: 'AZ', label: 'Azerbejdżan' },
        { value: 'AX', label: 'Wyspy Alandzkie' },
        { value: 'AL', label: 'Albania' },
        { value: 'DZ', label: 'Algieria' },
        { value: 'AS', label: 'Samoa Amerykańskie' },
        { value: 'AI', label: 'Anguilla' },
        { value: 'AO', label: 'Angola' },
        { value: 'AQ', label: 'Antarktyda' },
        { value: 'AG', label: 'Antigua i Barbuda' },
        { value: 'AR', label: 'Argentyna' },
        { value: 'AM', label: 'Armenia' },
        { value: 'AW', label: 'Aruba' },
        { value: 'AF', label: 'Afganistan' },
        { value: 'BS', label: 'Bahamy' },
        { value: 'BD', label: 'Bangladesz' },
        { value: 'BB', label: 'Barbados' },
        { value: 'BH', label: 'Bahrajn' },
        { value: 'BY', label: 'Białoruś' },
        { value: 'BZ', label: 'Belize' },
        { value: 'BJ', label: 'Benin' },
        { value: 'BM', label: 'Bermudy' },
        { value: 'BO', label: 'Boliwia' },
        { value: 'BA', label: 'Bośnia i Hercegowina' },
        { value: 'BW', label: 'Botswana' },
        { value: 'BR', label: 'Brazylia' },
        { value: 'IO', label: 'Brytyjskie Terytorium Oceanu Indyjskiego' },
        { value: 'BN', label: 'Brunei' },
        { value: 'BF', label: 'Burkina Faso' },
        { value: 'BI', label: 'Burundi' },
        { value: 'BT', label: 'Bhutan' },
        { value: 'VU', label: 'Vanuatu' },
        { value: 'VA', label: 'Watykan' },
        { value: 'VE', label: 'Wenezuela' },
        { value: 'TL', label: 'Timor Wschodni' },
        { value: 'VN', label: 'Wietnam' },
        { value: 'GA', label: 'Gabon' },
        { value: 'HT', label: 'Haiti' },
        { value: 'GY', label: 'Gujana' },
        { value: 'GM', label: 'Gambia' },
        { value: 'GH', label: 'Ghana' },
        { value: 'GP', label: 'Gwadelupa' },
        { value: 'GT', label: 'Gwatemala' },
        { value: 'GN', label: 'Gwinea' },
        { value: 'GW', label: 'Gwinea Bissau' },
        { value: 'GG', label: 'Guernsey' },
        { value: 'GI', label: 'Gibraltar' },
        { value: 'HN', label: 'Honduras' },
        { value: 'GD', label: 'Grenada' },
        { value: 'GL', label: 'Grenlandia' },
        { value: 'GE', label: 'Gruzja' },
        { value: 'JE', label: 'Jersey' },
        { value: 'DJ', label: 'Dżibuti' },
        { value: 'DM', label: 'Dominika' },
        { value: 'DO', label: 'Dominikana' },
        { value: 'EG', label: 'Egipt' },
        { value: 'ZM', label: 'Zambia' },
        { value: 'EH', label: 'Sahara Zachodnia' },
        { value: 'ZW', label: 'Zimbabwe' },
        { value: 'IN', label: 'Indie' },
        { value: 'ID', label: 'Indonezja' },
        { value: 'JO', label: 'Jordania' },
        { value: 'IQ', label: 'Irak' },
        { value: 'IR', label: 'Iran' },
        { value: 'IS', label: 'Islandia' },
        { value: 'YE', label: 'Jemen' },
        { value: 'CV', label: 'Republika Zielonego Przylądka' },
        { value: 'KZ', label: 'Kazachstan' },
        { value: 'KH', label: 'Kambodża' },
        { value: 'CM', label: 'Kamerun' },
        { value: 'QA', label: 'Katar' },
        { value: 'KE', label: 'Kenia' },
        { value: 'KG', label: 'Kirgistan' },
        { value: 'KI', label: 'Kiribati' },
        { value: 'CN', label: 'Chiny' },
        { value: 'KP', label: 'Korea Północna' },
        { value: 'CC', label: 'Wyspy Kokosowe' },
        { value: 'CO', label: 'Kolumbia' },
        { value: 'KM', label: 'Komory' },
        { value: 'CG', label: 'Kongo' },
        { value: 'CD', label: 'Demokratyczna Republika Konga' },
        { value: 'CR', label: 'Kostaryka' },
        { value: 'CI', label: 'Wybrzeże Kości Słoniowej' },
        { value: 'CU', label: 'Kuba' },
        { value: 'KW', label: 'Kuwejt' },
        { value: 'CW', label: 'Curaçao' },
        { value: 'LA', label: 'Laos' },
        { value: 'LS', label: 'Lesotho' },
        { value: 'LR', label: 'Liberia' },
        { value: 'LB', label: 'Liban' },
        { value: 'LY', label: 'Libia' },
        { value: 'MU', label: 'Mauritius' },
        { value: 'MR', label: 'Mauretania' },
        { value: 'MG', label: 'Madagaskar' },
        { value: 'YT', label: 'Majotta' },
        { value: 'MO', label: 'Makau' },
        { value: 'MW', label: 'Malawi' },
        { value: 'MY', label: 'Malezja' },
        { value: 'ML', label: 'Mali' },
        { value: 'MV', label: 'Malediwy' },
        { value: 'MA', label: 'Maroko' },
        { value: 'MQ', label: 'Martynika' },
        { value: 'MH', label: 'Wyspy Marshalla' },
        { value: 'MX', label: 'Meksyk' },
        { value: 'MZ', label: 'Mozambik' },
        { value: 'MD', label: 'Mołdawia' },
        { value: 'MC', label: 'Monako' },
        { value: 'MN', label: 'Mongolia' },
        { value: 'MS', label: 'Montserrat' },
        { value: 'MM', label: 'Mjanma (Birma)' },
        { value: 'NA', label: 'Namibia' },
        { value: 'NR', label: 'Nauru' },
        { value: 'NP', label: 'Nepal' },
        { value: 'NE', label: 'Niger' },
        { value: 'NG', label: 'Nigeria' },
        { value: 'NI', label: 'Nikaragua' },
        { value: 'NU', label: 'Niue' },
        { value: 'NC', label: 'Nowa Kaledonia' },
        { value: 'BV', label: 'Wyspa Bouveta' },
        { value: 'IM', label: 'Wyspa Man' },
        { value: 'NF', label: 'Wyspa Norfolk' },
        { value: 'CX', label: 'Wyspa Bożego Narodzenia' },
        { value: 'SH', label: 'Wyspa Świętej Heleny' },
        { value: 'PN', label: 'Wyspy Pitcairn' },
        { value: 'TC', label: 'Turks i Caicos' },
        { value: 'HM', label: 'Wyspy Heard i McDonalda' },
        { value: 'AE', label: 'Zjednoczone Emiraty Arabskie' },
        { value: 'OM', label: 'Oman' },
        { value: 'KY', label: 'Kajmany' },
        { value: 'CK', label: 'Wyspy Cooka' },
        { value: 'PK', label: 'Pakistan' },
        { value: 'PW', label: 'Palau' },
        { value: 'PS', label: 'Terytoria Palestyńskie' },
        { value: 'PA', label: 'Panama' },
        { value: 'PG', label: 'Papua-Nowa Gwinea' },
        { value: 'PY', label: 'Paragwaj' },
        { value: 'PE', label: 'Peru' },
        { value: 'KR', label: 'Korea Południowa' },
        { value: 'RE', label: 'Reunion' },
        { value: 'RU', label: 'Rosja' },
        { value: 'RW', label: 'Rwanda' },
        { value: 'SV', label: 'Salwador' },
        { value: 'WS', label: 'Samoa' },
        { value: 'ST', label: 'Wyspy Świętego Tomasza i Książęca' },
        { value: 'SA', label: 'Arabia Saudyjska' },
        { value: 'MK', label: 'Macedonia Północna' },
        { value: 'SC', label: 'Seszele' },
        { value: 'BL', label: 'Saint-Barthélemy' },
        { value: 'MF', label: 'Saint-Martin' },
        { value: 'PM', label: 'Saint-Pierre i Miquelon' },
        { value: 'SN', label: 'Senegal' },
        { value: 'VC', label: 'Saint Vincent i Grenadyny' },
        { value: 'KN', label: 'Saint Kitts i Nevis' },
        { value: 'LC', label: 'Saint Lucia' },
        { value: 'RS', label: 'Serbia' },
        { value: 'SG', label: 'Singapur' },
        { value: 'SX', label: 'Sint Maarten' },
        { value: 'SY', label: 'Syria' },
        { value: 'SB', label: 'Wyspy Salomona' },
        { value: 'SO', label: 'Somalia' },
        { value: 'SD', label: 'Sudan' },
        { value: 'SR', label: 'Surinam' },
        { value: 'TJ', label: 'Tadżykistan' },
        { value: 'TH', label: 'Tajlandia' },
        { value: 'TW', label: 'Tajwan' },
        { value: 'TZ', label: 'Tanzania' },
        { value: 'TG', label: 'Togo' },
        { value: 'TK', label: 'Tokelau' },
        { value: 'TO', label: 'Tonga' },
        { value: 'TT', label: 'Trynidad i Tobago' },
        { value: 'TV', label: 'Tuvalu' },
        { value: 'TN', label: 'Tunezja' },
        { value: 'TM', label: 'Turkmenistan' },
        { value: 'TR', label: 'Turcja' },
        { value: 'UG', label: 'Uganda' },
        { value: 'UZ', label: 'Uzbekistan' },
        { value: 'UA', label: 'Ukraina' },
        { value: 'WF', label: 'Wallis i Futuna' },
        { value: 'UY', label: 'Urugwaj' },
        { value: 'FO', label: 'Wyspy Owcze' },
        { value: 'FM', label: 'Mikronezja' },
        { value: 'FJ', label: 'Fidżi' },
        { value: 'PH', label: 'Filipiny' },
        { value: 'FK', label: 'Falklandy' },
        { value: 'GF', label: 'Gujana Francuska' },
        { value: 'PF', label: 'Polinezja Francuska' },
        { value: 'TF', label: 'Francuskie Terytoria Południowe' },
        { value: 'CF', label: 'Republika Środkowoafrykańska' },
        { value: 'TD', label: 'Czad' },
        { value: 'ME', label: 'Czarnogóra' },
        { value: 'CL', label: 'Chile' },
        { value: 'SJ', label: 'Svalbard i Jan Mayen' },
        { value: 'LK', label: 'Sri Lanka' },
        { value: 'EC', label: 'Ekwador' },
        { value: 'GQ', label: 'Gwinea Równikowa' },
        { value: 'ER', label: 'Erytrea' },
        { value: 'SZ', label: 'Eswatini' },
        { value: 'ET', label: 'Etiopia' },
        { value: 'GS', label: 'Georgia Południowa i Sandwich Południowy' },
        { value: 'ZA', label: 'Republika Południowej Afryki' },
        { value: 'SS', label: 'Sudan Południowy' },
        { value: 'JM', label: 'Jamajka' },
        { value: 'JP', label: 'Japonia' }
    ]
}