// DE
// Fields: INSTRUMENT	DEAL	OPEN QUOTE	CLOSE QUOTE	AMOUNT	RESULT

export default {
    instrument: 'Instrument',
    deal: 'Deal',
    open: 'Eröffnungskurs',
    close: 'Schlusskurs',
    sum: 'Betrag',
    result: 'Ergebnis',
    next: 'Weiter',
    prev: 'Zurück',
    total_result: 'Gesamtergebnis',
}