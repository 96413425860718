//de
//Fields: DATUM	BETRAG	TYP	STATUS	KARTE	WÄHRUNG	METHODE

export default {
    date: 'Datum',
    amount: 'Betrag',
    type: 'Typ',
    status: 'Status',
    card: 'Karte',
    currency: 'Währung',
    method: 'Methode',
    // operation status
    operationStatus: (status) => {
        switch (status) {
            case 'success':
                return 'Erfolgreich';
            case 'pending':
                return 'Ausstehend';
            case 'failed':
                return 'Fehlgeschlagen';
            case 'error':
                return 'Fehler';
            default:
                return '-';
        }
    },
    operationType: (type) => {
        switch (type) {
            case 'deposit':
                return 'Einzahlung';
            case 'withdrawal':
                return 'Auszahlung';
            case 'bonus':
                return 'Bonus';
            default:
                return '-';
        }
    }
}