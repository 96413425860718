// TradingViewWidget.jsx
import React, { useState, useEffect, useRef, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedActives } from '@/redux/activesSlice';
import Sidebar from '@/layout/Sidebar';
import ChartComponent from '@/components/ChartComponent';

function TradingViewWidget({ actives }) {
  const dispatch = useDispatch();
  // const [selectedOption, setSelectedOption] = useState(actives[0]);
  const selectedOption = useSelector(state => state.actives.selected);

  const container = useRef();
  // const actives = ['GBP/USD', 'EUR/USD', 'AUD/USD', 'CAD/USD', 'JPY/USD']



  useEffect(() => {
    if (selectedOption === null) {
      dispatch(setSelectedActives(
        localStorage.getItem('selectedOption') ?
          actives.find(active => active.id === JSON.parse(localStorage.getItem('selectedOption')).id) :
          actives
            .find(active => active.id === 6)
      ));
    }
  }, [actives]);

  // useEffect(
  //   () => {
  //     const script = document.createElement("script");
  //     script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
  //     script.type = "text/javascript";
  //     script.async = true;
  //     script.innerHTML = `
  //       {
  //         "autosize": false,
  //         "symbol": "${selectedOption?.prefix ?? 'FX'}:${selectedOption?.type.replace(' / ', '') ?? 'GBPUSD'}",
  //         "interval": "1",
  //         "timezone": "Etc/UTC",
  //         "theme": "dark",
  //         "style": "1",
  //         "locale": "en",
  //         "enable_publishing": true,
  //         "allow_symbol_change": false,
  //         "support_host": "https://www.tradingview.com"
  //       }`;
  //     container.current.appendChild(script);

  //     return () => {
  //       if (container.current)
  //         container.current.innerHTML = '';
  //     };

  //   }, [selectedOption]);

  return (<>
    {/* <div className="tradingview-widget-container min-h-[500px] md:h-screen w-full" ref={container} >
      <div className="tradingview-widget-container__widget min-h-[500px] md:h-[calc(100%-32px)] w-full"></div>
    </div> */}
    {selectedOption && <ChartComponent />}
  </>
  );
}

const TradingViewWrap = () => {
  const actives = useSelector(state => state.actives.items);

  return (<div className='flex flex-col md:flex-row '>
    <div className="flex-1 flex flex-col overflow-hidden bg-gray-800 text-white shadow-md w-full">
      {actives.length === 0 && <div className="text-center">Loading...</div>}
      {actives.length > 0 && <TradingViewWidget actives={actives} />}
    </div>
    <Sidebar isOpen={true} />

  </div>
  )
}
export default memo(TradingViewWrap);
