// pl

export default {
    //Konto
    account: 'Konto',
    //Na koncie
    on_account: 'Na koncie',
    // Dostępne do wypłaty
    available_for_withdrawal: 'Dostępne do wypłaty',
    // Prowizja
    commission: 'Prowizja',
    // Twoje poprzednie wnioski
    last_applications: 'Twoje poprzednie wnioski',
    // Wypłata
    withdrawal: 'Wypłata',
    // Brak dostępnych środków do wypłaty
    not_for_withdrawal: 'Możesz wypłacić środki z salda na swoją kartę bankową lub portfel elektroniczny, który został użyty do doładowania konta. Możesz złożyć wniosek o wypłatę środków w dowolnym momencie. Wnioski o wypłatę środków są przetwarzane w ciągu 3 dni roboczych.',
    // Dokonaj wpłaty
    make_a_deposit: 'Dokonaj wpłaty',
    // Historia płatności
    payment_history: 'Historia płatności',

    faq: [
            {
                question: 'Jak wypłacić pieniądze z konta?',
                answer: 'Procedura wypłaty kapitału jest bardzo prosta i odbywa się za pośrednictwem Twojego konta osobistego.\n\nSposób, który wybrałeś do doładowania konta, jest również sposobem wypłaty środków.\n\nNa przykład, jeśli doładowałeś swoje konto za pomocą systemu płatności Visa, to również będziesz wypłacać pieniądze za pomocą systemu płatności Visa.\n\nKiedy chodzi o wypłatę większej sumy, Firma może poprosić o weryfikację (weryfikacja jest wymagana według uznania Firmy), dlatego ważne jest, aby zarejestrować konto na swoje dane, aby w każdej chwili potwierdzić swoje prawa do niego.'
            },
            {
                question: 'Ile czasu zajmuje wypłata środków?',
                answer: 'Średnio procedura wypłaty środków trwa od jednego do pięciu dni od daty otrzymania odpowiedniego wniosku od Klienta i zależy tylko od ilości jednocześnie przetwarzanych wniosków. Firma zawsze stara się dokonywać wypłat w dniu otrzymania wniosku od Klienta.'
            },
            {
                question: 'Czy istnieje opłata za doładowanie lub wypłatę środków z konta?',
                answer: 'Nie. Firma nie pobiera opłat za operację doładowania konta ani za wypłatę środków.\n\nNależy jednak pamiętać, że systemy płatności mogą pobierać swoje opłaty i stosować wewnętrzny kurs wymiany walut.'
            },
            {
                question: 'Czy potrzebne są jakieś dokumenty do wypłaty środków?',
                answer: 'Zazwyczaj do wypłaty środków nie są potrzebne dodatkowe dokumenty. Jednak Firma może według własnego uznania poprosić Cię o potwierdzenie swoich danych osobowych poprzez dostarczenie określonych dokumentów. Zazwyczaj jest to robione w celu zapobieżenia działaniom związanym z nielegalnym handlem, manipulacjami finansowymi oraz wykorzystywaniem środków uzyskanych w sposób nielegalny.\n\nLista takich dokumentów jest minimalna, a operacja ich dostarczenia nie zabierze Ci wiele czasu i wysiłku.'
            },
            {
                question: 'Jaka jest minimalna kwota wypłaty?',
                answer: 'Minimalna kwota wypłaty wynosi 10 USD dla większości systemów płatności.\n\nDla kryptowalut ta kwota zaczyna się od 50 USD (i może być wyższa dla niektórych walut, na przykład Bitcoin).'
            }
        ]
}
