// en

export default {
    // Pending Trade
    pending_trade: 'Pending Trade',
    // Choose date and time
    date_and_time: 'Choose date and time',
    //Time
    time: 'Time',
    // Investment
    investment: 'Investment',
    // up
    up: 'up',
    // down
    down: 'down',
    // Your payout
    your_payout: 'Your payout',
    // Deals
    deals: 'Deals',
    // Pending
    pending: 'Pending',
    // In progress
    in_progress: 'In progress',
}