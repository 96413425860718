export default {
    traid: 'Handel',
    deposit: 'Depozyt',
    withdrawal: 'Wypłata',
    transactions: 'Transakcje',
    deals: 'Deale',
    profile: 'Profil',
    analytics: 'Analiza',
    logout: 'Wyloguj',
    real: 'Konto prawdziwe',
    demo: 'Konto Demo',
}
