// features/websocket/websocketActions.js
import { connected, disconnected, setError, setSocket } from './websocketSlice';

let keepAliveInterval = null;


export const initializeWebSocket = (token) => (dispatch) => {
  const URL = `${process.env.REACT_APP_WS_URL}/api/deals/ws/deal/${token}`;
  const socket = new WebSocket(URL);


  const startKeepAlive = () => {
    keepAliveInterval = setInterval(() => {
      if (socket.readyState === WebSocket.OPEN) {
        socket.send(JSON.stringify({ type: "ping" }));
      }
    }, 10000);
  };

  const stopKeepAlive = () => {
    if (keepAliveInterval) {
      clearInterval(keepAliveInterval);
      keepAliveInterval = null;
    }
  };

  socket.onopen = () => {
    dispatch(connected());
    startKeepAlive();

    // socket.send(JSON.stringify({ message: 'Anriy mrazzzzz' }));
  };

  socket.onclose = () => {
    dispatch(disconnected());
    stopKeepAlive();
    // Здесь можно добавить логику для переподключения
    reconnectWebSocket(token, dispatch);

  };

  socket.onerror = (error) => {
    dispatch(setError(error.message));
  };

  dispatch(setSocket(socket));
};


const reconnectWebSocket = (token, dispatch) => {
  // Задержка перед переподключением
  setTimeout(() => initializeWebSocket(token)(dispatch), 5000);
};