import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { clearAlerts } from '@/redux/globalAlertSlice';

const AlertComponent = () => {
    const alert = useSelector(state => state.globalAlert.alert);
    const type = useSelector(state => state.globalAlert.type);
    const dispatch = useDispatch();

    useEffect(() => {
        if (alert !== '') {
            const timer = setTimeout(() => {
                dispatch(clearAlerts());
            }, 15000);

            return () => clearTimeout(timer);
        }
    }, [alert, dispatch]);

    if (alert === '') {
        return null;
    }
    return (
        <div className={`fixed top-4 min-w-[320px] w-auto left-1/2 -translate-x-1/2 z-50 flex justify-center items-center h-12 ${classColor(type)} rounded-xl shadow-sm px-4`}>
            <div className='flex-1 text-center font-bold'>{alert}</div>
            <button onClick={() => dispatch(clearAlerts())}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-gray-800 w-6 h-6 bg-white rounded-full p-1">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                </svg>

            </svg>
            </button>
        </div >
    );
};

const classColor = (type) => {
    switch (type) {
        case 'success':
            return 'shadow-emerald-400 bg-emerald-400 text-white';
        case 'error':
            return 'bg-red-400 text-white';
        case 'warning':
            return 'bg-yellow-400 text-black';
        case 'info':
            return 'bg-blue-400 text-white';
        default:
            return '';
    }
};


export default AlertComponent;
