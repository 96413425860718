// gm

export default {
    // Total Profit
    total_profit: 'Gesamtgewinn',
    // Average Profit per Trade
    average_profit_per_trade: 'Durchschnittlicher Gewinn pro Trade',
    // Most Profitable Trade
    most_profitable_trade: 'Rentabelster Trade',
    // Deal for Amount
    deal_for_amount: 'Deal für Betrag',
    // Profit
    profit: 'Gewinn',
    // Date
    date: 'Datum',
    // Duration
    duration: 'Dauer',
    // Instruments Analytics
    instruments_analytics: 'Instrumentenanalyse',
    // Successful Deals
    successful_deals: 'Erfolgreiche Trades',
    // Heute
    today: 'Heute',
    // Gestern
    yesterday: 'Gestern',
    // 30 Tage
    thirtyDays: '30 Tage',
}
