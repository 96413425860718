import React, { useState } from 'react';
import { useLanguage } from '@/context/LanguageContext';

const FAQAccordion = () => {
  const [activeIndex, setActiveIndex] = useState(-1); // to track which item is open
  const { getTranslation } = useLanguage();
  const t = getTranslation('withdrawal');

  return (
    <div className="bg-gray-900 text-white p-4 rounded-md">
      <div className="font-bold text-xl mb-2">FAQ:</div>
      <div className='flex flex-col gap'>
        {t.faq.map((faq, index) => (
          <div key={index} className=''>
            <button
              className="flex justify-between items-center w-full text-left py-4 font-bold "
              onClick={() => setActiveIndex(activeIndex === index ? -1 : index)} // Toggle active index
            >
              <span>{faq.question}</span>
              <span className={`duration-300 ${activeIndex === index ? ' rotate-180' : 'rotate-0'}`}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                <path fillRule="evenodd" d="M11.47 7.72a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06L12 9.31l-6.97 6.97a.75.75 0 0 1-1.06-1.06l7.5-7.5Z" clipRule="evenodd" />
              </svg>
              </span>
            </button>
            {activeIndex === index && (
              <div className="mt-2 bg-gray-800 p-4 rounded-md whitespace-pre-wrap">
                {faq.answer.replace(/^\s+/gm, '').trim()}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQAccordion;
