import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import translations from '../lang';

export const langList = [
    { code: 'RU', img: '/img/ru.png', prefix: '/ru' },
    { code: 'EN', img: '/img/en.png', prefix: '/en' },
    { code: 'ES', img: '/img/pl.png', prefix: '/es' },
    { code: 'PL', img: '/img/de.png', prefix: '/pl' },
    { code: 'DE', img: '/img/fr.png', prefix: '/de' }, 
];
const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export const LanguageProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { lang } = useParams();
    // Get default value from localStorage or set 
    const [language, setLanguage] = useState(
        localStorage.getItem('appLanguage') || 'RU'
    );
    const currentTranslations = translations[language];

    const getNestedTranslation = (obj, path) => {
        return path.split('.').reduce((xs, x) => (xs && xs[x] !== undefined ? xs[x] : null), obj);
    };

    // Function for receiving translation with automatic fallback into Ukrainian
    const getTranslation = (path) => {
        const value = getNestedTranslation(currentTranslations, path);
        // If the value is not found, use Ukrainian as a fallback
        return value !== null ? value : getNestedTranslation(translations.RU, path);
        // return getNestedTranslation(translations.RU, path); //
    };

    const toggleLanguage = (langCode) => {
        console.log('langCode: ', langCode);
        localStorage.setItem('appLanguage', langCode);
        setLanguage(langCode);
    };

    useEffect(() => {
        // Получение текущего пути без повторяющегося языкового сегмента
        // const path = location.pathname.replace(/^\/[a-z]{2}/, '');

        if (!lang) {
            // const storedLang = localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage').toLowerCase() : language.toLowerCase();
            // const currentPath = window.location.pathname.replace(/\/storedLang/, '');
            // const newPath = `/${storedLang.toLowerCase()}${location.pathname}`;

            const storedLang = localStorage.getItem('appLanguage') ? localStorage.getItem('appLanguage').toLowerCase() : language.toLowerCase();
            const regex = new RegExp(`/${storedLang}`);
            const currentPath = window.location.pathname.replace(regex, '');
            const newPath = `/${storedLang.toLowerCase()}${currentPath}`;

            navigate(newPath);
        }

        // const langFromPath = window.location.pathname.includes('/en/') ;
        // console.log("langFromPath: ", langFromPath)
        // const currentPath = window.location.pathname.replace(/\/en/, '');
        // // Определение нового пути
        // const newPath = language === 'EN' ? `/en${currentPath}` : currentPath;
        // // Редирект только если путь изменился
        // if (newPath !== window.location.pathname) {
        //     localStorage.removeItem('rsc_cache')
        //     navigate(newPath);
        // }
    }, [language]);


    return (
        <LanguageContext.Provider value={{
            language: language, // just language,
            translations: translations[currentTranslations], // currentTranslations,
            toggleLanguage,
            getTranslation: getTranslation,
        }}>
            {children}
        </LanguageContext.Provider>
    );
};
