// ru
// Fields: ИНСТРУМЕНТ	СДЕЛКА	КОТИРОВКА, ОТКРЫТИЕ	КОТИРОВКА, ЗАКРЫТИЕ	СУММА	РЕЗУЛЬТАТ

export default {
    instrument: 'Инструмент',
    deal: 'Сделка',
    open: 'Котировка, открытие',
    close: 'Котировка, закрытие',
    sum: 'Сумма',
    result: 'Результат',
    next: 'Следующая',
    prev: 'Предыдущая',
    total_result: 'Всего',
}