// de

export default {
    //Konto
    account: 'Konto',
    //Auf dem Konto
    on_account: 'Auf dem Konto',
    // Verfügbar zum Abheben
    available_for_withdrawal: 'Verfügbar zum Abheben',
    // Kommission
    commission: 'Kommission',
    // Ihre vergangenen Anträge
    last_applications: 'Ihre vergangenen Anträge',
    // Abhebung
    withdrawal: 'Abhebung',
    // Nicht zum Abheben
    not_for_withdrawal: 'Sie können Geld von Ihrem Kontostand auf Ihre Bankkarte oder E-Wallet abheben, die Sie zur Einzahlung verwendet haben. Sie können jederzeit eine Auszahlung beantragen. Ihre Auszahlungsanträge werden innerhalb von 3 Werktagen bearbeitet.',
    // Eine Einzahlung tätigen
    make_a_deposit: 'Eine Einzahlung tätigen',
    // Zahlungshistorie
    payment_history: 'Zahlungshistorie',

    faq: [
            {
                question: 'Wie kann ich Geld von meinem Konto abheben?',
                answer: 'Der Kapitalabhebungsprozess ist sehr einfach und erfolgt über Ihr persönliches Konto.\n\nDie Methode, die Sie zur Einzahlung auf Ihr Konto verwendet haben, ist auch die Methode, mit der Sie Geld abheben können.\n\nZum Beispiel, wenn Sie Ihr Konto über das Visa-Zahlungssystem aufgeladen haben, werden Sie auch Geld über das Visa-Zahlungssystem abheben.\n\nWenn es um die Abhebung einer größeren Summe geht, kann das Unternehmen eine Verifizierung anfordern (die Verifizierung erfolgt nach eigenem Ermessen des Unternehmens). Deshalb ist es sehr wichtig, das Konto persönlich auf Ihren Namen zu registrieren, um jederzeit Ihre Rechte darauf bestätigen zu können.'
            },
            {
                question: 'Wie lange dauert es, Geld abzuheben?',
                answer: 'Im Durchschnitt dauert der Geldabhebungsprozess zwischen einem und fünf Tagen ab dem Datum des Eingangs des entsprechenden Kundenantrags und hängt nur von der Anzahl der gleichzeitig bearbeiteten Anträge ab. Das Unternehmen bemüht sich immer, Zahlungen am Tag des Eingangs des Antrags vom Kunden zu leisten.'
            },
            {
                question: 'Fällt eine Gebühr für Einzahlungen oder Abhebungen an?',
                answer: 'Nein. Das Unternehmen erhebt weder eine Gebühr für Einzahlungen auf das Konto noch für Geldabhebungen.\n\nEs ist jedoch zu beachten, dass Zahlungssysteme ihre eigenen Gebühren erheben und einen internen Wechselkurs verwenden können.'
            },
            {
                question: 'Werden für die Geldabhebung zusätzliche Dokumente benötigt?',
                answer: 'In der Regel sind für Geldabhebungen keine zusätzlichen Dokumente erforderlich. Das Unternehmen kann jedoch nach eigenem Ermessen darum bitten, Ihre persönlichen Daten durch die Vorlage bestimmter Dokumente zu bestätigen. Dies geschieht normalerweise, um Maßnahmen im Zusammenhang mit illegalem Handel, Finanzmanipulationen und der Verwendung von unrechtmäßig erworbenen Mitteln zu verhindern.\n\nDie Liste solcher Dokumente ist minimal und der Vorgang ihrer Vorlage nimmt nicht viel Zeit und Mühe in Anspruch.'
            },
            {
                question: 'Wie hoch ist der Mindestbetrag für eine Auszahlung?',
                answer: 'Der Mindestbetrag für eine Auszahlung beträgt in den meisten Zahlungssystemen 10 USD.\n\nFür Kryptowährungen beginnt dieser Betrag bei 50 USD (und kann für einige Währungen wie Bitcoin höher sein).'
            }
        ]
}
