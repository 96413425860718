import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    alert: '',
    type: '' // ['success', 'error', 'warning', 'info']
};

const globalAlertSlice = createSlice({
    name: 'globalAlert',
    initialState,
    reducers: {
        addAlert: (state, action) => {
            console.log(action);
            state.alert = action.payload.alert;
            state.type = action.payload.type;
        },
        removeAlert: (state, action) => {
            state.alert = '';
            state.type = '';
        },
        clearAlerts: state => {
            state.alert = '';
            state.type = '';
        },
    },
});

export const { addAlert, removeAlert, clearAlerts } = globalAlertSlice.actions;

export default globalAlertSlice.reducer;
