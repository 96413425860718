import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { increaseInvestment, decreaseInvestment, setInvestment } from '@/redux/investmentSlice';

function InvestmentInput() {
  const dispatch = useDispatch();
  const investment = useSelector((state) => state.investment.value);
  const { wallet, activeWallet } = useSelector(state => state.balance);
  const available_funds = activeWallet === 'real' ? wallet.available_funds : wallet.demo_balance;
  
  const handleInvestmentChange = (event) => {
    const value = parseInt(event.target.value.replace(' $', ''), 10) || 0;
    if (value <= available_funds) {
      dispatch(setInvestment(value));
    } else {
      dispatch(setInvestment(available_funds));
    }
  };

  return (
    <div className="flex items-center justify-between bg-gray-700 px-2 py-1 rounded-md border border-gray-600 w-full">
      <button onClick={() => dispatch(decreaseInvestment())} className="flex items-center justify-center bg-gray-800 w-6 h-6 rounded-full shrink-0 text-white text-2xl hover:bg-opacity-70">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
          <path fillRule="evenodd" d="M4.25 12a.75.75 0 0 1 .75-.75h14a.75.75 0 0 1 0 1.5H5a.75.75 0 0 1-.75-.75Z" clipRule="evenodd" />
        </svg>
      </button>
      <input
        type="text"
        className="text-white bg-gray-700 text-xs w-20 mx-2 text-center focus:outline-none"
        value={`${investment} $`}
        onChange={handleInvestmentChange}
      />
      <button onClick={() => dispatch(increaseInvestment(available_funds))} className="flex items-center justify-center bg-gray-800 w-6 h-6 rounded-full shrink-0 text-white text-2xl hover:bg-opacity-70">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
          <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
        </svg>

      </button>
    </div>
  );
}

export default InvestmentInput;
