// ES
// Campos: INSTRUMENTO	OPERACIÓN	COTIZACIÓN DE APERTURA	COTIZACIÓN DE CIERRE	CANTIDAD	RESULTADO

export default {
    instrument: 'Instrumento',
    deal: 'Operación',
    open: 'Cotización de Apertura',
    close: 'Cotización de Cierre',
    sum: 'Cantidad',
    result: 'Resultado',
    next: 'Siguiente',
    prev: 'Anterior',
    total_result: 'Resultado Total',
}