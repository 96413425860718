import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

export const fetchTransactions = createAsyncThunk(
    'transactions/fetchTransactions',
    async ({ token, limit = 10, offset = 0 }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/transactions/get_transaction_for_lead/?limit=${limit}&offset=${offset}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить транзакции');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

const initialState = {
    txns: [],
    total: 0,
    status: 'idle',
    currentPage: 1,
    txnsPerPage: 10,
    error: null
};

const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        // Reducer для добавления сделки
        addTxn: (state, action) => {
            state.txns.push(action.payload);
        },
        // Reducer для обновления сделки
        updateTxns: (state, action) => {
            const index = state.txns.findIndex(deal => deal.id === action.payload.id);
            if (index !== -1) {
                state.txns[index] = action.payload;
            }
        },
        // Reducer для удаления сделки
        deleteTxn: (state, action) => {
            state.txns = state.txns.filter(deal => deal.id !== action.payload);
        },
        // Reducer для установки списка сделок
        setTxns: (state, action) => {
            state.txns = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setTxnsPerPage: (state, action) => {
            state.dealsPerPage = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.txns = action.payload.transactions;
                state.total = action.payload.total;

                // const newTxns = action.payload.transactions;
                // const existingTxnsds = new Set(state.txns.map(txn => txn.id));
                // const filteredNewTxns = newTxns.filter(txn => !existingTxnsds.has(txn.id));
                // state.txns = _.orderBy([...state.txns, ...filteredNewTxns], ['created_at'], ['desc']); // 'asc' для сортировки по возрастанию, 'desc' - по убыванию
                // state.total = action.payload.total;


            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },

});

// Action creators генерируются для каждой функции reducer
export const { addTxn, updateTxns, deleteTxn, setTxns, setCurrentPage, setTxnsPerPage } = transactionSlice.actions;
export const getAllTxns = (state) => state.transactions.txn;
export const getDealsStatus = (state) => state.transactions.status;
export const getDealsError = (state) => state.transactions.error;

// Экспорт reducer'а
export default transactionSlice.reducer;
