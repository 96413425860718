// pl

export default {
    // Oczekujący handel
    pending_trade: 'Oczekujący handel',
    // Wybierz datę i godzinę
    date_and_time: 'Wybierz datę i godzinę',
    // Godzina
    time: 'Godzina',
    // Inwestycja
    investment: 'Inwestycja',
    // w górę
    up: 'w górę',
    // w dół
    down: 'w dół',
    // Twój zysk
    your_payout: 'Twój zysk',
    // Transakcje
    deals: 'Transakcje',
    // Oczekujące
    pending: 'Oczekujące',
    // W trakcie
    in_progress: 'W trakcie',
}