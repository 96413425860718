// ru

export default {
    //Профит Всего
    total_profit: 'Профит Всего',
    // Средний профит за сделку
    average_profit_per_trade: 'Средний профит за сделку',
    //Самая профитная сделка
    most_profitable_trade: 'Самая профитная сделка',
    // Сделка на сумму
    deal_for_amount: 'Сделка на сумму',
    //Прибыль
    profit: 'Прибыль',
    // Дата
    date: 'Дата',
    //Продолжительность
    duration: 'Продолжительность',
    // Аналитика инструментов
    instruments_analytics: 'Аналитика инструментов',
    // Успешных сделок
    successful_deals: 'Успешных сделок',
    //today
    today: 'Сегодня',
    //yesterday 
    yesterday: 'Вчера',
    // thirtyDays
    thirtyDays: 'Месяц',
}
