import React, { useState, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';
import useAuth from '@/hooks/useAuth.hook';
import { useSelector } from 'react-redux';
import { langList } from "@/context/LanguageContext";
import { useLanguage } from "@/context/LanguageContext";

const LangSwitcher = () => {
    const { language } = useLanguage();
    const { toggleLanguage } = useLanguage();

    const auth = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const { wallet } = useSelector(state => state.balance);


    const handleItemClick = (lang) => {
        toggleLanguage(lang);
        setIsOpen(false);
    };

    useEffect(() => {
        // Функция для проверки клика вне компонента
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Добавление обработчика события при монтировании компонента
        document.addEventListener('mousedown', handleClickOutside);

        // Удаление обработчика события при размонтировании компонента
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative inline-block text-left text-xs" ref={dropdownRef}>
            <button onClick={() => setIsOpen(!isOpen)} className="" id="options-menu" aria-haspopup="true" aria-expanded={isOpen}>
                <div className='flex items-center gap-2 bg-gray-900 px-4 py-2 rounded-lg cursor-pointer hover:bg-opacity-80'>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-blue-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m10.5 21 5.25-11.25L21 21m-9-3h7.5M3 5.621a48.474 48.474 0 0 1 6-.371m0 0c1.12 0 2.233.038 3.334.114M9 5.25V3m3.334 2.364C11.176 10.658 7.69 15.08 3 17.502m9.334-12.138c.896.061 1.785.147 2.666.257m-4.589 8.495a18.023 18.023 0 0 1-3.827-5.802" />
                    </svg>

                    <span className='text-white'>{language}</span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-white">
                        <path fillRule="evenodd" d="M12.53 16.28a.75.75 0 0 1-1.06 0l-7.5-7.5a.75.75 0 0 1 1.06-1.06L12 14.69l6.97-6.97a.75.75 0 1 1 1.06 1.06l-7.5 7.5Z" clipRule="evenodd" />
                    </svg>
                </div>
            </button>

            <Transition
                show={isOpen}
                enter="transition ease-out duration-100 transform"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-75 transform"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
                className="absolute right-0 z-50 mt-2 origin-top-right rounded-md shadow-lg bg-black ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
            >
                <div className="py-1">
                    {langList.map((item, index) => (
                        <button
                            onClick={() => handleItemClick(item.code)}
                            key={index}
                            to={item.href}
                            className="block px-4 py-2 text-sm text-gray-200 hover:bg-gray-700 hover:text-white"
                            role="menuitem"
                        >
                            {item.code}
                        </button>
                    ))}
                </div>
            </Transition>
        </div>
    );
};

export default LangSwitcher;
