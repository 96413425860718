import './App.css';
import React, { useEffect } from 'react';
import Layout from './layout/Layout';
import { Routes, Route, Navigate } from 'react-router-dom';
import { tabs } from './layout/pages';
import Login from './pages/Login';
import useAuth from './hooks/useAuth.hook';
import { initializeWebSocket } from './redux/socket/websocketActions';
import { fetchDealsForLead, getDealsStatus } from './redux/dealsSlice';
import { fetchActives, setSelectedActives } from './redux/activesSlice';
import { useDispatch, useSelector } from 'react-redux';
import { langList, useLanguage } from './context/LanguageContext';


function App() {
  const auth = useAuth();
  const dispatch = useDispatch();
  const status = useSelector(getDealsStatus);
  const activesStatus = useSelector(state => state.actives.status);
  const actives = useSelector(state => state.actives.items);
  const { language } = useLanguage();
  const { isConnected, error } = useSelector(state => state.websocket);

  useEffect(() => {
    if (auth.isLoaded && auth.token) {
      dispatch(initializeWebSocket(auth.token));
    }
  }, [auth.isLoaded, auth.token]);

  useEffect(() => {
    if (auth.isLoaded && auth.token) {

      if (status === 'idle') {
        dispatch(fetchDealsForLead(auth.token));
      }

      if (activesStatus === 'idle') {
        dispatch(fetchActives(auth.token));
      }

      if (activesStatus === 'succeeded') {
        dispatch(setSelectedActives(
          localStorage.getItem('selectedOption') ?
            actives.find(active => active.id === JSON.parse(localStorage.getItem('selectedOption')).id) :
            actives
              .find(active => active.id === 6)
        ));
      }

    }
  }, [status, dispatch, auth.isLoaded, auth.token]);

  return (<div className='bg-gray-800'>
    <Layout>
      {/* Содержимое, которое будет отображаться в основной части лейаута */}
      <Routes>
        {langList.map((lang, index) => (<>
          <Route path={lang.code.toLowerCase() + '/quick-login/:token'} exact element={<Login />} />
          <Route path={lang.code.toLowerCase() + '/login'} exact element={<Login />} />
        </>))}

        {auth.isLoaded && auth.token && auth.user !== null && auth?.user?.role !== 'affiliate' && <React.Fragment> {
          tabs
            .map((tab, index) => (<React.Fragment key={index}>
              <Route
                path={tab.path}
                element={tab.Component}
              />
              {tab?.links && tab.links
                .map((link, index) => (
                  <Route
                    key={index + 99}
                    path={link.path}
                    element={link.Component}
                  />
                ))}
            </React.Fragment>))}

          <Route
            path="*"
            element={<Navigate to={'/' + language.toLowerCase()} replace />}
          />
        </React.Fragment>}

        {auth.isLoaded && !auth.token && <Route
          path="*"
          element={<Navigate to={language.toLowerCase() + '/login'} replace />}
        />}
      </Routes>

    </Layout>
  </div>);
}

export default App;
