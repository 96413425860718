// Deposit.jsx
import Menu from '@/layout/Menu';
import React, { useEffect, useState } from 'react';
import FAQAccordion from './components/Faq';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactions } from '@/redux/transactionSlice';
import useAuth from '@/hooks/useAuth.hook';
import { fetchUserBalance, fetchUserDeposit } from '@/redux/userBalanceSlice';
import { addAlert } from '@/redux/globalAlertSlice';
import { useLanguage } from '@/context/LanguageContext';
import { fetchPaymentMethods } from '@/redux/paymentMethodSlice';
import _ from 'lodash';
import { slugify } from '@/utils/slugify';
import Drawer from 'react-modern-drawer'
import currencyapi from '@everapi/currencyapi-js'
import deposit from '@/lang/en/deposit';
import CurrencyInput from 'react-currency-input-field';

const CountdownTimer = ({ initialMinutes = 15, initialSeconds = 0 }) => {
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(initialSeconds);



    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    return (
        <div className="text-center">
            {minutes === 0 && seconds === 0
                ? <p>Время вышло</p>
                : <p>Время на оплату: {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
            }
        </div>
    );
};


const PaymentMethodCard = ({ method }) => {
    const { getTranslation } = useLanguage();
    const t = getTranslation('deposit');

    return (
        <div className="w-full cursor-pointer bg-white rounded-md p-4" >
            <div className='flex flex-col md:flex-row justify-center text-center text-gray-800 text-xl items-center min-h-[120px]'>
                <img src={'/media/wallets/' + slugify(method.name) + '.png'} alt={method.name} className="w-[120px] h-auto object-contain mr-2" />
                <p className='w-[120px] break-words'>{method.name}</p>
            </div>
        </div>
    );
};

const PaymentDataForm = ({ predefinedAmounts, amount, setAmount }) => {
    const { getTranslation } = useLanguage();
    const t = getTranslation('deposit');

    const onChange = (value, name, values) => {
        console.log(value, name, values.float);
        if (value > 50000) {
            alert(t.max_amount_50000)
            return
        }
        // const value = Math.min(Math.max(e.target.value, 0), 50000);
        setAmount(values.float);
    }

    return (
        <div className="bg-gray-900 rounded-lg text-white">
            <div className="mb-4">
                <label htmlFor="amount" className="block font-bold text-xl mb-2">
                    {t.the_amount}
                </label>
                <CurrencyInput
                    id="amount"
                    min={0}
                    max={50000}
                    defaultValue={10}
                    value={amount}
                    allowDecimals={false}
                    prefix="$"
                    name="input-name"
                    onValueChange={(value, name, values) => onChange(value, name, values)}
                    // onChange={onChange}
                    className="mt-1 p-2 block w-full bg-gray-700 border-transparent rounded-md"
                />
            </div>
            <div className="flex space-x-2">
                {predefinedAmounts.map((amt) => (
                    <button
                        key={amt}
                        onClick={() => setAmount(amt)}
                        className="px-4 py-2 bg-blue-500 rounded-md"
                    >
                        {amt} $
                    </button>
                ))}
            </div>
        </div>
    );
};

const WalletGroup = ({ handleChooseMethod, groupName, items }) => {
    const groupNames = {
        'card': 'Credit Card',
        'e-pay': 'Bank Transfer',
        'crypto': 'Cryptocurrency',
    }


    return <div className="flex flex-col gap-4">
        <h1 className="text-2xl text-white font-bold    ">{groupNames[groupName]}</h1>
        <div className="flex flex-wrap gap-4 justify-center md:justify-normal">
            {items.map((method) => (
                <div className='w-full sm:w-1/3 md:w-auto' onClick={() => handleChooseMethod(method)}>
                    <PaymentMethodCard method={method} setSelectedMethod={() => null} />
                </div>
            ))}
        </div>
    </div>
}


const Deposit = () => {
    const [amount, setAmount] = useState(0);
    const { token, user } = useAuth();
    const dispatch = useDispatch();
    const { depStatus } = useSelector(state => state.balance);
    const { getTranslation } = useLanguage();
    const t = getTranslation('deposit');
    const { paymentMethods, status: paymentsStatus } = useSelector(state => state.paymentMethod);
    const [selectedMethod, setSelectedMethod] = useState(null);
    const [isDeposit, setIsDeposit] = useState(false);
    const [depositPopup, setDepositPopup] = useState(false);
    const [currencyValue, setCurrencyValue] = useState({
        code: "USD",
        value: 1
    });

    const client = new currencyapi('cur_live_c0J99nNeTkW9nlCa0HK9sjUEgDQXu0UvRlTvwJA6')

    const toggleDrawer = () => {
        setDepositPopup((prevState) => !prevState)
    }

    React.useEffect(() => {
        if (depStatus === 'succeeded') {
            dispatch(fetchTransactions({ token }));
            dispatch(fetchUserBalance(token));
            dispatch(addAlert({ type: 'success', alert: t.depositSuccess }));
            setIsDeposit(true);
        }

        if (depStatus === 'failed') {
            dispatch(addAlert({ type: 'error', alert: t.depositError }));
        }

        return () => {
            setAmount(0);
        }
    }, [depStatus]);

    const handleDeposit = () => {
        const deposit = {
            amount: amount,
            method: selectedMethod.type,  // method id
            card_number: selectedMethod.account_number
        }

        dispatch(fetchUserDeposit({ token, deposit }));

    }

    function extractAbbreviation(str) {
        const match = str.match(/\(([^)]+)\)/);
        return match ? match[1] : null;
    }


    const handleChooseMethod = (method) => {
        setSelectedMethod(method);
    }

    useEffect(() => {
        if (paymentsStatus === 'idle') {
            dispatch(fetchPaymentMethods({ token, desk_id: user.desk_id }));
        }
    }, [paymentsStatus, token, user]);

    useEffect(() => {
        let currencies = user.currency;

        if (selectedMethod && selectedMethod.type == 'crypto') {

            // Пример использования:
            const abbreviation = extractAbbreviation(selectedMethod?.name);
            if (selectedMethod.name.includes('USD'))
                currencies = 'USD';
            else currencies = abbreviation;
        }

        client.latest({
            base_currency: 'USD',
            currencies: currencies || "USD"
        }).then(response => {
            setCurrencyValue(response.data[currencies || "USD"])
        });
    }, [selectedMethod, amount])

    const handleStartDeposit = () => {
        if (amount < 10) {
            alert(t.min_amount_10)
            return
        }

        toggleDrawer()
    }
    return (
        <div className='sm:container mx-auto'>
            <Menu />

            {!selectedMethod && <div className="grid lg:auto-cols-auto lg:grid-flow-col justify-between bg-gray-900 rounded-md m-2 md:m-4 pb-4 md:py-4 gap-4 px-4 md:px-8 mx-auto md:my-8 divide-y lg:divide-y-0 lg:divide-x divide-dashed divide-gray-200 min-h-screen md:min-h-[calc(100vh-180px)]">
                <div className='w-full grid grid-cols-1 lg:auto-cols-auto gap-2'>
                    {!_.isEmpty(paymentMethods) && Object.keys(paymentMethods).map(groupName => (
                        <WalletGroup handleChooseMethod={handleChooseMethod} key={groupName} groupName={groupName} items={paymentMethods[groupName]} />
                    ))}
                </div>

            </div>}

            {!!selectedMethod && <div className='bg-gray-900 rounded-md m-2 md:m-4 md:py-4 gap-4 md:px-8 mx-auto md:my-8'>
                <div className="w-full flex justify-start">
                    <button onClick={() => setSelectedMethod(null)} className="flex items-center gap-2 px-2 py-2 m-2 bg-gray-800 rounded-md text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                        </svg>
                        Назад
                    </button>
                </div>

                <div className="grid lg:auto-cols-auto lg:grid-flow-col justify-between divide-y lg:divide-y-0 lg:divide-x divide-dashed divide-gray-200 min-h-screen md:min-h-[calc(100vh-180px)]">
                    {/* Back button */}

                    <div className='w-full flex flex-col gap-2'>
                        <div className={`w-full border-2 border-transparent p-2 rounded-md hover:border-green-500 transition-all`}>
                            <PaymentMethodCard
                                method={selectedMethod}
                            />
                        </div>
                    </div>
                    <div className='w-full p-4'>
                        <PaymentDataForm
                            amount={amount}
                            setAmount={setAmount}
                            predefinedAmounts={[150, 200, 300, 500]} />
                        <button onClick={handleStartDeposit} className="flex gap-2 text-white mt-4 px-4 py-2 bg-green-500 rounded-md">{t.deposit}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                            <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25Zm4.28 10.28a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 1 0-1.06 1.06l1.72 1.72H8.25a.75.75 0 0 0 0 1.5h5.69l-1.72 1.72a.75.75 0 1 0 1.06 1.06l3-3Z" clipRule="evenodd" />
                        </svg>
                        </button>
                        {isDeposit && <div className="text-2xl whitespace-pre-line bg-gray-800 rounded-lg p-4 text-green-500 mt-4">{t.depositSuccessMsg}</div>}
                    </div>



                    <div className='max-w-2xl w-full'>
                        <FAQAccordion />
                    </div>
                </div>
            </div>}

            {selectedMethod && Number(amount) > 0 && <Drawer
                open={depositPopup}
                onClose={toggleDrawer}
                direction='bottom'
                size={'100vh'}
                className='overflow-auto'
                style={{ background: 'rgb(0 0 0 / .5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <div className='container mx-auto max-w-md bg-white rounded-md p-4'>
                    {selectedMethod.type === 'crypto' ? <div className="space-y-4 relative mb-8">
                        {t.payment.crypto.map((step, index) => (
                            <div className="step">
                                <p className="pl-6 relative text-gray-800">{step}</p>
                            </div>
                        ))}
                    </div>
                        : <div className="space-y-4 relative mb-8">
                            {t.payment.other.map((step, index) => (
                                <div className="step">
                                    <p className="pl-6 relative text-gray-800">{step}</p>
                                </div>
                            ))}
                        </div>}

                    <div className='flex flex-col gap-2'>
                        <div className="relative bg-gradient-to-r from-zinc-200 to-purple-200 rounded-lg shadow-lg px-4 py-8">
                            <div>{t.payment.amount_to_transfer}:</div>
                            <div className='break-all flex gap-4 items-center font-bold text-xl'>
                                {selectedMethod.type != 'crypto' || selectedMethod.name.includes('USD') ? new Intl.NumberFormat('en-EN', { style: 'currency', currency: currencyValue.code }).format(
                                    parseFloat(currencyValue.value * amount)
                                ) : extractAbbreviation(selectedMethod.name) + ' ' + parseFloat(currencyValue.value * amount)}
                                {/* Copy amount btn */}
                                <div className='p-2 bg-zinc-300 bg-opacity-20  text-gray-500 rounded-full flex items-center justify-center shadow-md cursor-pointer duration-300'
                                    onClick={(e) => {
                                        navigator.clipboard.writeText(amount)
                                        // change svg
                                        e.target.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6" style="width: 1.25rem;height: 1.25rem;color: green;">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                      </svg>`

                                        setTimeout(() => {
                                            e.target.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 duration-300 hover:scale-[1.1]" style="width: 1.25rem;height: 1.25rem;color:rgb(107 114 128 / 1)">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                        </svg>`
                                        }, 2000)
                                    }}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 duration-300 hover:scale-[1.1]" >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                    </svg>

                                </div>
                            </div>

                            <div className='flex items-center gap-2 mt-4 text-sm'>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5 text-orange-400">
                                    <path fillRule="evenodd" d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003ZM12 8.25a.75.75 0 0 1 .75.75v3.75a.75.75 0 0 1-1.5 0V9a.75.75 0 0 1 .75-.75Zm0 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" clipRule="evenodd" />
                                </svg>

                                <span>{t.payment.amount_to_transfer_hint}</span>
                            </div>

                        </div>

                        <div className="relative text-zinc-200 bg-gradient-to-r from-indigo-500 to-indigo-950 rounded-lg shadow-lg px-4 py-8">
                            <div>{t.payment.card_number}:</div>
                            <div className='break-all flex gap-4 items-center font-bold text-xl'>
                                {selectedMethod.account_number}
                                <div className='p-2 bg-zinc-300 bg-opacity-20  text-white rounded-full flex items-center justify-center shadow-md cursor-pointer duration-300'
                                    onClick={(e) => {
                                        navigator.clipboard.writeText(selectedMethod.account_number)
                                        // change svg
                                        e.target.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" style="width: 1.25rem;height: 1.25rem;color: green;">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                          </svg>`

                                        setTimeout(() => {
                                            e.target.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 duration-300 hover:scale-[1.1]" style="width: 1.25rem;height: 1.25rem;">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                    </svg>`
                                        }, 2000)
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 duration-300 hover:scale-[1.1]">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z" />
                                    </svg>

                                </div>
                            </div>

                            <div className='flex items-center gap-2 mt-4 text-sm'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                </svg>


                                <span>{selectedMethod.description}</span>
                            </div>

                        </div>
                        {/* Таймер */}
                        <div className='flex items-center gap-2  p-4 mb-4 text-sm text-blue-800 rounded-lg bg-blue-50'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            <CountdownTimer />
                            <div className='text-zinc-500 ml-auto brder-b border-b-2 border-gray-400 cursor-pointer' onClick={toggleDrawer}>
                                {t.payment.cancel_request}
                            </div>
                        </div>

                        <button onClick={handleDeposit} className="flex gap-2 text-white mt-4 px-4 py-2 bg-green-500 rounded-md justify-center">
                            {t.payment.i_paid}
                        </button>
                    </div>
                </div>
            </Drawer>}
        </div>

    );
};

export default Deposit;
