// es

export default {
    // Pending Trade
    pending_trade: 'Operación pendiente',
    // Choose date and time
    date_and_time: 'Elegir fecha y hora',
    //Time
    time: 'Hora',
    // Investment
    investment: 'Inversión',
    // up
    up: 'arriba',
    // down
    down: 'abajo',
    // Your payout
    your_payout: 'Tu pago',
    // Deals
    deals: 'Operaciones',
    // Pending
    pending: 'Pendiente',
    // In progress
    in_progress: 'En progreso',
}