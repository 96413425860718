//pl
//Fields: DATA	SUMA	TYP	STATUS	KARTA	WALUTA	METODA

export default {
    date: 'Дата',
    amount: 'Сумма',
    type: 'Тип',
    status: 'Статус',
    card: 'Карта',
    currency: 'Валюта',
    method: 'Метод',
    // operation status
    operationStatus: (status) => {
        switch (status) {
            case 'success':
                return 'Успешно';
            case 'pending':
                return 'В ожидании';
            case 'failed':
                return 'Неудача';
            case 'error':
                return 'Ошибка';
            default:
                return '-';
        }
    },
    operationType: (type) => {
        switch (type) {
            case 'deposit':
                return 'Депозит';
            case 'withdrawal':
                return 'Вывод';
            case 'bonus':
                return 'Бонус';
            default:
                return '-';
        }
    }
}