import useAuth from '@/hooks/useAuth.hook';
import Menu from '@/layout/Menu';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';
// import { Chart } from "react-google-charts";
import { fetchAnalytics } from '@/redux/analyticsSlice';
import { PieChart } from 'react-minimal-pie-chart';
import FullOption from './components/FullOption';
import { dateFormater } from '@/utils/dateFormater';
import { formatDuration } from '@/utils/formatDuration';
import { useLanguage } from '@/context/LanguageContext';
import { useState } from 'react';
import dayjs from 'dayjs'

const colorMap = {};
const selectedColors = {};

const generateRGBColor = () => {
    const r = Math.floor(Math.random() * 256); // Красный компонент
    const g = Math.floor(Math.random() * 256); // Зеленый компонент
    const b = Math.floor(Math.random() * 256); // Синий компонент
    return `rgb(${r} ${g} ${b} / .6)`;
};

const newColorFind = id => {
    if (colorMap[id]) return colorMap[id];

    let newColor;

    do {
        newColor = generateRGBColor();
    } while (selectedColors[newColor]);

    colorMap[id] = newColor;
    selectedColors[newColor] = true;

    return newColor;
};

const getColorByPercent = (percent) => {
    const h = percent * 1.2;
    return `hsla(${h}, 100%, 50%, 1)`;
}

const Analytics = ({ user, token, statistics, status, error }) => {

    const { getTranslation } = useLanguage();
    const t = getTranslation('analytics');

    const [popularData, setPopularData] = React.useState([]);
    const [mostProfitableDeal, setMostProfitableDeal] = React.useState(null);
    const actives = useSelector(state => state.actives.items);

    useEffect(() => {
        if (statistics && statistics.get_user_popular_assets) {
            setPopularData(statistics.get_user_popular_assets.map((asset, index) => ({
                title: asset.type,
                value: asset.deals_count,
                color: newColorFind(index)
            })));
        }

        if (statistics && statistics.get_most_profitable_deal) {
            setMostProfitableDeal(statistics.get_most_profitable_deal);
        }
    }, [statistics]);

    return (
        <div >

            <div className="bg-gray-900 text-white rounded-lg p-4 md:min-h-[calc(100vh-180px)]">
                <div className='flex flex-wrap md:grid gap-4 md:grid-cols-3 md:divide-x items-center justify-center border-b pb-4'>
                    <div className='flex items-center gap-2'>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-12 h-12">
                            <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
                        </svg>

                        <div>
                            <p>{user.first_name} {user.last_name}</p>
                            <p className='text-gray-200'>{user.email}</p>
                        </div>
                    </div>
                    {statistics.get_total_profit ? <div className='flex h-fit items-center justify-center flex-col'>
                        <p className='font-bold'>{t.total_profit}</p>
                        <p className=''>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                            parseFloat(statistics.get_total_profit)
                        )}</p>
                    </div> : ''}
                    {statistics.get_average_profit ? <div className='flex h-fit items-center justify-center flex-col'>
                        <p className='font-bold'>{t.average_profit_per_trade}</p>
                        <p className=''>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                            parseFloat(statistics.get_average_profit)
                        )}</p>
                    </div> : ''}
                </div>
                {/* Самый популярный инструмент */}

                <div className='grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 items-start justify-center border-b pb-4 mt-4'>
                    {mostProfitableDeal && <div className='bg-gray-800 p-4 m-4 rounded-lg shadow-md gap-y-4'>
                        <h3 className='text-2xl font-bold'>{t.most_profitable_trade}</h3>
                        {/* Инструмент */}
                        {statistics.get_most_profitable_deal && <p className='flex gap-4 text-gray-200 p-4 text-md bg-gray-900 rounded-lg w-max mt-4'>{actives.find(el =>
                            el.id === statistics.get_most_profitable_deal.active_id
                        )?.type}
                            <div className="text-sm text-white">{statistics.get_most_profitable_deal.type === 'up' ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-green-500 w-6 h-6">
                                <path fillRule="evenodd" d="M15.22 6.268a.75.75 0 0 1 .968-.431l5.942 2.28a.75.75 0 0 1 .431.97l-2.28 5.94a.75.75 0 1 1-1.4-.537l1.63-4.251-1.086.484a11.2 11.2 0 0 0-5.45 5.173.75.75 0 0 1-1.199.19L9 12.312l-6.22 6.22a.75.75 0 0 1-1.06-1.061l6.75-6.75a.75.75 0 0 1 1.06 0l3.606 3.606a12.695 12.695 0 0 1 5.68-4.974l1.086-.483-4.251-1.632a.75.75 0 0 1-.432-.97Z" clipRule="evenodd" />
                            </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="text-red-500 w-6 h-6">
                                    <path fillRule="evenodd" d="M1.72 5.47a.75.75 0 0 1 1.06 0L9 11.69l3.756-3.756a.75.75 0 0 1 .985-.066 12.698 12.698 0 0 1 4.575 6.832l.308 1.149 2.277-3.943a.75.75 0 1 1 1.299.75l-3.182 5.51a.75.75 0 0 1-1.025.275l-5.511-3.181a.75.75 0 0 1 .75-1.3l3.943 2.277-.308-1.149a11.194 11.194 0 0 0-3.528-5.617l-3.809 3.81a.75.75 0 0 1-1.06 0L1.72 6.53a.75.75 0 0 1 0-1.061Z" clipRule="evenodd" />
                                </svg>

                            }</div>
                        </p>}
                        {statistics.get_most_profitable_deal ? <p className='text-gray-200 text-md bg-gray-900 flex p-4 mt-4 w-max'>{t.deal_for_amount}: <div className='text-green-500 ml-2'>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                            parseFloat(statistics.get_most_profitable_deal.value)
                        )}</div></p> : ''}
                        {statistics.get_most_profitable_deal ? <p className='text-gray-200 text-md bg-gray-900 flex p-4 mt-4 w-max'>{t.profit}: <div className='text-green-500 ml-2'>{new Intl.NumberFormat('en-EN', { style: 'currency', currency: 'USD' }).format(
                            parseFloat(statistics.get_most_profitable_deal.profit)
                        )}</div></p> : ''}
                        {statistics.get_most_profitable_deal ? <p className='flex-col text-gray-200 text-md bg-gray-900 flex flex-wrap max-w-full  p-4 mt-4 w-max'>
                            <div className='flex'>{t.date}: <div className='flex flex-wrap max-w-full text-green-500 ml-2'>{dateFormater(statistics.get_most_profitable_deal.open_time)} - {dateFormater(statistics.get_most_profitable_deal.close_time)}</div></div>
                            <div className='flex '>{t.duration}:<div className='text-orange-400 ml-2'>{formatDuration(statistics.get_most_profitable_deal.duration)}</div></div>
                        </p> : ''}
                    </div>}
                    <div className='flex flex-wrap bg-gray-800 p-4 m-4 rounded-lg shadow-md gap-y-4'>
                        <h3 className='text-2xl font-bold basis-full'>{t.instruments_analytics}</h3>
                        {popularData ? <>
                            <div className=''>
                                <FullOption
                                    chartType="PieChart"
                                    data={popularData}
                                    className='text-white text-md'
                                />
                            </div>
                            <div className="flex flex-col items-center justify-center mt-4">
                                {popularData.map((data, index) => (
                                    <div key={index} className="flex items-center gap-2">
                                        <div
                                            className="w-4 h-4 rounded-full"
                                            style={{ backgroundColor: data.color }}
                                        ></div>
                                        <p className="text-gray-200">{data.title}</p>
                                    </div>
                                ))}
                            </div>
                        </> : ''}
                    </div>
                    <div className='flex flex-wrap bg-gray-800 p-4 m-4 rounded-lg shadow-md gap-y-4'>
                        <h3 className='text-2xl font-bold basis-full'>{t.successful_deals}</h3>
                        <div className='mx-auto'>
                            {statistics && statistics.get_success_deals_percentage ? <PieChart
                                data={[{ value: statistics?.get_success_deals_percentage.toFixed(2), color: getColorByPercent(statistics?.get_success_deals_percentage.toFixed(2)) }]}
                                totalValue={100}
                                background="#bfbfbf"
                                lineWidth={20}
                                label={({ dataEntry }) => dataEntry.value + '%'}
                                rounded
                                animate
                                labelStyle={{
                                    fontSize: '18px',
                                    fontFamily: 'sans-serif',
                                    fill: getColorByPercent(statistics?.get_success_deals_percentage.toFixed(2)),
                                }}
                                labelPosition={0}
                                style={{
                                    maxWidth: '300px',
                                    maxHeight: '300px',
                                }}
                            /> : ''}
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
};
const activeDate = (activeTab) => {

    switch (activeTab) {
        case 'today':
            return {
                start: dayjs().format('YYYY-MM-DD'),
                end: dayjs().add(1, 'day').format('YYYY-MM-DD')
            }
        case 'yesterday':
            return {
                start: dayjs().subtract(1, 'day').format('YYYY-MM-DD'),
                end: dayjs().format('YYYY-MM-DD')
            }
        case 'month':
            return {
                start: dayjs().startOf('month').format('YYYY-MM-DD'),
                end: dayjs().endOf('month').format('YYYY-MM-DD')
            }
        case 'thirtyDays':
            return {
                start: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
                end: dayjs().add(1, 'day').format('YYYY-MM-DD')
            }
        default:
            return {
                start: dayjs().format('YYYY-MM-DD'),
                end: dayjs().add(1, 'day').format('YYYY-MM-DD')
            }
    }
}
const AnalyticsWrapper = () => {
    const { getTranslation } = useLanguage();
    const t = getTranslation('analytics');

    const { user, token } = useAuth();
    const [activeTab, setActiveTab] = useState('thirtyDays');
    const { wallet } = useSelector(state => state.balance);
    const { status, data: statistics, error } = useSelector(state => state.analytics);
    const dispatch = useDispatch();
    const [isDemo, setIsDemo] = useState(false);

    useEffect(() => {
        const date = activeDate(activeTab);

        dispatch(fetchAnalytics({ token: token, ...date, demo: isDemo }));

    }, [token, activeTab, isDemo]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    if (status === 'idle') {
        return <div>Loading...</div>
    }


    return <div className='sm:container mx-auto min-h-screen'    >
        <Menu />
        <div className='flex flex-wrap justify-between px-4 mb-4 gap-4'>
            <div className="flex gap-4">
                <button
                    className={`flex gap-1 px-4 py-2 rounded ${activeTab === 'today' ? 'text-white bg-gray-600' : 'text-gray-300 bg-gray-700 hover:bg-gray-700 hover:text-white'
                        }`}
                    onClick={() => handleTabClick('today')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                    </svg>


                    {t.today}

                </button>
                <button
                    className={`flex gap-1 px-4 py-2 rounded ${activeTab === 'yesterday' ? 'text-white bg-gray-600' : 'text-gray-300 bg-gray-700 hover:bg-gray-700 hover:text-white'
                        }`}
                    onClick={() => handleTabClick('yesterday')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                    </svg>


                    {t.yesterday}
                </button>
                <button
                    className={`flex gap-1 px-4 py-2 rounded ${activeTab === 'thirtyDays' ? 'text-white bg-gray-600' : 'text-gray-300 bg-gray-700 hover:bg-gray-700 hover:text-white'
                        }`}
                    onClick={() => handleTabClick('thirtyDays')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                        <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                    </svg>

                    {t.thirtyDays}
                </button>
            </div>
            <div>
                <button
                    className={`flex gap-1 px-4 py-2 rounded ${isDemo ? 'text-white bg-green-600' : 'text-gray-300 bg-gray-700 hover:bg-gray-700 hover:text-white'
                        }`}
                    onClick={() => setIsDemo(!isDemo)}
                >
                    Demo
                </button>
            </div>
        </div>
        <Analytics
            user={user}
            token={token}
            statistics={statistics}
            status={status}
            error={error}
        />
    </div>
}

export default AnalyticsWrapper;